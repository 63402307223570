import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import CourseStructure from '../../../api/courseStructureApi';
import Loader from "../../../components/loader";
import Grid from "../../../components/dataTable";

const PrintCourseStructureModal = ({data,rows, isLoader,selectedClass,selectedTerm,field, handleClose}) =>{

    const generateCourseStructurePDF = () => {
        const CourseStructureApi = new CourseStructure();
        CourseStructureApi.generateCourseStructurePDF(selectedClass, selectedTerm).then((response) => {
          if (response && response.success) {
            window.open(
              "/api/downloadCourse",
              "_blank",
              "location=no,height=700,width=850,scrollbars=yes,status=yes"
            );
          } else {
            toast.error("error in PDF");
          }
        });
    };
    return (
        <Modal
        id="divcontents"
        show={data.isModalVisible}
        size="xl"
        onHide={() => handleClose()}
        >
            <Modal.Header closeButton>
            <h3 className="page-title"> Course Structure </h3>
            </Modal.Header>
            {rows ? (
            <div className="card" id="cardcontents">
                {isLoader ? <Loader /> : null}
                <div className="card-body">
                <div
                    className="row cntr-cls"
                    style={{ justifyContent: "center" }}
                >
                    <h2>Class : {rows && rows.className ? rows.className : ""} </h2>
                </div>
                <div className="row cntr-cls" style={{ justifyContent: "center" }}>
                    <h4>Term : {rows && rows.term ? rows.term : ""} </h4>
                </div>
                <div className="row cntr-cls" style={{ justifyContent: "center" }}>
                    <Grid
                    columns={field()}
                    rows={rows && rows.subjects ? rows.subjects : []}
                    settings={{
                        search: false,
                        paging: false,
                        exportButton: false,
                    }}
                    />
                </div>
                </div>
            </div>
            ) : (
            ""
            )}
            <Modal.Footer
            id="hide-fee-rcpt-id"
            style={{ backgroundColor: "#ffffff" }}
            >
            <Button variant="secondary" onClick={() => handleClose()}>
                Close
            </Button>
            <Button
                className="button1"
                variant="primary"
                onClick={() => {
                handleClose();
                generateCourseStructurePDF();
                }}
            >
                Print
            </Button>
            </Modal.Footer>
        </Modal>
        )
}

export default PrintCourseStructureModal;