import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
const csvModal = ({ show = false, isModal, filename,headers, errorRecords }) => {
  return (
    <>
      <Modal show={show} onHide={() => isModal()}>
        <Modal.Header style={{ backgroundColor: "#af0000" }} closeButton>
          <Modal.Title style={{ color: "beige" }}>Warning!</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            minHeight: "133px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <span>Please download and check the error sheet. </span>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "white" }}>
          <CSVLink
            style={{ padding: "16px" }}
            data={errorRecords}
            filename={filename}
            onClick={() => isModal()}
            headers={headers}
            className="btn btn-outline-danger"
          >
            <i className="mdi mdi-download"></i> Download{" "}
          </CSVLink>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => isModal()}
            style={{ padding: "16px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default csvModal;
