import React, { useEffect, useState } from 'react';
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import Grid from "../../../components/dataTable";
import { toast } from "react-toastify";
import StaffMember from "../../../api/staffMember";
import class4Employees from "../../../api/addclass4Employees";
import moment from 'moment';
import StaffAttendanceScatterPlot from "../../../components/StaffAttendanceScatterPlot";

const ManageStaffAttendance = () => {

  const today = moment();
  const disableFutureDt = current => {
    return current.isBefore(today)
  }

  const [rows, setRows] = useState([]);
  const [displayAttendance, setDisplayAttendance] = useState('staff');
  const [attendanceDate, setAttendanceDate] = useState(moment().format('DD/MM/YYYY'));

  useEffect(() => {
    if (displayAttendance === 'staff') {
      getStaffAttendance();
    } else {
      getNonTeachingStaffAttendance();
    }
  }, [displayAttendance, attendanceDate]);

  const getStaffAttendance = async () => {
    let Api = new StaffMember();
    await Api.getStaffAttendance(attendanceDate).then(async (response) => {
      if (response.data && response.data.length && displayAttendance === 'staff') {
        setRows(response.data);
      } else {
        setRows([]);
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getNonTeachingStaffAttendance = async () => {
    let Api = new class4Employees();
    await Api.getAttendanceDetails(attendanceDate).then(async (response) => {
      if (response.data && response.data.length) {
        setRows(response.data)
      } else {
        setRows([]);
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const NameHyperLink = (props) => {
    return (
      <span>
        <Link to={displayAttendance === 'staff' ? `/addstaff?id=${props.userId}` : `/addclass4employees?id=${props.userId}`}>
          {props.firstName} {props.lastName}
        </Link>
      </span>
    )
  }

  const renderWithTag = (props) => {
    let cls = 'badge';
    switch (props.statusOfAttendance) {
      case 'OnTime':
        cls = 'badge badge-success';
        break;
      case 'Late':
        cls = 'badge badge-warning';
        break;
      case 'Leave':
        cls = 'badge badge-warning';
        break;
      case 'Absent':
        cls = 'badge badge-danger';
        break;
    }

    return (
      <span className={cls}>
        {props.statusOfAttendance}
      </span>
    )
  }

  const field = () => {
    const fields = [
      {
        title: "Name",
        render: NameHyperLink,
        field: "firstName"
      },
      {
        title: "Attendance",
        render: renderWithTag,
        field: "statusOfAttendance"
      },
      {
        title: "Time In",
        field: "timeIn"
      }
    ];
    return fields;
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Staff Attendance </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Staff</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => setDisplayAttendance(e.target.value)} className="form-control">
                        <option value="staff">Staff</option>
                        <option value="nonTeachingStaff">Non-Teaching Staff</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <Datetime
              dateFormat={true}
              timeFormat={false}
              onChange={(e) => {
                setAttendanceDate(moment(e).format("DD/MM/YYYY"))
              }}
              value={"Todays Attendance - " + (attendanceDate)}
              isValidDate={disableFutureDt}
            />
            <Grid
              columns={field()}
              rows={rows}
              settings={{
                paging: false,
                exportButton: false,
              }}
            />
            <div className='row mt-3'>
              <div className='col-md-3'>
                <StaffAttendanceScatterPlot data={rows} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageStaffAttendance;