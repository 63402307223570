import BaseApi from "./baseApi";

class CourseStructure extends BaseApi {
    async getCourseStructures() {
        return await this.API(
            {
                url: "/coursestructure/getcourses",
                method: "get",
            }
        )
    }

    async getCourseStructure(data) {
        return await this.API(
            {
                url: "/coursestructure/getcourse/" + data,
                method: "get"
            }
        )
    }

    async getFilteredCourses(data) {
        return await this.API(
            {
                url: "/coursestructure/getfilteredcourses",
                data: data,
                method: "post",
            }
        )
    }

    async generateCourseStructurePDF(selectedClass, selectedTerm) {
        return await this.API({
            url: `/coursestructure/generatecoursepdf/${selectedClass}/${selectedTerm}`,
            method: 'get'
        });
    }

    async getTermslist() {
        return await this.API(
            {
                url: "/coursestructure/gettermslist",
                method: "get",
            }
        )
    }

    async createCourseStructure(data) {
        return await this.API(
            {
                url: "/coursestructure/createcourse",
                data: data,
                method: "post"
            }
        )
    }

    async editCourseStructure(data) {
        return await this.API(
            {
                url: "/coursestructure/updatecourse",
                data: data,
                method: "post"
            }
        )
    }

    async deleteCourseStructure(data) {
        return await this.API(
            {
                url: "/coursestructure/deletecourse",
                data: { _id: data },
                method: "post"
            }
        )
    }

    async getTermslistFromSchool(data) {
        return await this.API(
            {
                url: "/coursestructure/gettermslistfromschool/" + data,
                method: "get",
            }
        )
    }

    async checkCourseSubjectPresentInResult(data) {
        return await this.API(
            {
                url: "/coursestructure/checkCourseSubjectPresentInResult",
                data: data,
                method: "post",
            }
        )
    }

    async checkTermInCourseAndResults(data) {
        return await this.API(
            {
                url: "/coursestructure/checkTermInCourseAndResults",
                data: data,
                method: "post",
            }
        )
    }

}

export default CourseStructure;