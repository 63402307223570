import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "../../assets/styles/web/imagehover.min.css";
import "../../assets/styles/web/style.css";
import Dashboard from "../../pages/schooladmin/dashboard/dashboard";
import ClassInformation from "../../pages/schooladmin/ClassInformation/ClassInformation";
import ManageClassForEmployees from "../../pages/schooladmin/ManageClass4Employees/ManageClassForEmployees";
import ManageStaffAttendance from "../../pages/schooladmin/ManageStaffAttendance/ManageStaffAttendance";
import AddClass4Employees from "../../pages/schooladmin/ManageClass4Employees/AddClass4Employees";
import AccessLevels from "../../pages/schooladmin/AccessLevels/AccessLevels";
import AddAccessLevels from "../../pages/schooladmin/AccessLevels/AddAccessLevels";
import AddStaff from "../../pages/schooladmin/ManageStaffData/AddStaff";
import ManageStaffData from "../../pages/schooladmin/ManageStaffData/ManageStaffData";
import AddStudent from "../../pages/schooladmin/ManageStudentsData/AddStudent";
import ViewStudent from "../../pages/schooladmin/ManageStudentsData/ViewStudent";
import ManageStudentData from "../../pages/schooladmin/ManageStudentsData/ManageStudentData";
import AddExamSchedule from "../../pages/schooladmin/ExamSchedule/AddExamSchedule";
import ManageExamSchedule from "../../pages/schooladmin/ExamSchedule/ManageExamSchedule";
import AddCourseStructure from "../../pages/schooladmin/CourseStructure/AddCourseStructure";
import ManageCourseStructure from "../../pages/schooladmin/CourseStructure/ManageCourseStructure";
import AddBusRoute from "../../pages/schooladmin/ManageBusData/AddBusRoute";
import ManageBusData from "../../pages/schooladmin/ManageBusData/ManageBusData";
import SubmitStudentFee from "../../pages/schooladmin/ManageFeeData/SubmitStudentFee";
import StudentsFees from "../../pages/schooladmin/ManageFeeData/StudentsFees";
import TimeTable from "../../pages/schooladmin/TimeTable/TimeTable";
import SubstituteTeachers from "../../pages/schooladmin/ManageSubstituteTeachers/ManageSubstituteTeachers";
import AutoTimeTable from "../../pages/schooladmin/TimeTable/AutoTimeTable"
import ManageCategoryData from "../../pages/schooladmin/MasterData/ManageCategoryData";
import ManageConcessionData from "../../pages/schooladmin/MasterData/ManageConcessionData";
import SchoolAdminSettings from '../../pages/schooladmin/SchoolAdminSettings/SchoolAdminSettings';
import ManageFloors from '../../pages/schooladmin/MasterData/ManageFloors';
import ManageSubjects from "../../pages/schooladmin/ManageSubjects/ManageSubjects";
import ManageHolidaysData from "../../pages/schooladmin/MasterData/ManageHolidaysData";
import CreateFees from "../../pages/schooladmin/MasterData/CreateFees";
import ManageFeeData from "../../pages/schooladmin/MasterData/ManageFeeData";
import ChangePassword from "../../pages/schooladmin/ChangePassword/ChangePassword";
import ManageNonTeachingStaffRoles from "../../pages/schooladmin/MasterData/ManageNonTeachingStaffRoles";
import ChequeStatus from "../../pages/schooladmin/MasterData/ChequeStatus";
import ForgotPassword from "../../pages/forgotPassword";
import { PrivateRoute } from '../../components/privateRoute';
import ManageUpdates from "../../pages/schooladmin/MasterData/ManageUpdates";
import ManageReports from "../../pages/schooladmin/Reports/ManageReports";
import ViewStudentReport from "../../pages/schooladmin/Reports/ViewStudentReport";
import ManageResults from "../../pages/schooladmin/Results/ManageResults";
import AddResults from "../../pages/schooladmin/Results/AddResults";

import Menu from "./menu";
import Header from "./header";

const SchoolAdminLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid page-body-wrapper pt-0">
        <Menu />
        <div className="main-panel">
          <div className="content-wrapper bg-gradient-light">
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route exact path="/login">
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/changepassword" component={ChangePassword} />
              <Route path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/classes" component={ClassInformation} />
              <Route path="/addclass4employees" component={AddClass4Employees} />
              <PrivateRoute path="/class4employees" component={ManageClassForEmployees} />
              <Route path="/addaccesslevels" component={AddAccessLevels} />
              <Route path="/editaccesslevels/:_id" component={AddAccessLevels} />
              <Route path="/accesslevels" component={AccessLevels} />
              <Route path="/addstaff" component={AddStaff} />
              <PrivateRoute path="/staffAttendance" component={ManageStaffAttendance} />
              <PrivateRoute path="/staffmembers" component={ManageStaffData} />
              <Route path="/addstudent" component={AddStudent} />
              <Route path="/viewstudent" component={ViewStudent} />
              <PrivateRoute path="/students" component={ManageStudentData} />
              <PrivateRoute path="/addexamschedule" component={AddExamSchedule} />
              <PrivateRoute path="/editexamschedule/:_id" component={AddExamSchedule} />
              <PrivateRoute path="/manageexamschedule" component={ManageExamSchedule} />
              <Route path="/addcoursestructure" component={AddCourseStructure} />
              <PrivateRoute path="/coursestructure" component={ManageCourseStructure} />
              <Route path="/addbusroute" component={AddBusRoute} />
              <PrivateRoute path="/busdata" component={ManageBusData} />
              <Route path="/submitstudentfee" component={SubmitStudentFee} />
              <PrivateRoute path="/studentsfees" component={StudentsFees} />
              <Route path="/timetable/autotimetable" component={AutoTimeTable} />
              <PrivateRoute path="/timetable" component={TimeTable} />
              <PrivateRoute path="/substituteTeachers" component={SubstituteTeachers} />
              <PrivateRoute path="/reports" component={ManageReports} />
              <PrivateRoute path="/results" component={ManageResults} />
              <Route path="/addresults" component={AddResults} />
              {/* To Individual Details */}
              <Route path="/report/:_id" component={ViewStudentReport} />
              <PrivateRoute path="/masterdata/concession" component={ManageConcessionData} />
              <PrivateRoute path="/masterdata/category" component={ManageCategoryData} />
              <PrivateRoute path="/settings" component={SchoolAdminSettings} />
              <PrivateRoute path="/masterdata/managefloors" component={ManageFloors} />
              <PrivateRoute path="/subjects" component={ManageSubjects} />
              <PrivateRoute path="/masterdata/holidays" component={ManageHolidaysData} />
              <PrivateRoute path="/masterdata/feedata" component={ManageFeeData} />
              <Route path="/admin/masterdata/createfees" component={CreateFees} />
              <PrivateRoute path="/masterdata/nonteachingstaffroles" component={ManageNonTeachingStaffRoles} />
              <PrivateRoute path="/masterdata/chequeStatus" component={ChequeStatus} />
              <PrivateRoute path="/masterdata/manageupdates" component={ManageUpdates} />
            </Switch>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SchoolAdminLayout;
