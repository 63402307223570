import React, { useLayoutEffect } from "react";
const PrivacyPolicy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  const myInlineStyles = {
    marginLeft: '20px',
    paddingLeft: '10px'
  };
  return (
    <div>
      <section id="contact">
        <div class="container">
          <div class="row">
            <div class="header-section text-center margin-btm col-12 mt-5">
              <h2 class="title__line">Privacy Policy</h2>
            </div>
            <div class="col-md-12">
              <p>
                EDVANTUM ("we," "us," or "our'') is committed to safeguarding the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our web www.edvantum.com and mobile applications (''EDVANTUM App").
              </p>
              <br />
              <p>
                <h3>Information We Collect:</h3>
              </p>
              <p>
                <h5>Personal Information:</h5>
                <div style={myInlineStyles}>
                  Student Information: We may collect information provided by students during the registration process, including but &nbsp;not limited to names, ages, genders, and class details. We will also collect the performance data of the students .
                  <br />
                  <br />
                  Parent/Guardian Information: We may collect information about parents or guardians, including names, contact details, and relationships with the student. We will be collecting fees on the app which will go directly to the school account.
                  Teacher and Staff Information: For school staff using the app, we may collect professional information such as names, contact details, and roles within the school.
                </div>
              </p>
              <p>
                <h5>Non-Personal Information:</h5>
                <div style={myInlineStyles}>
                  Usage Information: We collect information about how users interact with the App, such as the pages viewed, features accessed, and time spent on the App.
                  <br />
                  Device Information: We may collect device-specific information, including device type, operating system, and unique device identifiers.
                </div>
              </p>
              <br />
              <p>
                <h3>How We Use Your Information:</h3>
                <div style={myInlineStyles}>
                  To Provide Services: We use the collected information to provide educational services, communicate with parents, and facilitate school-related activities.
                  Improvement of Services: We analyze usage patterns to enhance and improve our services, features, and user experience.
                  <br />
                  Communication: We use contact information to send important updates, announcements, and relevant information.
                </div>
              </p>
              <br />
              <p>
                <h3>Information Sharing:</h3>
                We do not sell, trade, or rent users' personal identification information to third parties. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers.
              </p>
              <br />
              <p>
                <h3>Data Security:</h3>
                We implement industry-standard security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
              </p>
              <br />
              <p>
                <h3>Your Choices:</h3>
                <div style={myInlineStyles}>
                  Access and Correction: Users may request access to their personal information and make corrections if necessary.
                  <br />
                  Opt-Out: Users have the right to opt out of non-essential communications.
                </div>
              </p>
              <br />
              <p>
                <h3>How long does Edvantum retain user data?:</h3>
                Currently, Edvantum retains data while the child is in the school and for one year afterwards. It may alter this practice according to legal and business requirements. For example, it may lengthen the retention period for some data if needed to comply with law or voluntary codes of conduct. Unless otherwise prohibited, it may shorten the retention period for some types of data if needed to free up storage space.
              </p>
              <br />
              <p>
                <h3>Log information</h3>
                When you access the Edvantum application as a service provider, it automatically forwards your geolocation coordinates to its servers. These server logs may include information such as your device id, and the date and time of your request.
              </p>
              <br />
              <p>
                <h3>User communications</h3>
                When you send an email or other communication to Edvantum, it may retain those communications in order to process your inquiries, respond to your requests and improve our Services.
              </p>
              <br />
              <p>
                <h3>Changes to this Privacy Policy:</h3>
                We reserve the right to update this Privacy Policy at any time. We will notify users about significant changes through the App or other means.
              </p>
              <br />
              <p>
                <h3>Your Consent:</h3>
                Edvantum believes that every user of our Application/Services/Products/Website must be in a position to provide an informed consent prior to providing any Information required for the use of the Application/Services/Products/Website. By registering with it, you are expressly consenting to its collection, processing, storing, disclosing and handling of your information as set forth in this Policy now and as amended by us. Processing your information in any way, including, but not limited to, collecting, storing, deleting, using, combining, sharing, transferring and disclosing information, all of which activities will take place in India. If you reside outside India your information will be transferred, processed and stored in accordance with the applicable data protection laws of India.
              </p>{" "}
              <br />
              <p>
                <h3>Contact Us:</h3>
                If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:info@edvantum.com">info@edvantum.com</a>.
                <br />
                <br />
                By using our App, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our App.
                <br />
                <br />
                This Privacy Policy was last updated on 23rd November, 2023.
              </p>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default PrivacyPolicy;
