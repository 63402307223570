import React from "react";
import { CSVLink } from "react-csv";

const ExportCoaches = ({coachesData=null}) => {
    function downloadCoachesCSVFormat() {
        let headers = [
            {label:"FirstName",key:"firstName"},
            {label:"LastName",key:"lastName"},
            {label:"Email",key:"email"},
            {label:"Mobile",key:"mobile"},
            {label:"Created At",key:"createdAt"},
            {label:"Updated At",key:"updatedAt"}
          ];
          return (
            <CSVLink
              data={coachesData}
              filename={"Coaches.csv"}
              headers={headers}
            >
            <i className="mdi mdi-download"></i> Export Data
            </CSVLink>
          );
    }
    return (
      <div>{downloadCoachesCSVFormat()}</div>
		);
}

export default ExportCoaches;