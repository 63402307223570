import React from "react";
import { CSVLink } from "react-csv";

const ExportBusData = ({ busData = null }) => {

  function downloadBusDataCSVFormat() {
    let headers = [
      { label: "Arriva Time", key: "arrivalTime" },
      { label: "Bus No", key: "busNo" },
      { label: "Capacity", key: "capacity" },
      { label: "Days Of Week", key: "daysOfWeek" },
      { label: "Departure Time", key: "departureTime" },
      { label: "Driver Name", key: "driver.name" },
      { label: "Leave From", key: "leaveFrom" },
      { label: "Monitor Name", key: "monitor.name" },
      { label: "Route No", key: "routeNo" },
      { label: "Stops BusFees", key: "stops.busFees" },
      { label: "Stops Destination", key: "stops.destination" },
      { label: "Subjects Data", key: "subjectsData" },
    ];
    return (
      <CSVLink
        data={busData}
        filename={"Bus_Data.csv"}
        headers={headers}
      >
        <i className="mdi mdi-download"></i>  Export Bus Data
      </CSVLink>
    );
  }

  return (
    <div> {downloadBusDataCSVFormat()} </div>
  );

}

export default ExportBusData;