import React, { useLayoutEffect } from "react";
const AboutUs = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>

            <section id="about" className="section-padding container">
                <div className="container-fluid">
                    <div className="header-section text-left margin-btm">
                        <h2 className="title__line1">EDVANTUM: HOLISTIC LEARNING SOLUTIONS</h2>
                        <p className="padding_minus">Your Partner in Digital Transformation</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="row box_auto box-white box_wide">
                            <div className="blog_card_content">
                                <h3>About us:</h3>
                                <span>
                                    Edvantum is a technology-enabled initiative to transform
                                    institution management through digital innovation
                                </span>
                            </div>
                            <p className="bordered_name_box">Core Leadership Team</p>
                            <div className="bordered_content">
                                <div className="row">
                                    <div className="col-md-3">
                                        <img
                                            className="avatar"
                                            src={require("../../assets/images/web/img_avatar.png")}
                                            alt="Avatar"
                                        />
                                        <div id="circle" />
                                    </div>
                                    <div className="col-md-9">
                                        <p>
                                            <b>Sumit Sharma</b>, <i>Co-founder &amp; CEO</i>
                                            <br />
                                            Sumit brings over 18 years of extensive expertise in private
                                            equity investments and mergers and acquisitions (M&amp;A). His
                                            track record includes adeptly overseeing investments in the
                                            financial services, telecommunications, and infrastructure
                                            sectors throughout South Asia.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <img
                                            className="avatar"
                                            src={require("../../assets/images/web/img_avatar.png")}
                                            alt="Avatar"
                                        />
                                        <div id="circle" />
                                    </div>
                                    <div className="col-md-9">
                                        <p>
                                            <b>Manu Sharma</b>, <i>Cofounder &amp; COO</i>
                                            <br />
                                            Manu is a serial entrepreneur with 14+ years of experience in incubating Retail focussed businesses. Manu has significant experience in providing management services to Academic institutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 ourSolutions">
                        <p className="h3 blog_card_content">
                            <strong>Our Solutions:</strong>
                        </p>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 customfont1 no-wrap-container">
                                    <h4>In-school activities</h4>
                                    <p className="text-md-30">Indoor &amp; out-door activities</p>
                                    <p>Online Performance checks</p>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  customfont1 no-wrap-container">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4  customfont1 for-mobile no-wrap-container">
                                                <h4>Mobile App</h4>
                                                <p>Real-time Dashboard</p>
                                                <p>Digital Fee collection</p>
                                                <p>Online Attendance</p>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                <img
                                                    className="imgact1 no-wrap-container"
                                                    src={require("../../assets/images/web/learning-solutions.png")}
                                                    alt="Center Image"
                                                />
                                            </div>
                                            <div className=" col-xs-4 col-sm-4 col-md-4 col-lg-4  customfont1  post-activity no-wrap-container">
                                                <h4>Post-school Activities</h4>
                                                <p>Outdoor activities</p>
                                                <p>Verified trainers</p>
                                                <p>Maintenance of <br /> infrastructure</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  customfont1 no-wrap-container">
                                    <h4 className="mt-3">Event Management</h4>
                                    <p>Summer Camps</p>
                                    <p>Inter-school turn-arounds</p>
                                    <p>Annual functions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>I
            <section id="about" className="container">
                <div className="header-section text-left margin-btm">
                    <h2 className="title__line1">
                        EDVANTUM APP: YOUR TRUSTED DIGITAL ALLY IN REVOLUTIONIZING THE WAY
                        SCHOOLS, PARENTS, TEACHERS, AND MANAGEMENT COLLABORATE
                    </h2>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 text-center ">
                        <img src={require("../../assets/images/web/edvantum-app-left.png")} alt="Center Image" />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 text-center">
                        <img src={require("../../assets/images/web/edvantum-app-right.png")} alt="Center Image" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                        <div className="box_blank col-sm-0 col-xs-0" />
                        <div className="box_wide">
                            <div>
                                <p>Online Fee collection</p>
                                <hr />
                                <p>Digital real-time notifications</p>
                                <hr />
                                <p>
                                    Effective communication and seamless coordination among all
                                    stakeholders
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                        <div className="row box_auto box_wide box-white">
                            <p>Available on Android and IOS platform</p>
                        </div>
                        <div className="row box_auto box_wide box-white">
                            <p>
                                Bridges the gap between Parents and Teachers, empowering all parties
                                to work together effortlessly and harmoniously.
                            </p>
                        </div>
                        <div className="row box_auto box_wide box-white">
                            <ul className="streamLines">
                                <li> Streamlines Communication
                                </li>
                                <li> Student- Centric Focus
                                </li>
                                <li> Efficiency
                                </li>
                                <li>
                                    Secure and Private
                                </li>
                                <li>
                                    Transparency and Accountability</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>




    );
};
export default AboutUs;
