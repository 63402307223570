import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Form } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import ClassApi from '../../../api/classApi';
import ExamScheduleApi from '../../../api/examScheduleApi';
import SchoolApi from '../../../api/schoolApi';
import StaffMemberApi from '../../../api/staffMember';
import { ExamSchedule } from './examSchedule';
import { toast } from 'react-toastify';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const AddExamSchedule = (props) => {
    const _id = props && props.match && props.match.params && props.match.params._id ? props.match.params._id : '';
    let [heading, setHeading] = useState('Add Exam Schedule');
    let [disabledOtions, setDisabledOtions] = useState(false);
    useEffect(() => {
        getExamSchedulesList();
        if (_id) {
            getExamSchedule(_id);
        }
        getStaffMembersByRole('teacher');
        if (localStorage.getItem('schoolId')) {
            getSchool(localStorage.getItem('schoolId'));
        }
    }, []);
    const [classesName, setClassesName] = useState([]);
    const [subjectsData, setSubjectsData] = useState([]);
    const [examSchedulesData, setExamSchedulesData] = useState([]);
    const [examScheduleFormData, setExamScheduleFormData] = useState(ExamSchedule);
    const [termsData, setTermsData] = useState([]);
    const [invigilatorList, setInvigilatorList] = useState([]);
    const [displayExamList, setDisplayExamList] = useState([]);
    const [currentSessionStartDate, setCurrentSessionStartDate] = useState([]);
    const [currentSessionEndDate, setCurrentSessionEndDate] = useState([]);
    const [data, setData] = useState({
        records: [],
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        }
    })

    const onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = await reorder(
            data.items,
            result.source.index,
            result.destination.index
        );

        setExamSchedulesData(data.items = items)
    }

    const getSchool = async (id) => {
        const schoolApi = new SchoolApi();
        await schoolApi.getSchool(id).then(async (response) => {
            if (response.data && response.data) {
                const termsExams = response.data.termsExams;
                const session = response.data.session;
                if (termsExams && termsExams.length && session && session.startsFromMonth) {
                    setTermsData(response.data.termsExams);
                    const sessionData = response.data.session;
                    const currentYear = new Date().getFullYear();
                    let sessionEndyear = new Date().getFullYear();
                    if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
                        sessionEndyear = currentYear + 1;
                    }
                    setCurrentSessionStartDate(new Date(currentYear, sessionData.startsFromMonth - 1, 1));
                    setCurrentSessionEndDate(new Date(sessionEndyear, sessionData.endsOnMonth, 0));
                }
            } else {
                setTermsData([])
            }
            return response;
        }).catch((error) => {
            console.error('There is an error!', error);
        });
    }

    const getExamSchedule = async (_id) => {
        if (_id) {
            let examScheduleApi = new ExamScheduleApi();
            await examScheduleApi.getExamSchedule(_id).then(async (response) => {
                if (response.data) {
                    response.data.dateOfExam = moment(response.data.dateOfExam).format('YYYY-MM-DD')
                    response.data.timeOfExam.from = moment(response.data.timeOfExam.from).format('hh:mm')
                    response.data.timeOfExam.to = moment(response.data.timeOfExam.to).format('hh:mm')
                    setExamScheduleFormData(response.data);
                } else {
                    setExamSchedulesData({})
                }
                return response;
            }).catch((error) => {
                toast.error(error.toString());
            });
        }
    }

    const getExamSchedulesList = async () => {
        const examScheduleApi = new ExamScheduleApi();
        await examScheduleApi.getExamSchedulesList().then(async (response) => {
            if (response.data && response.data.length) {
                setExamSchedulesData(response.data);
            } else {
                setExamSchedulesData([])
            }
            return response;
        }).catch((error) => {
            toast.error(error.toString());
            console.error('There is an error!', error);
        });
    }

    const getClassesByTerm = async (value) => {
        let classApi = new ClassApi();
        await classApi.getClassesByTerm(value).then(async (response) => {
            if (response.data && response.data.length) {
                setClassesName(response.data);
            } else {
                setClassesName([])
            }
        });
    }

    const getClassSubjects = async (value) => {
        const dt = { className: value };
        const classApi = new ClassApi();
        await classApi.getSubjectByClassName(dt).then(async (response) => {
            if (response.data && response.data.subjectsData && response.data.subjectsData.length) {
                setSubjectsData(response.data.subjectsData);
            } else {
                setSubjectsData([])
            }
        }).catch((error) => {
            toast.error(error.toString());
            console.error('There is an error!', error);
        });
    }


    const getStaffMembersByRole = async (role) => {
        const staffMemberApi = new StaffMemberApi();
        await staffMemberApi.getStaffMembersByRole(role).then(async (response) => {
            if (response.data && response.data.length) {
                setInvigilatorList(response.data);
            } else {
                setInvigilatorList([])
            }
            return response;
        }).catch((error) => {
            console.error('There is an error!', error);
        });
    }

    const getDisplayExamsList = async (examScheduleForm) => {
        const examScheduleApi = new ExamScheduleApi();
        await examScheduleApi.getDisplayExamsList(examScheduleForm).then(async (response) => {
            if (response.data && response.data.length) {
                setDisplayExamList(response.data);
            } else {
                setDisplayExamList([])
            }
            return response;
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const getSubjectName = (val) => {
        if (!val || !subjectsData || subjectsData.length <= 0) {
            return '';
        }
        const outlist = subjectsData.find(item => {
            return item._id === val;
        });
        if (outlist && outlist !== null) {
            return outlist.subjectName;
        } else {
            return '';
        }
    }


    function onChange(event) {
        const examScheduleForm = { ...examScheduleFormData };
        const { name, value } = event.target;
        switch (name) {
            case 'term':
                examScheduleForm.term = value;
                if (examScheduleForm.term) {
                    getClassesByTerm(examScheduleForm.term);
                }
                if (examScheduleForm.className && examScheduleForm.term) {
                    getDisplayExamsList(examScheduleForm)
                }
                examScheduleForm.className = '';
                examScheduleForm.subjectId = '';
                examScheduleForm.invigilator = '';
                examScheduleForm.dateOfExam = '';
                examScheduleForm.fromTimeOfExam = '';
                examScheduleForm.toTimeOfExam = '';
                break;
            case 'className':
                examScheduleForm.className = value;
                getClassSubjects(value);
                if (examScheduleForm.className && examScheduleForm.term) {
                    getDisplayExamsList(examScheduleForm)
                }
                examScheduleForm.subjectId = '';
                examScheduleForm.invigilator = '';
                examScheduleForm.dateOfExam = '';
                examScheduleForm.fromTimeOfExam = '';
                examScheduleForm.toTimeOfExam = '';
                break;
            case 'subjectId':
                examScheduleForm.subjectId = value;
                examScheduleForm.invigilator = '';
                examScheduleForm.dateOfExam = '';
                examScheduleForm.fromTimeOfExam = '';
                examScheduleForm.toTimeOfExam = '';
                break;
            case 'invigilator':
                examScheduleForm.invigilator = value;
                examScheduleForm.dateOfExam = '';
                examScheduleForm.fromTimeOfExam = '';
                examScheduleForm.toTimeOfExam = '';
                break;
            case 'dateOfExam':
                examScheduleForm.fromTimeOfExam = '';
                examScheduleForm.toTimeOfExam = '';
                const checkDoe = validateDateOfExam(value);
                if (checkDoe) {
                    examScheduleForm.dateOfExam = value;
                } else {
                    alert('date already selected');
                    examScheduleForm.dateOfExam = '';
                }
                break;
            case 'description':
                examScheduleForm.description = value;
                break;
        }
        // console.log(examScheduleForm);
        setExamScheduleFormData(examScheduleForm);
    }

    const validateDateOfExam = (doe) => {
        let flag = true;
        examSchedulesData.map(esd => {
            if (esd.className === examScheduleFormData.className && esd.term === examScheduleFormData.term) {
                const dateOfExam = moment(esd.subjects.dateOfExam).tz('Asia/Kolkata').format('YYYY-MM-DD');
                if (moment(doe).isSame(dateOfExam)) {
                    flag = false;
                    return;
                }
            }
        })
        return flag;
    }

    const validateMonitorId = (fromTimeOfExam, toTimeOfExam) => {
        const formFromTimeOfExam = moment(fromTimeOfExam).format("HH:mm:ss");
        const formToTimeOfExam = moment(toTimeOfExam).format("HH:mm:ss");
        let flag = true;
        examSchedulesData.map(item => {
            console.log('item',item)
            const dateOfExam = moment(item.subjects.dateOfExam).format('YYYY-MM-DD');
            if (item.subjects.monitorUserId === examScheduleFormData.invigilator && moment(examScheduleFormData.dateOfExam).isSame(dateOfExam) && examScheduleFormData._id != item._id) {
                const fromTimeOfExam = moment(item.subjects.timeOfExam.from).format("HH:mm:ss");
                const toTimeOfExam = moment(item.subjects.timeOfExam.to).format("HH:mm:ss");
                // [ in moment means inclusive of time/date where as ) means exclusive of time/date
                if ((formFromTimeOfExam >= fromTimeOfExam) && (formFromTimeOfExam <= toTimeOfExam)) {
                    flag = false;
                    return;
                } else if ((formToTimeOfExam >= fromTimeOfExam) && (formToTimeOfExam <= toTimeOfExam)) {
                    flag = false;
                    return;
                } 
            }
        })
        return flag;
    }

    const editSchedule = async (item) => {
        if (item.subjects && item.subjects._id) {
            getClassesByTerm(item.term);
            getClassSubjects(item.className);
            setHeading('Edit Exam Schedule')
            const records = {
                className: item.className,
                term: item.term,
                subjectId: item.subjectsData._id,
                dateOfExam: moment(item.subjects.dateOfExam).tz('Asia/Kolkata').format('YYYY-MM-DD'),
                fromTimeOfExam: moment(item.subjects.timeOfExam.from),
                toTimeOfExam: moment(item.subjects.timeOfExam.to),
                invigilator: item.subjects.monitorUserId ? item.subjects.monitorUserId : '',
                _id: item._id,
                description: item.description
            }
            setDisabledOtions(true);
            setExamScheduleFormData(records)
        }
    }

    const deleteSchedule = async (item) => {
        if (item.subjects && item.subjects._id) {
            const examScheduleApi = new ExamScheduleApi();
            await examScheduleApi.deleteExamSchedule(item.subjects._id)
                .then((response) => {
                })
                .catch((error) => {
                    console.error('There is ERROR!', error)
                })
            getExamSchedulesList()
        }
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">{heading}</h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={(e) => addExamSchedule(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Term<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <select
                                                    className="form-control"
                                                    name="term"
                                                    value={examScheduleFormData.term}
                                                    required={true}
                                                    onChange={(e) => onChange(e)}
                                                    disabled={disabledOtions ? true : null}
                                                >
                                                    <option value="">Select Term</option>
                                                    {
                                                        termsData.map((resp) => <option key={resp._id} value={resp._id}>{resp.term}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Select Class<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <select
                                                    className="form-control"
                                                    name="className"
                                                    value={examScheduleFormData.className}
                                                    required={true}
                                                    onChange={(e) => onChange(e)}
                                                    disabled={disabledOtions ? true : null}
                                                >
                                                    <option value="">Select Class</option>
                                                    {
                                                        classesName.map((resp) => <option key={resp}>{resp}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Subject<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <select
                                                    className="form-control"
                                                    name="subjectId"
                                                    value={examScheduleFormData.subjectId}
                                                    required={true}
                                                    onChange={(e) => onChange(e)}
                                                >
                                                    <option value="">Select Subject</option>
                                                    {
                                                        subjectsData.map((resp) => <option key={resp._id} value={resp._id}>{resp.subjectName}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Invigilator</label>
                                            <div className="col-sm-9">
                                                <select
                                                    className="form-control"
                                                    name="invigilator"
                                                    value={examScheduleFormData.invigilator}
                                                    onChange={(e) => onChange(e)}
                                                >
                                                    <option value="">Select Invigilator</option>
                                                    {
                                                        invigilatorList.map((resp) => <option key={resp._id} value={resp._id}>{resp.firstName} {resp.lastName}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Date<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control
                                                    type="date"
                                                    name="dateOfExam"
                                                    value={examScheduleFormData.dateOfExam}
                                                    required={true}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Time<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-4">
                                                <Datetime
                                                    dateFormat={false}
                                                    // name="timeFrom"
                                                    value={examScheduleFormData && examScheduleFormData.fromTimeOfExam != ''? moment(examScheduleFormData.fromTimeOfExam) : ""}
                                                    onChange={(e) => {
                                                        let examScheduleForm = { ...examScheduleFormData };
                                                        if(examScheduleForm.fromTimeOfExam){     
                                                            let check = validateMonitorId(e, examScheduleForm.toTimeOfExam);
                                                            if (check) {
                                                                examScheduleForm.fromTimeOfExam = moment(e);
                                                            } else {
                                                                alert("Time is in between existing slot");
                                                            }
    
                                                            setExamScheduleFormData(examScheduleForm)
                                                        }else{
                                                            let check = validateMonitorId(e, examScheduleForm.toTimeOfExam);
                                                            if (check) {
                                                                examScheduleForm.fromTimeOfExam = moment(e);
                                                            } else {
                                                                alert("Time is in between existing slot");
                                                            }
                                                            examScheduleForm.toTimeOfExam = '';
    
                                                            setExamScheduleFormData(examScheduleForm)

                                                        }
                                                        }
                                                    }
                                                />
                                            </div>
                                            <div className="col-1">
                                                <label className="col-form-label">To</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <Datetime
                                                    dateFormat={false}
                                                    value={examScheduleFormData && examScheduleFormData.toTimeOfExam != ''? moment(examScheduleFormData.toTimeOfExam) : ""}
                                                    onChange={(e) => {
                                                            let examScheduleForm = { ...examScheduleFormData };

                                                            const selectedTime = moment(e).format("HH:mm:ss");
                                                            if (examScheduleForm.fromTimeOfExam) {
                                                                const timeFrom = moment(examScheduleForm.fromTimeOfExam).format("HH:mm:ss");
                                                                if (timeFrom >= selectedTime) {
                                                                    alert('Time To less than Time From');
                                                                } else {
                                                                    const checkMonitorId = validateMonitorId(examScheduleForm.fromTimeOfExam, e);
                                                                    if (checkMonitorId) {
                                                                        examScheduleForm.toTimeOfExam = moment(e);
                                                                        setExamScheduleFormData(examScheduleForm)
                                                                    } else {
                                                                        alert("Time is in between existing slot");
                                                                    }
                                                                }
                                                            } else {
                                                                alert('Choose Time From First');
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Description<span style={data.startstyle}></span></label>
                                            <div className="col-sm-9">
                                                <textarea
                                                    type="textarea"
                                                    className="form-control"
                                                    rows="4"
                                                    name="description"
                                                    value={examScheduleFormData.description}
                                                    onChange={(e) => onChange(e)}
                                                ></textarea>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                {displayExamList.map((item, index) => (
                                                    <span key={item.subjects && item.subjects._id}>
                                                        <span className="row">
                                                            <div className="col-md-1">
                                                                {item.className}
                                                            </div>
                                                            <div className="col-md-2">
                                                                {item.subjectsData && item.subjectsData.subjectName}
                                                            </div>
                                                            <div className="col-md-2">
                                                                {moment(item.subjects && item.subjects.dateOfExam).tz('Asia/Kolkata').format('DD/MM/YYYY')}
                                                            </div>
                                                            <div className="col-md-3">
                                                                {moment(item.subjects && item.subjects.timeOfExam.from).format('hh:mm A')} to {moment(item.subjects && item.subjects.timeOfExam.to).format('hh:mm A')}
                                                            </div>
                                                            <div className="col-md-2">
                                                                {item.invigilatorName}
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span className="float-right">
                                                                    <ReactTooltip id='Edit' type='warning' effect='solid'>
                                                                        <span>Edit</span>
                                                                    </ReactTooltip>
                                                                    <button data-tip data-for="Edit" onClick={() => editSchedule(item)} type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                                                                        <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                                                                    </button>

                                                                    <ReactTooltip id='Delete' type='error' effect='solid'>
                                                                        <span>Delete</span>
                                                                    </ReactTooltip>

                                                                    <button data-tip data-for="Delete" onClick={() => deleteSchedule(item)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
                                                                        <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </span>
                                                ))}
                                                
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5, float: 'right' }}>Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function clearForm() {
        const examScheduleForm = { ...examScheduleFormData };
        examScheduleForm.className = '';
        examScheduleForm.term = '';
        examScheduleForm.subjectId = '';
        examScheduleForm.dateOfExam = '';
        examScheduleForm.fromTimeOfExam = '';
        examScheduleForm.toTimeOfExam = '';
        examScheduleForm.timeOfExam = {};
        examScheduleForm.timeOfExam.from = '';
        examScheduleForm.timeOfExam.to = '';
        examScheduleForm.invigilator = '';
        examScheduleForm.description = '';
        setExamScheduleFormData(examScheduleForm);
        setDisplayExamList([]);
    }

    async function addExamSchedule(e) {
        e.preventDefault();
        localStorage.getItem('user_info');
        if (localStorage.getItem('user_info') && !examScheduleFormData._id) {
            const user = JSON.parse(localStorage.getItem('user_info'));
            examScheduleFormData.addedByUserId = user.userId;
        }
        if (examSchedulesData && examSchedulesData.length) {
            const cName = examSchedulesData.find(item => {
                const like = moment(examScheduleFormData.dateOfExam).isBetween(currentSessionStartDate, currentSessionEndDate);
                // to check if the session is same or different.
                return item.className === examScheduleFormData.className && item.term === examScheduleFormData.term && like;
            })
            if (cName && cName._id) {
                examScheduleFormData._id = cName._id;
            }
        }

        const examScheduleApi = new ExamScheduleApi();
        await examScheduleApi
            .save(examScheduleFormData)
            .then((data) => {
                if (data.success) {
                    setDisabledOtions(false);
                    toast.success(data.msg.toString());
                    clearForm();
                    getExamSchedulesList();
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                toast.error(error.toString());
                console.error('There was an error!', error);
            });
    }
}

export default AddExamSchedule;
