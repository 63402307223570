import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import MaterialTable from "material-table";
import Loader from "../../../components/loader";
import { toast } from "react-toastify";
import ExamScheduleApi from '../../../api/examScheduleApi';
const PrintExamScheduleModal = ({data,examSchedulesData,selectedSession,selectedTerm,field,handleClose,isLoader, selectedTermId }) =>{

    const generateExamSchedulePDF = async (term, session) => {
        const examScheduleApi = new ExamScheduleApi();
        examScheduleApi.generateExamDatesheetPDF(selectedTerm, term, session).then(async (response) => {
            if (response.success) {
                window.open(
                    "/api/downloadExam",
                    "_blank",
                    "location=no,height=700,width=850,scrollbars=yes,status=yes"
                );
            } else {
                toast.error("error in PDF");
            }
        });
    };
    return ( 
        <Modal
            id="divcontents"
            show={data.isModalVisible}
            size="xl"
            onHide={() => handleClose()}
        >
            <Modal.Header closeButton>  
                <h3 className="page-title"> Exam Schedule </h3>
            </Modal.Header>
            {examSchedulesData ? (
                <div className="card" id="cardcontents">
                    {isLoader ? <Loader /> : null}
                    <div className="card-body">
                        <div
                            className="row cntr-cls"
                            style={{ justifyContent: "center" }}
                        >
                            <h4> {selectedSession ? selectedSession : ""} (Term {selectedTerm ? selectedTerm : ""})</h4>
                        </div>
                        <div className="row cntr-cls" style={{ justifyContent: "center" }}>
                            <MaterialTable
                                title=""
                                data={examSchedulesData}
                                columns={field()}
                                options={{ search: false, paging: false, exportButton: false, sorting: false }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
            <Modal.Footer
                id="hide-fee-rcpt-id"
                style={{ backgroundColor: "#ffffff" }}
            >
                <Button variant="secondary" onClick={() => handleClose()}>
                    Close
                </Button>
                <Button
                    className="button1"
                    variant="primary"
                    onClick={() => {
                        handleClose();
                        generateExamSchedulePDF(selectedTermId, selectedSession);
                    }}
                >
                    Print
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PrintExamScheduleModal;