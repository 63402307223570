import React from "react";
import axios from "axios";

export const checkEmail = async (email) => {
    try {
      let data = await axios.post(`http://localhost:8097/api/auth/checkEmail`,{
          "email": email
      });
      return data
    } catch (e) {
      return e
    }
};

export const login = async (user) => {
  try {
    let data = await axios.post(`http://localhost:8097/api/auth/login`,user);
    return data
  } catch (e) {
    return e
  }
};

export const checkForMobileAndSendOTP = async (mobile) => {
  try {
    let data = await axios.post(`http://localhost:8097/api/auth/otplogin`,{
        "mobile": mobile
    });
    return data

  } catch (e) {
    return e
  }
};

export const capitalize = (input)=> {  
  var words = input.split(' ');  
  var CapitalizedWords = [];  
  words.forEach(element => {  
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));  
  });  
  return CapitalizedWords.join(' '); 
}

export const FallbackLoader = () => {
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid row mt-5">
          <div className="col-12 text-center mt-5">
            <img
              className="responsive"
              style={{width: "20%"}}
              src={require("../assets/images/Edvantum.png")}
              alt=""
              loading="lazy"
            />
          </div>
          <div className="col-12">
            <div className="dot-opacity-loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};