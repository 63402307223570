import axios from "axios";
import * as actions from "../action/getSchoolAction";
const API_URL = process.env.REACT_APP_API_URL;

const GetSchool =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type !== actions.apiCallBegan.type) return next(action);
        const { url, method,params, data, onStart, onSuccess, onError } =
            action.payload;
        if (onStart) dispatch({ type: onStart });
        next(action);
        try {
            let token = localStorage.getItem("token");
            const response = await axios.request({
                headers: {'authorization': `Bearer ${token}`},
                baseURL: API_URL,
                url,
                method,
                data,
                params
            });
            dispatch(actions.apiCallSuccess(response.data));
            console.log('response.data',response.data)
            if (onSuccess)
                dispatch({ type: onSuccess, payload: response.data });
        } catch (error) {
            dispatch(actions.apiCallFailed(error.message));
            if (onError) dispatch({ type: onError, payload: error.message });
        }
    };

export default GetSchool;