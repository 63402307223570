import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import AuthApi from '../../../api/authApi';

const ChangePassword = () => {
    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        tabstyle: {
            border: '1px solid #333',
            borderRadius: '12px',
            padding: '2px 5px',
            margin: '5px',
            color: '#333',
            fontSize: '13px',
        },
        tabCrossIconStyle: {
            fontSize: '15px',
            color: '#333'
        },
        rowPropsId:"",
        selectedClass: '',
    })
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    useEffect(() => {
    },[]);

    // ================================================== coding part =========================================

    const handleChanges = (event) =>{
        let c = { ...formData };
        let { name, value } = event.target;
        if (name === 'oldPassword'){
            c.oldPassword = value
        } else if(name === 'newPassword') {
            c.newPassword = value
        } else if(name === 'confirmPassword') {
            c.confirmPassword = value
        }
        setFormData(c);
    }
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        const u = localStorage.getItem('user_info') && JSON.parse(localStorage.getItem('user_info'));
        const _id = u.userId;
        const {
            oldPassword,
            newPassword,
            confirmPassword
        } = formData;
        const authApi = new AuthApi();
        if (_id) {
            await authApi.changePassword({
                _id,
                oldPassword,
                newPassword,
                confirmPassword
            }).then((data) => {
                if (data.success) {
                    toast.success(data.msg.toString());
                    cleanFields();
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                toast.error(error.toString());
                console.error("There was an error!", error);
            });
        }
    }

  
    const cleanFields = () => {
        const c = { ...formData}
        c.oldPassword = '';
        c.newPassword = '';
        c.confirmPassword = '';
        setFormData(c);
    }
    
    // =================================================/ coding part /========================================

    return(
        <div>
            <div className="page-header">
                <h3 className="page-title">Change Password </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">    
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Old Password<span style={data.startstyle}></span></label>
                                            <div className="col-sm-9">
                                                <Form.Control
                                                    type="password"
                                                    name="oldPassword"
                                                    placeholder="Old Password"
                                                    required={true}
                                                    onChange={(e)=>handleChanges(e)}
                                                    value={formData.oldPassword}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">New Password<span style={data.startstyle}></span></label>
                                            <div className="col-sm-9">
                                                <Form.Control
                                                    type="password"
                                                    name="newPassword"
                                                    placeholder="New Password"
                                                    required={true}
                                                    onChange={(e)=>handleChanges(e)}
                                                    value={formData.newPassword}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Confirm Password<span style={data.startstyle}></span></label>
                                            <div className="col-sm-9">
                                                <Form.Control
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    required={true}
                                                    onChange={(e)=>handleChanges(e)}
                                                    value={formData.confirmPassword}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw float-right" style={{lineHeight:1.5}}>Update</button>
                                    </div>
                                </div>
                                {/* ========================================================== */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
