import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import Gallery from "../../../api/galleryApi";
import { Trash } from 'react-bootstrap-icons'

const UpdateGallery = () => {
    const [galleries, setGalleries] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let GalleryApi = new Gallery();
        await GalleryApi.getGallery().then(async (response) => {
            if (response.success && response.data && response.data.length) {
                setGalleries(response.data);
            } else {
                setGalleries([]);
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    async function updateGallery(value) {
        let GalleryApi = new Gallery();
        const data = new FormData();
        data.append("file", value[0]);
        await GalleryApi.updateGallery(data).then((response) => {
            if (response && response.success) {
                toast.success(response.msg);
                getData();
            }
        }).catch(() => {
            toast.error("Upload failed");
        });
    }

    async function deleteGallery(id) {
        let GalleryApi = new Gallery();
        await GalleryApi.deleteGallery({ id: id }).then((response) => {
            if (response && response.success) {
                toast.success(response.msg);
                getData();
            }
        }).catch(() => {
            toast.error("Delete failed");
        });
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">Update Gallery</h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Control
                                        type="file"
                                        className="form-control"
                                        id="customFileLang"
                                        name="photo"
                                        onChange={(e) => updateGallery(e.target.files)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-5">
                                {galleries &&
                                    galleries.map(item => (
                                        <div className="col-md-3" key={`Image ${item._id}`}>
                                            <div style={{ position: 'relative' }}>
                                                <img
                                                    src={item.fileUrl}
                                                    className="w-100 shadow-1-strong rounded mb-4"
                                                    height={"250px"}
                                                />
                                                <Trash
                                                    size={20}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                        cursor: 'pointer',
                                                        zIndex: 1,
                                                        color: 'red'
                                                    }}
                                                    onClick={() => deleteGallery(item._id)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default UpdateGallery;