import React, { useEffect, useState } from "react";
import Grid from "../../../components/dataTable";
import { Form, Modal, Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import Class from "../../../api/classApi";
import Subject from "../../../api/subjectsApi";
import Loader from "../../../components/loader";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import DeleteModal from "../../../components/deleteAlertPopup";
import Classes from "../../../components/bulkupload/classes";
import ExportClasses from "../../../components/export/classes"

const ClassInformation = () => {
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    tabstyle: {
      border: "1px solid #333",
      borderRadius: "12px",
      padding: "2px 5px",
      margin: "5px",
      color: "#333",
      fontSize: "13px",
    },
    tabCrossIconStyle: {
      fontSize: "15px",
      color: "#333",
    },
    romanNumbers: [],
    alphabet: [],
    intermediateSection: [],
    rowPropsId: "",
    selectedClass: "",

    _id: "",
    className: "",
    section: "",
    classTeacherUserId: "",
    selectedSubjectList: [],
  });
  const [rows, setRows] = useState([]);
  const [record, setRecord] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [sreachKeyword, setSreachKeyword] = useState(null);

  const handleList = async (e, flag) => {
    let fr = [];
    let { name, value } = e.target;
    if (flag == "search") {
      setSreachKeyword(value);
    }
    await setRows(fr);
  };

  useEffect(() => {
    getList(sreachKeyword);
  }, [sreachKeyword]);

  useEffect(() => {
    subjectList();
    getDefaultClassesSettings();
  }, []);

  const getList = async (sreachKeyword) => {
    setDataLoader(true);
    let ClassApi = new Class();
    await ClassApi.getClass(sreachKeyword)
      .then(async (response) => {
        if (response.data && response.data.length) {
          setRows(response.data);
          setTeacherData(response.teacherData);
        } else {
          setRows([]);
          setTeacherData([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };
  const subjectList = async () => {
    setDataLoader(true);
    let subjectApi = new Subject();
    await subjectApi
      .getSubject()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setRecord(response.data);
        } else {
          setRecord([]);
        }
        return response;
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  async function getDefaultClassesSettings() {
    let ClassApi = new Class();
    let response = await ClassApi.getDefaultClassesSettings();
    if (response.success && response.data) {
      let clsData = { ...data };
      clsData.romanNumbers = response.data.classes;
      clsData.alphabet = response.data.sections;
      clsData.intermediateSection = response.data.intermediateSection;
      setData(clsData);
    }
  }

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          onClick={() => editClass(props)}
          data-tip
          data-for="Edit"
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>

        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>

        <button
          data-tip
          data-for="Delete"
          onClick={() => isVisible2(props._id)}
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  };

  const ShowSubjects = (props) => {
    return props.subject_docs.map((data) => (
      <span
        key={data._id}
        onClick={() =>
          deleteOneByOne(props.subjects, data._id, props.className)
        }
      >
        <Link style={data.tabstyle}>
          <i
            className="mdi mdi-close-circle"
            style={data.tabCrossIconStyle}
          ></i>
          {data.subjectName}
        </Link>
      </span>
    ));
  };

  const ShowClass = (props) => {
    return (
      <span>
        {props.className}{props && props.section ? "-" : ""}{props.section ? props.section : ""}
      </span>
    );
  };
  const ShowClassTeacher = (props) => {
    return <span>{props.teacherName ? props.teacherName : ""}</span>;
  };

  const field = () => {
    const fields = [
      {
        title: "Classes",
        render: ShowClass,
        width: "0%",
      },
      {
        title: "Subjects",
        render: ShowSubjects,
      },
      {
        title: "Class Teacher",
        render: ShowClassTeacher,
      },
      {
        name: "action",
        title: "Actions ",
        width: "0%",
        render: renderAction,
      },
    ];
    return fields;
  };

  const handleClose = () => {
    let c = { ...data };
    c.selectedClass = "";
    c.selectedSubjectList = [];
    setData(c);
    setIsModalVisible(false);
  };

  const deleteOneByOne = (dt, selectedId, className) => {
    let list = [];
    dt.map((dt) =>
      dt.subjectId != selectedId ? list.push(dt.subjectId) : null
    );
    setData(
      (data.selectedSubjectList = list),
      (data.selectedClass = className)
    );
    updateSubjectList();
  };

  // ================================================== coding part =========================================

  const handleChanges = async (event) => {
    const c = { ...data };
    const { name, value } = event.target;
    if (name == "className") {
      c.className = value;
    } else if (name == "section") {
      c.section = value;
    } else if (name == "classTeacherUserId") {
      c.classTeacherUserId = value;
    } else if (name == "selectedClass") {
      let valueSplit = value.split("-");
      if (valueSplit[0] == "Pre") {
        valueSplit[0] = "Pre-nursery"
      }
      if (valueSplit[0] == "Play") {
        valueSplit[0] = "Play-way"
      }
      loadPreAddedClassSubjets(valueSplit[0].trim());
      c.selectedClass = value;
    }
    setData(c);
  };

  const loadPreAddedClassSubjets = (className, section = null) => {
    let d = { ...data };
    d.selectedSubjectList.splice(0, d.selectedSubjectList.length);
    let flag = true;
    rows.map((dt) => {
      if (flag && dt.className == className) {
        flag = false;
        if (dt.subject_docs)
          dt.subject_docs.map((o) => {
            d.selectedSubjectList.push(o._id);
          });
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let { _id, className, section, classTeacherUserId } = data;
    let ClassApi = new Class();
    if (className) {
      let isClassName = rows && rows.filter((item) => {
        return item && item.section && item.className.toString() == className.toString() && item.section.toString() == section;
      })
      if (isClassName && isClassName.length) {
        return toast.error("This class is already added");
      }
    }
    if (data._id) {
      await ClassApi.editClass({
        _id,
        className,
        section,
        classTeacherUserId,
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList(sreachKeyword);
          } else {
            console.log("else")
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    } else {
      await ClassApi.createClass({
        className,
        section,
        classTeacherUserId,
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList(sreachKeyword);
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    }
  };
  const updateSubjectList = async (event) => {
    let ClassApi = new Class();
    if (data.selectedClass && data.selectedSubjectList) {
      let selectedSubjects = data.selectedSubjectList.map((id) => ({
        subjectId: id,
      }));
      let clas = data.selectedClass.split("-");

      if (clas[0] == "Pre") {
        clas[0] = "Pre-nursery";
      }
      if (clas[0] == "Play") {
        clas[0] = "Play-way";
      }
      await ClassApi.updateSubjectList({
        className: clas[0].trim(),
        subjectId: selectedSubjects,
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList(sreachKeyword);
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
      handleClose();
    }
  };

  const cleanFields = () => {
    let c = { ...data };
    c._id = "";
    c.className = "";
    c.section = "";
    c.classTeacherUserId = "";
    c.selectedSubjectList = [];
    setData(c);
  };

  const editClass = (props) => {
    let c = { ...data };
    c._id = props._id;
    c.className = props.className;
    c.section = props.section;
    c.classTeacherUserId = props.classTeacherUserId;
    setData(c);
  };

  const deleteClassInfo = async () => {
    let ClassApi = new Class();
    isVisible2();
    await ClassApi.deleteClass(data.rowPropsId)
      .then((response) => {
        toast.success(response.msg.toString());
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is ERROR!", error);
      });
    getList(sreachKeyword);
  };

  const ShowsubjectList = () => {
    return record.map((subject) => (
      <>
        <div className="col-sm-6">
          <Form.Group>
            <div className="form-check form-check-success">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input"
                  disabled={
                    data.selectedClass && data.selectedClass != ""
                      ? false
                      : true
                  }
                  checked={
                    data.selectedSubjectList
                      ? data.selectedSubjectList.indexOf(subject._id) !== -1
                      : false
                  }
                  onClick={() => addorRemove(subject)}
                />{" "}
                {subject.subjectName}
                <i className="input-helper"></i>
              </label>
            </div>
          </Form.Group>
        </div>
      </>
    ));
  };

  const addorRemove = async (subject) => {
    let c = { ...data };
    let flag = "";
    await c.selectedSubjectList.map((id) => {
      if (id.toString() == subject._id.toString()) {
        flag = id;
      }
    });
    if (flag == "") {
      c.selectedSubjectList.push(subject._id);
    } else {
      let index = c.selectedSubjectList.findIndex((s) => { return s == subject._id });
      delete c.selectedSubjectList[index];
    }
    setData(c);
  };

  const modelOpen = () => {
    setIsModalVisible(true);
  };

  // =================================================/ coding part /========================================

  return (
    <div className="container">
      <div className="page-header">
        <h3 className="page-title"> Classes </h3>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-5">
                    <Form.Group className="align-items-center">
                      <label>Class Name<span style={data.startstyle}>*</span></label>
                      <select
                        className="form-control"
                        name="className"
                        value={data.className}
                        onChange={handleChanges}
                        required
                        style={{ height: "35px" }}
                      >
                        <option value="">----</option>
                        {data && data.romanNumbers && data.romanNumbers.length
                          ? data.romanNumbers.map((val, i) => (
                            <option key={i} value={val.className}>
                              {val.className}
                            </option>
                          ))
                          : null}
                      </select>
                    </Form.Group>
                  </div>
                  <div className="col-md-5">
                    <Form.Group className="align-items-center">
                      <label>Section<span style={data.startstyle}></span></label>
                      <select
                        className="form-control"
                        name="section"
                        value={data.section}
                        onChange={handleChanges}
                        style={{ height: "35px" }}
                      >
                        <option value="">----</option>
                        {data.className === "XI" ||
                          (data.className === "XII" && data.intermediateSection &&
                            data.intermediateSection.length)
                          ? data.intermediateSection.map((val, i) => (
                            <option key={i} value={val}>
                              {val}
                            </option>
                          ))
                          : data && data.alphabet && data.alphabet.length &&
                          data.alphabet.map((val, i) => (
                            <option key={i} value={val}>
                              {val}
                            </option>
                          ))}
                      </select>
                    </Form.Group>
                  </div>
                  <div className="col-md-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      {data._id ? "Update" : "Add"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-6 mb-4">
          <div className="input-group">
            <div className="form-outline col-12">
              <input type="search" id="form1" className="form-control" placeholder="Search ..."
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    handleList(e, "search")
                  } else if (e.target.value.length == 0) {
                    setSreachKeyword("");
                  }
                }
                } />
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2">
              <div className="col-md-5 import">
                <Classes />
              </div>
              <div className="col-md-5 export">
                <ExportClasses classData={rows} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={modelOpen}
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: "8px" }}
                >
                  Add Subject
                </button>
              </div>
            </div>
            <Grid
              columns={field()}
              rows={rows}
              settings={{
                search: false,
                paging: false,
                exportButton: false,
                sorting: true
              }}
            />
          </div>
        </div>

        <DeleteModal
          show={isModalVisible2}
          isModalVisible2={isVisible2}
          deleteData={deleteClassInfo}
        />
      </div>
      {/* ================================= */}
      <Modal show={isModalVisible} onHide={() => handleClose()}>.
        <Modal.Header closeButton>
          <h3 className="page-title"> Subjects </h3>
        </Modal.Header>
        <div className="card">
          <div className="card-body">
            <Form.Group className="row">
              <label className="col-sm-3 col-form-label">
                Select Class<span style={data.startstyle}>*</span>
              </label>
              <div className="col-sm-9">
                <select
                  className="form-control"
                  name="selectedClass"
                  onChange={handleChanges}
                >
                  <option value="">-----</option>
                  {rows && rows.map((resp) => (
                    <option>
                      {resp.className} {resp.section ? "-" + resp.section : ""}
                    </option>
                  ))}
                </select>
              </div>
            </Form.Group>
            <form>
              <div className="row">{ShowsubjectList()}</div>
            </form>
          </div>
        </div>
        <Modal.Footer style={{ backgroundColor: "#ffffff" }}>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => updateSubjectList(e)}
            disabled={!data.selectedClass ? true : false}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ================================= */}
    </div>
  );
};

export default ClassInformation;
