import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "../../../components/dataTable";
import Loader from "../../../components/loader";
import CoachApi from "../../../api/coachApi";
import { toast } from "react-toastify";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import DeleteModal from "../../../components/deleteAlertPopup";
import StatusModal from "../../../components/alertPopup";
import ExportCoachesCSV from "../../../components/export/coaches";

const ManageCoaches = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    isModalVisible: false,
    isModalVisible2: false,
    idForDelete: "",
  });

  const [archiveListActive, setArchinveListActive] = useState(true);
  const [selecteUser, setSelecteUser] = useState();
  const [dataLoader, setDataLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: "",
  });

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let list = [];
    setDataLoader(true);
    let Api = new CoachApi();
    await Api.getCoaches().then(async (response) => {
      if (response.data && response.data.length) {
        await response.data.map((data) => {
          console.log("data", data)
          if (data.active && !archiveListActive) {
            list.push(data);
          } else if (!data.active && archiveListActive) {
            list.push(data);
          }
        });
        setRows(response.data);
        setSelectedRows(list)
      } else {
        setRows([]);
        setSelectedRows([])
      }
    })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const handleClose = () => {
    let d = { ...data };
    d.isModalVisible = false;
    d.isModalVisible2 = false;
    setSelecteUser();
    setStatusMsg({
      msg: "",
      buttonName: "",
    });
    setData(d);
  };

  const deleteCoach = async () => {
    let Api = new CoachApi();
    await handleClose();
    await Api.deleteCoach(selecteUser)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    getList();
  };

  const isVisible1 = (props = null) => {
    let d = { ...data };
    if (props) {
      setSelecteUser(props._id);
    }
    d.isModalVisible = !d.isModalVisible;
    setData(d);
  };

  const deactieActionApi = async () => {
    let Api = new CoachApi();
    let data = { _id: selecteUser._id, activate: !selecteUser.active };
    await Api.activateOrDactivate(data)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is ERROR!", error);
      });
    handleClose();
    await getList();
  };

  const DeactiveAction = (props, index) => {
    return (
      <span>
        <ReactTooltip
          id="activate"
          type={props.active ? "success" : "error"}
          effect="solid"
        >
          <span>Activate/Deactive</span>
        </ReactTooltip>
        <span
          onClick={() => {
            let d = { ...data };
            if (props.active) {
              setStatusMsg({
                msg: `Are you sure that you want to Deactivate ${props.firstName}`,
                buttonName: "Deactivate",
              });
            } else {
              setStatusMsg({
                msg: `Are you sure that you want to Activate ${props.firstName}`,
                buttonName: "Activate",
              });
            }
            d.isModalVisible2 = true;
            setData(d);
            setSelecteUser(props);
          }}
          data-tip
          data-for="activate"
          className={
            props.active
              ? "form-check form-check-success"
              : "form-check form-check-danger"
          }
          style={{ display: "inline-flex" }}
        >
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={props.active}
            />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    );
  };

  const CoachHyperLink = (props) => {
    return (
      <span>
        <Link to={`/managecoaches/addcoachdetails?id=${props._id}`}>
          {props.firstName} {props.lastName}
        </Link>
      </span>
    );
  };

  const createdAt = (props) => {
    if (props.createdAt) {
      return <span>{moment(props.createdAt).format("DD/MM/YYYY")}</span>;
    }
  };

  const updatedAt = (props) => {
    if (props.updatedAt) {
      return <span>{moment(props.updatedAt).format("DD/MM/YYYY")}</span>;
    }
  };

  const checkArchive = async (archive) => {
    let list = [];
    setArchinveListActive(archive);
    if (rows) {
      rows.map((data) => {
        if (data.active && !archive) {
          list.push(data);
        } else if (!data.active && archive) {
          list.push(data);
        }
      });
    }
    setSelectedRows(list);
  };

  const renderAction = (props) => {
    return (
      <span
        style={{
          display: "block",
          width: "76px",
        }}
      >
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          // onClick={() => history.push(`/addcoachdetails?id=${props._id}`)}
          onClick={() => history.push(`/managecoaches/addcoachdetails?id=${props._id}`)}
          data-tip
          data-for="Edit"
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>

        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>
        <button
          onClick={() => {
            isVisible1(props);
          }}
          data-tip
          data-for="Delete"
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const field = () => {
    const fields = [
      {
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: DeactiveAction,
      },
      {
        title: "Coach Name",
        render: CoachHyperLink
      },
      {
        title: "Email",
        field: "email"
      },
      {
        title: "Mobile",
        field: "mobile"
      },
      {
        title: "Created At",
        field: "createdAt",
        render: createdAt
      },
      {
        title: "Updated At",
        field: "updatedAt",
        render: updatedAt
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      }
    ];
    return fields;
  };

  return (
    <>
      <div className="page-header">
        <h3 className="page-title"> Manage Coaches </h3>
        <Link className="nav-link" to="/managecoaches/addcoachdetails">
          <button type="submit" className="btn btn-primary">Add</button>
        </Link>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2" style={{ justifyContent: "flex-end" }}>
              <div className="col-md-5 export">
                <ExportCoachesCSV coachesData={selectedRows} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={async () => {
                    checkArchive(!archiveListActive);
                  }}
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: "8px" }}
                >
                  {archiveListActive ? "Active List" : "Archive List"}
                </button>
              </div>
            </div>
            <Grid
              columns={field()}
              rows={selectedRows}
              settings={{
                search: true,
                sorting: true
              }} />
          </div>
        </div>
      </div>
      <DeleteModal
        show={data.isModalVisible}
        isModalVisible2={isVisible1}
        deleteData={deleteCoach}
      />
      <StatusModal
        show={data.isModalVisible2}
        isModalVisible2={handleClose}
        deleteData={deactieActionApi}
        body={statusMsg}
      />
    </>
  );
};

export default ManageCoaches;