import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import StaffMember from "../../../api/staffMember"
import { toast } from 'react-toastify';
const AddAccessLevels = (props) => {
    const _id = props && props.match && props.match.params && props.match.params._id ? props.match.params._id : '';
    const [heading, setHeading] = useState('Create School Sub-Admin');
    const [disabledOtions, setDisabledOtions] = useState(false);
    const [records, setRecords] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [seletedUserRole, setSeletedUserRole] = useState('');
    const [seletedUserId, setSeletedUserId] = useState('');
    const [selectedUser, setSelectedUser] = useState({
        email: '',
        mobile: ''
    });
    useEffect(() => {
        if (_id) {
            getSchoolMember(_id);
        }
    }, [seletedUserId]);

    const [startstyle, setStartstyle] = useState({
        startstyle: {
            color: 'red',
            fontSize: '14px',
        },
    });

    const [formData, setFormData] = useState({
        dashboard: true,
        classes: false,
        students: false,
        staffMembers: false,
        nonTeachingStaff: false,
        studentFees: false,
        busData: false,
        accessLevels: false,
        timeTable: false,
        courseStr: false,
        examSchedule: false,
        settings: false,
        concessions: false,
        subjects: false,
        feeData: false,
        holidays: false,
        manageFloors: false,
        nonTeachingStaffRoles: false
    })

    function renderAction(props) {
        return(
            <span>
                <button
                    type = "button"
                    className = "btn btn-outline-warning"
                    style = {{ padding: "8px" }}>
                    <i
                        className = "mdi mdi-border-color"
                        style = {{ fontSize: "17px" }}>
                    </i>
                </button>
                <button
                    type = "button"
                    className = "btn btn-outline-danger"
                    style = {{ padding: "8px" }}>
                    <i
                        className = "mdi mdi-delete"
                        style = {{ fontSize: "17px" }}>
                    </i>
                </button>
            </span>
        );
    }

    function deactiveAction(props, index) {
        return (
            <span>
                <span
                    className="form-check form-check-danger"
                    style={{ display: "inline-flex" }}
                >
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            />
                        <i className="input-helper"></i>
                    </label>
                </span>
            </span>
        );
    };

    async function getUsers(role) {
        if (role) {
            const StaffApi = new StaffMember();
            const data = await StaffApi.getStaffMembersByRole(role);
            if (data.success) {
                setRecords(data.data);
                const getUserAccess = data.data.find(item => {
                    return item._id === seletedUserId
                })
                if (getUserAccess && getUserAccess.accessToModules) {
                    setFormData(getUserAccess.accessToModules);
                    setSelectedUser({
                        email: getUserAccess.email,
                        mobile: getUserAccess.mobile
                    });
                }
            }
        } else {
            setRecords([]);
        }
    }

    const getSchoolMember = async (_id) => {
        if (_id) {
            const staffApi = new StaffMember();
            const res = await staffApi.getSchoolMember(_id);
            setHeading('Edit School Sub-Admin');
            if (res.data.nonStaff) {
                setSeletedUserRole('nonTeaching');
            } else {
                setSeletedUserRole(res.data.role);
            }
            setSeletedUserId(res.data._id);
            getUsers(res.data.role);
        }
    }

    const handleChanges = (event) => {
        const form = { ...formData };
        const { name, value } = event.target;
        const fValue = (value === 'true' || value === 'false') ? JSON.parse(value) : value;
        switch (name) {
            case 'userName':
                const getUserAccess = records.find(item => {
                    return item._id === value
                })
                if (getUserAccess && getUserAccess.accessToModules) {
                }
            break;
            case 'dashboard':
                form.dashboard = !fValue;
            break;
            case 'classes':
                form.classes = !fValue;
            break;
            case 'students':
                form.students = !fValue;
            break;
            case 'staffMembers':
                form.staffMembers = !fValue;
            break;
            case 'nonTeachingStaff':
                form.nonTeachingStaff = !fValue;
            break;
            case 'studentFees':
                form.studentFees = !fValue;
            break;
            case 'busData':
                form.busData = !fValue;
            break;
            case 'accessLevels':
                form.accessLevels = !fValue;
            break;
            case 'timeTable':
                form.timeTable = !fValue;
            break;
            case 'courseStr':
                form.courseStr = !fValue;
            break;
            case 'examSchedule':
                form.examSchedule = !fValue;
            break;
            case 'settings':
                form.settings = !fValue;
            break;
            case 'concessions':
                form.concessions = !fValue;
            break;
            case 'subjects':
                form.subjects = !fValue;
            break;
            case 'feeData':
                form.feeData = !fValue;
            break;
            case 'holidays':
                form.holidays = !fValue;
            break;
            case 'manageFloors':
                form.manageFloors = !fValue;
            break;
            case 'nonTeachingStaffRoles':
                form.nonTeachingStaffRoles = !fValue;
            break;
        }
        setFormData(form);
    }

    async function addAccessLevel(e) {
        e.preventDefault();
        const staffData = {};
        if (seletedUserId) {
            staffData._id = seletedUserId;
        }
        staffData.accessToModules = formData;
        const staffApi = new StaffMember();
        await staffApi
        .save(staffData)
        .then((data) => {
            if (data.success) {
                toast.success(data.msg.toString());
            } else {
                toast.error(data.msg);
            }
        })
        .catch((error) => {
            toast.error(error.toString());
            console.error('There was an error!', error);
        });
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">{heading}</h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={(e) => addAccessLevel(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">
                                            Select Role<span style={startstyle}>*</span>
                                            </label>
                                            <div className="col-sm-9">
                                                <select className="form-control"
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        setSeletedUserRole(e.target.value);
                                                        getUsers(e.target.value)
                                                    }}
                                                    value={seletedUserRole}>
                                                    <option value="">select role</option>
                                                    <option value="principal">Principal</option>
                                                    <option value="teacher">Teacher</option>
                                                    <option value="nonTeaching">Non-Teaching Staff</option>
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">
                                            Select User<span style={startstyle}>*</span>
                                            </label>
                                            <div className="col-sm-9">
                                                <select
                                                    name="userName"
                                                    className="form-control"
                                                    value={seletedUserId}
                                                    disabled={true}
                                                    onChange={(e)=> {
                                                        const selectUser = records.filter((item) => {
                                                            return item._id.toString() === e.target.value.toString();
                                                        });
                                                        if (selectUser && selectUser.length) {
                                                            setSelectedUser({
                                                                email:selectUser[0].email,
                                                                mobile:selectUser[0].mobile
                                                            });
                                                        };
                                                        handleChanges(e)
                                                    }}>
                                                    <option>select user</option>
                                                    {
                                                        records.map((item, i) => {
                                                            return (
                                                               <option key={i} value={item._id}>{item.firstName} {item.lastName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">
                                            Email <span style={startstyle}>*</span>
                                            </label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text"
                                                    disabled={true}
                                                    value={selectedUser.email}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 hide">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">
                                            Mobile <span style={startstyle}>*</span>
                                            </label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text" disabled={true} value={selectedUser.mobile}/>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <h4 className="card-title">Give Access for :</h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Principal Role
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <h4 className="card-title">Give Access for Modules:</h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="dashboard"
                                                    className="form-check-input"
                                                    value={formData.dashboard}
                                                    checked = {formData.dashboard}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Dashboard
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="classes"
                                                    className="form-check-input"
                                                    value={formData.classes}
                                                    checked = {formData.classes}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Class
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="students"
                                                    className="form-check-input"
                                                    value={formData.students}
                                                    checked = {formData.students}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Students
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="staffMembers"
                                                    className="form-check-input"
                                                    value={formData.staffMembers}
                                                    checked = {formData.staffMembers}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Staff Members
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="nonTeachingStaff"
                                                    className="form-check-input"
                                                    value={formData.nonTeachingStaff}
                                                    checked={formData.nonTeachingStaff}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Non-Teaching Staff
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="studentFees"
                                                    className="form-check-input"
                                                    value = {formData.studentFees}
                                                    checked = {formData.studentFees}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Student Fees
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="busData"
                                                    className="form-check-input"
                                                    value = {formData.busData}
                                                    checked = {formData.busData}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Bus Data
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="accessLevels"
                                                    className="form-check-input"
                                                    value={formData.accessLevels}
                                                    checked = {formData.accessLevels}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Access Levels
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="timeTable"
                                                    className="form-check-input"
                                                    value={formData.timeTable}
                                                    checked = {formData.timeTable}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Time Table
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="courseStr"
                                                    className="form-check-input"
                                                    value={formData.courseStr}
                                                    checked = {formData.courseStr}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                    />{" "}
                                                Course Structure
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="examSchedule"
                                                    className="form-check-input"
                                                    value={formData.examSchedule}
                                                    checked = {formData.examSchedule}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Exam Schedule
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="settings"
                                                    className="form-check-input"
                                                    value={formData.settings}
                                                    checked = {formData.settings}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Settings
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="concessions"
                                                    className="form-check-input"
                                                    value={formData.concessions}
                                                    checked = {formData.concessions}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}

                                                />{" "}
                                                Concessions
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="subjects"
                                                    className="form-check-input"
                                                    value={formData.subjects}
                                                    checked = {formData.subjects}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Subjects
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="feeData"
                                                    className="form-check-input"
                                                    value={formData.feeData}
                                                    checked = {formData.feeData}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Fees Data
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="holidays"
                                                    className="form-check-input"
                                                    value={formData.holidays}
                                                    checked = {formData.holidays}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Holidays
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="manageFloors"
                                                    className="form-check-input"
                                                    value={formData.manageFloors}
                                                    checked = {formData.manageFloors}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Manage Floors
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    name="nonTeachingStaffRoles"
                                                    className="form-check-input"
                                                    value={formData.nonTeachingStaffRoles}
                                                    checked = {formData.nonTeachingStaffRoles}
                                                    onChange={(e) => {
                                                        handleChanges(e)
                                                    }}
                                                />{" "}
                                                Non-Teaching Staff Roles
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <h4 className="card-title">
                                    Give Access for Principal App Modules:
                                </h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Dashboard
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Fees Data
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Kids Attendance
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Teachers Attendance
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Non-teaching Staff Attendance
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Send Communication
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <h4 className="card-title">
                                    Give Access for Teacher App Modules:
                                </h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Mark Kids Attendance
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Upload Video
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Group>
                                            <div className="form-check form-check-success">
                                                <label className="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    />{" "}
                                                Send Messages
                                                <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary ml-2 btn-fw"
                                            style={{ lineHeight: 1.5, float: "right" }}
                                        >
                                        Submit
                                        </button>
                                    </div>
                                </div>
                                {/* =============================================== */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddAccessLevels;
