import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Loader from "../../../components/loader";
import MaterialTable from "material-table";
import { jsPDF } from "jspdf";
const PreviousFeeRecord = ({data,handleClose2,isLoader,rows2,field}) => {
    const exportToPdf = async () => {
        let pdf = new jsPDF('p', 'pt', 'a4');
        let pWidth = pdf.internal.pageSize.width;
        let srcWidth = document.getElementById('exportStudentFeesDiv').scrollWidth;
        let margin = 10;
        let scale = (pWidth - margin * 2) / srcWidth;
    
        pdf.html(document.getElementById('exportStudentFeesDiv'), {
          x: margin,
          y: margin,
          html2canvas: {
            scale: scale,
            allowTaint: true,
            backgroundColor: 'white'
          },
          callback: async function (doc) {
            let pageInfo = doc.internal.getCurrentPageInfo();
            let totalPages = doc.internal.getNumberOfPages();
            if (totalPages > 0 && totalPages > pageInfo.pageNumber) {
              for (let p = totalPages; p > pageInfo.pageNumber; p--) {
                doc.deletePage(p)
              }
            }
            doc.save('studentFees.pdf');
          }
        });
      }
    return (
        <Modal
            show={data.isModalVisible2}
            size="xl"
            onHide={() => handleClose2()}>
            <Modal.Header closeButton>
            <h3 className="page-title">Previous Fee Records</h3>
            </Modal.Header>
            <div className="card">
            {isLoader ? <Loader /> : null}
            <div className="card-body" id="exportStudentFeesDiv">
                <MaterialTable
                title=""
                data={rows2}
                columns={field()}
                options={{ search: false, paging: false, exportButton: false }}
                />
            </div>
            </div>
            <Modal.Footer style={{ backgroundColor: "#ffffff" }}>
            <div className="row">
                <div className="col-sm-12">
                <span>FEE ONCE PAID IS NOT REFUNDABLE</span>
                </div>
            </div>
            <button
                onClick={exportToPdf}
                type="button"
                className="btn btn-outline-warning"
                style={{ padding: "8px" }}
            >
                Print
            </button>
            <Button variant="secondary" onClick={() => handleClose2()}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PreviousFeeRecord;