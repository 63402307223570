import BaseApi from "./baseApi";

class About extends BaseApi {
    async getAboutContent() {
        return await this.API(
            {
                url: "/edvantum/getaboutcontent",
                method: "get",
            }
        )
    }

    async updateAboutContent(data) {
        return await this.API(
            {
                url: "/edvantum/updateaboutcontent",
                data: data,
                method: "post"
            }
        )
    }

}

export default About;