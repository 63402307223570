import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "../../../components/dataTable";
import Loader from "../../../components/loader";
import CoachApi from "../../../api/coachApi";
import { toast } from "react-toastify";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Form } from "react-bootstrap";
import Datetime from "react-datetime";
import AcceptRejectModal from "./component/responseOnApplidLeaves";
const OnLeaves = () => {

  const [dataLoader, setDataLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [coachList, setCoachList] = useState([]);
  const [filterData, setFilterData] = useState({
    coachId: "",
    from: "",
    to: "",
  })

  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  useEffect(() => {
    getList();
    getCoachList();
  }, []);

  const getList = async () => {
    setDataLoader(true);
    let Api = new CoachApi();
    await Api.getCoachleavesDetails(filterData).then(async (response) => {
      if (response.data) {
        console.log("response.data", response.data)
        setRows(response.data);
      }
    })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const getCoachList = async () => {
    setDataLoader(true);
    let Api = new CoachApi();
    await Api.getCoaches().then(async (response) => {
      if (response.data && response.data.length) {
        setCoachList(response.data);
      }
    })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const field = () => {
    const fields = [
      {
        title: "Coach Name",
        render: (row) => <span>{row.userDetails ? row.userDetails.firstName + " " + row.userDetails.lastName : ""}</span>
      },
      {
        title: "Leave Type",
        field: "leaveType"
      },
      {
        title: "Comments",
        field: "comment"
      },
      {
        title: "IN-School Approval Status",
        field: "inSchool.statusOfLeave",
        render: (row) => InOrOutSchoolData(row.inSchool, row)
      },
      {
        title: "OUT-School Approval Status",
        field: "outSchool.statusOfLeave",
        render: (row) => InOrOutSchoolData(row.outSchool, row)
      },
      {
        title: "Leave On",
        render: showToFromDate,
      },
    ];
    return fields;
  };

  const InOrOutSchoolData = (row, fullData) => {
    if (row.outSchool) {
      return printTags(row, fullData);
    } else if (row.inSchool) {
      return printTags(row, fullData);
    } else {
      return <span
        className="badge badge-secondary"
      >Not Applied</span>
    }
  }

  const printTags = (row, fullData) => {
    if (row.statusOfLeave == "Pending") {
      return <span
        onClick={() => {
          setIsShowModal(!isShowModal)
          setModalData(fullData)
        }}
        className="badge badge-warning"
        style={{ cursor: "pointer" }}
      >{row.statusOfLeave}</span>
    } else if (row.statusOfLeave == "Approved") {
      return <span
        className="badge badge-success "
      >{row.statusOfLeave}</span>
    } else {
      return (
        <span>
          <ReactTooltip id={'rejected' + fullData._id} type='error' effect='solid'>
            <span>{row.leaveRejectComment}</span>
          </ReactTooltip>
          <span
            ata-tip data-for={'rejected' + fullData._id}
            className="badge badge-danger"
          >
            {row.statusOfLeave}
          </span>
        </span>
      )
    }
  }

  const showToFromDate = (row) => {
    let today = moment().format('DD-MM-YYYY');
    let from = moment(row.leave.from).format('DD-MM-YYYY');
    let to = moment(row.leave.to).format('DD-MM-YYYY');
    if (from == to && from == today) {
      return <span className="badge badge-warning">Today on leave</span>
    } else {
      return <span className="badge badge-warning">{from} to {to}</span>
    }
  }

  const handleSearchChanges = (e) => {
    let Data = { ...filterData };
    let { name, value } = e.target;
    Data[name] = value;
    setFilterData(Data);
  }

  const reload = () => getList();


  return (
    <>
      <div className="page-header">
        <h3 className="page-title"> Coaches On Leave </h3>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample"
              >
                <h4 className="card-title">Search Coaches Leaves</h4>
                <div className="row">

                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Date From:</label>
                      <div className="col-sm-9">
                        <Datetime
                          placeholder="from:"
                          dateFormat={true}
                          timeFormat={false}
                          onChange={(e) => {
                            handleSearchChanges({ target: { name: "from", value: moment(e).format("YYYY-MM-DD") } })
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Date to:
                      </label>
                      <div className="col-sm-9">
                        <Datetime
                          placeholder="to:"
                          dateFormat={true}
                          timeFormat={false}
                          onChange={(e) => {
                            handleSearchChanges({ target: { name: "to", value: moment(e).format("YYYY-MM-DD") } })
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Coach</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="coachId"
                          onChange={(e) => handleSearchChanges(e)}
                        >
                          <option value="">------------</option>
                          {coachList.map((e) => {
                            return (
                              <option key={e._id} value={e._id}>
                                {e.firstName} {e.lastName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ float: "right" }}
                      onClick={() => getList()}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <Grid
              columns={field()}
              rows={rows}
              settings={{
                search: true,
                sorting: true
              }} />
          </div>
        </div>
      </div>

      {/* ========= modal section ========== */}
      <AcceptRejectModal
        show={isShowModal}
        data={modalData}
        clean={() => {
          setIsShowModal(false)
          setModalData({})
        }}
        reload={reload}
      />
      {/* ======== /modal section/ ========= */}

    </>
  );
};

export default OnLeaves;