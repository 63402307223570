import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
	return (
		<Navbar expand="lg" className="bg-body-tertiary navbar-default ">
			<Navbar.Brand href="/home" className="navbar-brand" style={{ minHeight: "60px" }}>
				<img
					src={require("../../assets/images/web/Edvantumlogo.png")}
					alt="EDVANTUM - Engage, Educate, Evolve"
					title="EDVANTUM - Engage, Educate, Evolve"
					className="logo-img"
					rel="preload"
				/>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="myNavbar" />
			<Navbar.Collapse id="myNavbar">
				<ul className="nav navbar-nav navbar-right" style={{ paddingTop: 15 }}>
					<li>
						<Link to="parents" title="Parents - Edvantum.com">
							Parents
						</Link>
					</li>
					<li>
						<Link to="schools" title="Schools">
							Schools
						</Link>
					</li>
					<li>
						<Link to="teachers" title="Teachers">
							Teachers
						</Link>
					</li>
					<li>
						<Link to="about-us" title="About Us">
							About Us
						</Link>
					</li>
					<li>
						<Link to="activities" title="Activities">
							Activities
						</Link>
					</li>
					<li>
						<Link to="pricing" title="Pricing">
							Pricing
						</Link>
					</li>
					<li>
						<Link to="events" title="Events">
							Events
						</Link>
					</li>
					<li>
						{/* <Link to="events" title="Events">
								Events
							</Link> */}
						<a href="home#blogs" title="Blogs">
							Blogs
						</a>
					</li>
					<li>
						<a href="home#contact1" title="Contact Us">
							Contact Us
						</a>
					</li>
					<li className="btn-trial">
						<Link to="login" title="Login">
							Login
						</Link>
					</li>
				</ul>
			</Navbar.Collapse>
		</Navbar>

		// <Navbar expand="lg" fixed="top"  className="navbar navbar-default navbar-fixed-top">
		// 	<div className="container">
		// 		<div className="navbar-header">
		// 			<Navbar.Brand href="/home"  className="navbar-brand" style={{minHeight:"60px"}}>
		// 				<img
		// 					src={require("../../assets/images/web/Edvantumlogo.png")}
		// 					alt="EDVANTUM - Engage, Educate, Evolve"
		// 					title="EDVANTUM - Engage, Educate, Evolve"
		// 					className="logo-img"
		// 					rel="preload"
		// 				/>
		// 			</Navbar.Brand>
		// 		</div>
		// 		<Navbar.Toggle aria-controls="myNavbar" />
		// 		<Navbar.Collapse  id="myNavbar">
		// 			<ul className="nav navbar-nav navbar-right" style={{ paddingTop: 15 }}>
		// 				<li>
		// 					<Link to="parents" title="Parents - Edvantum.com">
		// 						Parents
		// 					</Link>
		// 				</li>
		// 				<li>
		// 					<Link to="schools" title="Schools">
		// 						Schools
		// 					</Link>
		// 				</li>
		// 				<li>
		// 					<Link to="teachers" title="Teachers">
		// 						Teachers
		// 					</Link>
		// 				</li>
		// 				<li>
		// 					<Link to="about-us" title="About Us">
		// 						About Us
		// 					</Link>
		// 				</li>
		// 				<li>
		// 					<Link to="activities" title="Activities">
		// 						Activities
		// 					</Link>
		// 				</li>
		// 				<li>
		// 					<Link to="events" title="Events">
		// 						Events
		// 					</Link>
		// 				</li>
		// 				<li>
		// 					{/* <Link to="events" title="Events">
		// 						Events
		// 					</Link> */}
		// 				<a href="home#blogs" title="Blogs">
		// 					Blogs
		// 				</a>
		// 				</li>
		// 				<li>
		// 				<a href="home#contact" title="Contact Us">
		// 					Contact Us
		// 				</a>
		// 				</li>
		// 				<li className="btn-trial">
		// 					<Link to="login" title="Login">
		// 						Login
		// 					</Link>
		// 				</li>
		// 			</ul>
		// 		</Navbar.Collapse>
		// 	</div>
		// </Navbar>

	);
};

export default Header;
