import React, { useLayoutEffect } from "react";
const Pricing = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      <section id="pricing" className="section-padding ">
        <div className="container">
          <div className="">
            <div className="header-section text-center margin-btm">
              <h2 className="title__line">Pricing</h2>
              <p className="tagline">For Pricing please contact us and we will get back to you soon.</p>
              <a href="/home#contact1" className="btn btn-dark">Contact Us</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Pricing;