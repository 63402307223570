import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { UserContext } from "../contexts/userContext";
import { Link, useHistory} from 'react-router-dom';
import AuthApi from "../api/authApi";
import { toast } from "react-toastify";
import {Helmet} from 'react-helmet-async';

const ForgotPassword = () => {
    let history = useHistory();
  const [userContext] = useContext(UserContext);
  const [user, setUser] = useState({ email: "", password: ""});
  
    function handleChange(event) {
        const { name, value } = event.target;
        //here set the state and pass
        let c = { ...user };
        if (name == "email") {
        c.email = value;
        }
        setUser(c);
    }

  async function handleSubmit(event) {
    event.preventDefault();
    if(user.email){
        let authApi = new AuthApi();
        await authApi.forgotPassword(user.email)
        .then((response)=>{
            if(response.success){
                toast.success(response.msg);
                history.push(`/resetpassword?email=${user.email}`);
            } else {
                toast.error(response.msg);
            }
        })
        .catch((error) => {
            toast.error(error)
        })
    }
  }

  let dis = "Forgot password to login Edvantum Admin and School Admin";
  let keyword = "Edvantum, Forgot Password, Login School Admin";
  let tt = "EDVANTUM - Forgot Password";
  return (
    <Fragment>
        <Helmet>
            <title>{tt}</title>
            <meta
                name="description"
                content={dis}
            />
            <meta
                name="Keywords"
                content={keyword}
            />
        </Helmet>
        <div className="d-flex align-items-center auth px-0" style={{height: "45pc"}}>
            <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo mb-3">
                 <Link to="/home">
                    <img
                    src={require("../assets/images/Edvantum.png")}
                    alt="logo"
                    style={{ width: "200px" }}
                    loading="lazy"
                    />
                    </Link>
                </div>
                <h4>Enter Email for get OTP.</h4>
                <Form className="pt-3" onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group className="d-flex search-field">
                    <Form.Control
                        type="text"
                        onChange={(e) => handleChange(e)}
                        placeholder="email"
                        size="lg"
                        name="email"
                        className="h-auto"
                        required={true}
                    />
                    </Form.Group>
                    <div className="mt-3">
                    {/* <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard">SIGN IN</Link> */}
                    </div>
                    <div className="mt-3">
                    <Button
                        type="submit"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    >
                        {" "}
                        Send OTP{" "}
                    </Button>
                    </div>
                </Form>
                </div>
            </div>
            </div>
        </div>

    </Fragment>
  );
};

export default ForgotPassword;
