import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
const ExportSubjectCSV = ({ subjectData = null }) => {
  function downloadSubjectCSV() {
    let headers = [{ label: "Subject Name", key: "subjectName" }];
    return (
      <CSVLink
        data={subjectData}
        filename={"School_Subject_Export.csv"}
        headers={headers}
      >
        <i className="mdi mdi-download"></i> Export Subjects Data
      </CSVLink>
    );
  }

  return (
    <div>{downloadSubjectCSV()}</div>
  );
};

export default ExportSubjectCSV;
