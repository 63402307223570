import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

function StudentResult({ data }) {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const margin = { top: 20, right: 30, bottom: 40, left: 40 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Extract the list of subjects from the first data point
    if (data && data.length) {
      const subjects = Object.keys(data[0].results);

      // Define a color scale that maps percentages to colors
      const colorScale = d3.scaleThreshold()
        .domain([20, 40, 60, 80, 100])
        .range(['gray', 'red', 'orange', 'yellow', 'green']);

      // Define scales
      const xScale = d3
        .scaleBand()
        .domain(data.map((d) => d.term))
        .range([0, width])
        .padding(0.1);

      const yScale = d3
        .scaleLinear()
        .domain([0, 100])
        .nice()
        .range([height, 0]);

      // Create SVG container
      svg.selectAll('*').remove();
      const chart = svg
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      // Create bars and labels for each subject
      subjects.forEach((subject, index) => {
        chart
          .selectAll(`.bar-${subject}`)
          .data(data)
          .enter()
          .append('rect')
          .attr('class', `bar bar-${subject}`)
          .attr('x', (d) => xScale(d.term) + xScale.bandwidth() * index / subjects.length)
          .attr('y', (d) => yScale(parseInt(d.results[subject])))
          .attr('width', xScale.bandwidth() / subjects.length)
          .attr('height', (d) => height - yScale(parseInt(d.results[subject])))
          .attr('fill', (d) => colorScale(parseInt(d.results[subject])));

        chart
          .selectAll(`.label-${subject}`)
          .data(data)
          .enter()
          .append('text')
          .attr('class', `label label-${subject}`)
          .attr('x', (d) => xScale(d.term) + xScale.bandwidth() * (index / subjects.length) + xScale.bandwidth() / (2 * subjects.length))
          .attr('y', (d) => yScale(parseInt(d.results[subject])) - 5)
          .attr('text-anchor', 'middle')
          .text((d) => `${subject}: ${d.results[subject]}`);
      });

      // X-axis
      chart
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));

      // Y-axis
      chart
        .append('g')
        .attr('class', 'y-axis')
        .call(d3.axisLeft(yScale)
          .tickFormat(d => `${d}%`));

      // Add axis labels
      svg
        .append('text')
        .attr('class', 'x-label')
        .attr('x', width / 2 + margin.left)
        .attr('y', height + margin.top + 30)
        .attr('text-anchor', 'middle')

      svg
        .append('text')
        .attr('class', 'y-label')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2 - margin.top)
        .attr('y', margin.left - 30)
        .attr('text-anchor', 'middle')

    }
  }, [data])

  return (
    <div className="bar-chart">
      <svg ref={svgRef} width={600} height={400}></svg>
    </div>
  );
}

export default StudentResult;