import React, { Component } from 'react'

const Loader = () => {
  return (
    <div className="spinner-wrapper" style={{zIndex:999}}>
      <div className="donut"></div>
    </div>
  )
}

export default Loader;
