import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SchoolApi from "../../../api/schoolApi";
import ClassApi from "../../../api/classApi";
import ResultApi from "../../../api/resultApi";
import { toast } from "react-toastify";
import AllStudentsResult from "../../../components/AllStudentsResult";

const Dashboard = () => {
  const [counts, setCounts] = useState({
    classesCount: 0,
    studentsCount: 0,
    teachersCount: 0,
  });
  const [studentResults, setStudentResults] = useState([]);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState();
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState();

  useEffect(() => {
    getCounts();
    getSchool(localStorage.getItem("schoolId"));
  }, []);

  useEffect(() => {
    getClasses();
  }, [selectedTerm]);

  useEffect(() => {
    getResultDetails();
  }, [selectedTerm, selectedClassId]);

  async function getCounts() {
    const schoolApi = new SchoolApi();
    await schoolApi
      .getSchoolCounts()
      .then((data) => {
        setCounts(data.data);
      })
      .catch((error) => {
        console.error("There is ERROR!", error);
        toast.error(error.toString());
      });
  }

  async function getSchool(id) {
    let schoolApi = new SchoolApi();
    await schoolApi
      .getSchool(id)
      .then(async (response) => {
        if (response.success) {
          if (response.data && response.data.session) {
            setTerms(response.data.termsExams);
            // setSession(response.data.session);
          }
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  function onChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case 'term':
        setSelectedTerm(value)
        break;
      case 'class':
        let cls = classes.length && classes.find((item) => item._id.toString() == value.toString());
        if (cls) {
          setSelectedClassId(cls._id)
        } else {
          setSelectedClassId("")
        }
        break;
    }
  }

  const getClasses = async () => {
    const classApi = new ClassApi();
    await classApi.getClassesByTerm(selectedTerm, "ArrayOfObjects").then(async (response) => {
      if (response.data && response.data.length) {
        setClasses(response.data);
      } else {
        setClasses([]);
      }
    })
  }

  const getResultDetails = async () => {
    const resultApi = new ResultApi();
    if (selectedTerm && selectedClassId) {
      await resultApi.getResultDetails({ selectedTerm, selectedClassId }).then(async (response) => {
        let formattedData = []
        formattedData = response.data && response.data.map(data => {
          const fullName = `${data.firstName} ${data.lastName}`;
          const obtainedTotalMarks = data.marks.reduce((total, mark) => {
            return total + mark.marksObtained + mark.practicalMarks + mark.assignmentMarks;
          }, 0);
          const totalPossibleMarks = data.totalMarks.reduce((total, subject) => {
            return total + subject.marks + subject.practical + subject.assignment;
          }, 0);
          const totalPercentage = (obtainedTotalMarks / totalPossibleMarks) * 100;
          return {
            student: fullName,
            totalPercentage: totalPercentage.toFixed(2)
          };
        });
        setStudentResults(formattedData);
      })
    } else {
      setStudentResults([]);
    }
  }

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <div>
      <div>
        <div>
          <div className="d-sm-flex justify-content-between align-items-start">
            <h2 className="text-dark font-weight-bold mb-2"> Dashboard </h2>
            <div className="d-sm-flex justify-content-xl-between align-items-center mb-2">
              <div className="dropdown ml-0 ml-md-4 mt-2 mt-lg-0">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="bg-white dropdown-toggle border-0 p-3 mr-0 text-muted d-flex align-items-center">
                    <i className="mdi mdi-calendar mr-1"></i>24 Mar 2019 - 24 Mar
                    2019
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>24 Mar 2019 - 24 Mar 2019</Dropdown.Item>
                    <Dropdown.Item>24 April 2019 - 24 May 2019</Dropdown.Item>
                    <Dropdown.Item>24 May 2019 - 24 Jun 2019</Dropdown.Item>
                    <Dropdown.Divider></Dropdown.Divider>
                    <Dropdown.Item>24 Jun 2019 - 24 July 2019</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-12">
            <div className="justify-content-between align-items-center tab-transparent">
              <div>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <Link to="/classes">
                          <h4 className="mb-4 text-dark font-weight-bold">
                            Classes
                          </h4>
                          <h5 className="mb-4 text-dark font-weight-normal">{counts.classesCount}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <Link to="/staffmembers">
                          <h4 className="mb-4 text-dark font-weight-bold">
                            Teachers
                          </h4>
                          <h5 className="mb-4 text-dark font-weight-normal">
                            Total - {counts.teachersCount}
                          </h5>
                          <h5 className="mb-4 text-dark font-weight-normal">
                            Active - {counts.activeTeachersCount}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <Link to="/students">
                          <h4 className="mb-4 text-dark font-weight-bold">
                            Students
                          </h4>
                          <h5 className="mb-4 text-dark font-weight-normal">
                            Total - {kFormatter(parseInt(counts.studentsCount))}
                          </h5>
                          <h5 className="mb-4 text-dark font-weight-normal">
                            Active - {kFormatter(parseInt(counts.activeStudentsCount)).toString()}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-sm-flex justify-content-between align-items-start">
        <h4 className="mb-2"> Students Result </h4>
      </div>

      <div className="row mt-2">
        <div className="col-md-12">
          <div className="justify-content-between align-items-center tab-transparent">
            <div>

              <div className="row">
                <div className="col-xl-12 col-lg-12 col-sm-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body text-center">
                      <form className="forms-sample">
                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group className="row">
                              <label className="col-sm-4 col-form-label">Term<span style={{ color: "red" }}>*</span></label>
                              <div className="col-sm-8">
                                <select
                                  className="form-control"
                                  id="term"
                                  name="term"
                                  onChange={(e) => onChange(e)}
                                >
                                  <option>-------</option>
                                  {terms.map((resp) => <option key={"term_" + resp._id} value={resp._id}>{resp.term}</option>)}
                                </select>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group className="row">
                              <label className="col-sm-4 col-form-label">Class<span style={{ color: "red" }}>*</span></label>
                              <div className="col-sm-8">
                                <select
                                  className="form-control"
                                  id="class"
                                  name="class"
                                  onChange={(e) => onChange(e)}
                                >
                                  <option>-------</option>
                                  {classes.map((resp) => <option key={"className_" + resp._id} value={resp._id}>
                                    {resp.className}{resp && resp.section ? "-" : ""}{resp.section}</option>)}
                                </select>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {studentResults && studentResults.length !== 0 ?
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-sm-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                          <AllStudentsResult data={studentResults} />
                          </div>
                          <div className="col-md-12">
                            <div className="col-md-2">
                              <div className="grayColorBox"></div>
                              <span> 0% {"<"} 20%</span>
                            </div>
                            <div className="col-md-2">
                              <div className="redColorBox"></div>
                              <span> 20% {"<"} 40%</span>
                            </div>
                            <div className="col-md-2">
                              <div className="orangeColorBox"></div>
                              <span> 40% {"<"} 60%</span>
                            </div>
                            <div className="col-md-2">
                              <div className="yellowColorBox"></div>
                              <span> 60% {"<"} 80%</span>
                            </div>
                            <div className="col-md-2">
                              <div className="greenColorBox"></div>
                              <span> 80% to 100%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
