import BaseApi from './baseApi';
class ExamScheduleApi extends BaseApi {
    async save(data) {
        if (data && data._id) {
            return await this.API(
                {
                    url: '/examschedule/updateexamschedule',
                    data: data,
                    method: 'put'
                }
            )
        } else {
            return await this.API(
                {
                    url: '/examschedule/createexamschedule',
                    data: data,
                    method: 'post',
                }
            );
        }
    }

    async create(data) {

    }

    async edit(data) {

    }

    async getExamSchedulesList() {
        return await this.API(
            {
                url: '/examschedule/getexamscheduleslist',
                method: 'get',
            }
        );
    }

    async getExamDatesheet(term, session) {
        return await this.API(
            {
                url: '/examschedule/getexamdatesheet',
                method: 'get',
                params: { term: term, session: session }
            }
        );
    }

    async generateExamDatesheetPDF(selectedTerm, term, session) {
        return await this.API(
            {
                url: '/examschedule/generateexamdatesheetpdf',
                method: 'get',
                params: { selectedTerm: selectedTerm, term: term, session: session }
            }
        );
    }

    async getDisplayExamsList(examScheduleForm) {
        return await this.API(
            {
                url: '/examschedule/getdisplayexamschedulelist',
                method: 'get',
                params: { term: examScheduleForm.term, className: examScheduleForm.className }
            }
        );
    }

    async getExamSchedule(_id) {
        return await this.API(
            {
                url: '/examschedule/getexamschedule/' + _id,
                method: 'get',
                params: { _id: _id }
            }
        );
    }

    async deleteExamSchedule(data) {
        return await this.API(
            {
                url: '/examschedule/deleteexamschedule',
                data: { _id: data },
                method: 'delete'
            }
        )
    }
}
export default ExamScheduleApi;
