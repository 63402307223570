import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Form, Modal, Button, Image } from "react-bootstrap";
import StaffMember from "../../../api/staffMember";
import { toast } from "react-toastify";
import Subject from "../../../api/subjectsApi";
import Floor from "../../../api/floorsApi";
import Class from "../../../api/classApi";
import { Link, useHistory } from "react-router-dom";
import Grid from "../../../components/dataTable";
import ReactTooltip from "react-tooltip";
import Datetime from "react-datetime";
import moment from "moment";
import { capitalize } from '../../../helpers/utils';
const AddStaff = () => {
  const history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    isModalVisible: false,
    selectedClass: "",
  });

  const [fromData, setFormData] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    dob: "",
    address: {
      address: "",
      city: "",
      pinCode: "",
      state: "punjab",
      country: "india",
    },
    photoUrl: "",
    userRoles: {
      // userRole: "",
      userRole: [],
    },
    classTeacherUserId: "",
    floorId: "",
    markAttendance: false,
    accessToModules: {
      class: false,
      students: false,
      staffMembers: false,
      nonTeachingStaff: false,
      studentFees: false,
      busData: false,
      timeTable: false,
      courseStr: false,
      examSchedule: false,
      concessions: false,
      subjects: false,
      feesData: false,
      holidays: false,
      manageFloors: false,
      nonTeachingStaffRoles: false,
      subjectHead: false,
      classTeacher: false,
      floorIncharge: false,
      feeCollector: false,
      adAdmin: false,
    },
    classes: [],
    subjectHead_id: "",
    introduction: "",
  });

  const [accessToModules, setAccessToModules] = useState({
    class: false,
    students: false,
    staffMembers: false,
    nonTeachingStaff: false,
    studentFees: false,
    busData: false,
    timeTable: false,
    courseStr: false,
    examSchedule: false,
    concessions: false,
    subjects: false,
    feesData: false,
    holidays: false,
    manageFloors: false,
    nonTeachingStaffRoles: false,
    subjectHead: false,
    classTeacher: false,
    floorIncharge: false,
    feeCollector: false,
    adAdmin: false,
  });

  let rolesArray = ['principal', 'teacher', 'parent', 'coach']


  let [checkedState, setCheckedState] = useState(
    new Array(rolesArray.length).fill(false)
  );

  const [fileProgress, setFileProgress] = useState({});
  const [dataLoader, setDataLoader] = useState(false);
  const [subject, setSubject] = useState([]);
  const [subject2, setSubject2] = useState([]);
  const [floors, setFloors] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedClass, setSelectedClass] = useState({ id: "", name: "" });
  // ================================== code type ===============================

  useEffect(() => {
    getsubject();
    getFloors();
    getClasses();
    const id = new URLSearchParams(history.location.search).get("id");
    if (id) {
      selectedEmployee(id);
    }
  }, []);

  useEffect(() => {
    if (fromData.mobile.length == 10) {
      fetchStaffData(fromData.mobile);
    }
  }, [fromData.mobile]);

  const fetchStaffData = async (mobile) => {
    let StaffApi = new StaffMember();
    let response = await StaffApi.getStaffMemberByMobile({ mobile });
    if (response && response.success && response.data) {
      let d = { ...fromData }
      // d._id = response.data._id
      d.firstName = response.data.firstName ? response.data.firstName : ""
      d.lastName = response.data.lastName ? response.data.lastName : ""
      d.mobile = response.data.mobile ? response.data.mobile : ""
      d.email = response.data.email ? response.data.email : ""
      d.address.city = response.data.address && response.data.address.city ? response.data.address.city : ""
      d.address.pinCode = response.data.address && response.data.address.pinCode ? response.data.address.pinCode : ""
      d.address.address = response.data.address && response.data.address.address ? response.data.address.address : ""
      d.photoUrl = response.data.photoUrl ? response.data.photoUrl : ""
      d.dob = response.data.dob ? response.data.dob : ""
      d.dob = response.data.dob ? response.data.dob : ""

      for (let i = 0; i < rolesArray.length; i++) {
        let pos = response.data.userRoles.userRole.indexOf(rolesArray[i])
        if (pos != -1) {
          checkedState[i] = true
        }
      }
      setCheckedState(checkedState)
      setFormData(d);
    } else {
      let d = { ...fromData }
      d._id = ""
      d.firstName = ""
      d.lastName = ""
      d.email = ""
      d.address.city = ""
      d.address.pinCode = ""
      d.address.address = ""
      d.photoUrl = ""
      d.dob = ""
      d.userRoles.userRole = []
      setFormData(d);
    }
  };

  const getClasses = async () => {
    setDataLoader(true);
    let ClassApi = new Class();
    await ClassApi.getClass()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setClasses(response.data);
        } else {
          setClasses([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    setDataLoader(false);
  };

  const getFloors = async (id) => {
    setDataLoader(true);
    let FloorApi = new Floor();
    await FloorApi.getFloor()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setFloors(response.data);
        } else {
          setFloors([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    setDataLoader(false);
  };

  const selectedEmployee = async (id) => {
    let d = { ...fromData };
    let StaffApi = new StaffMember();
    let data = await StaffApi.getStaffMember(id);
    if (data && data.success) {
      data.data.accessToModules.subjectHead = data.data.subjectHead_id ? true : false;
      data.data.accessToModules.classTeacher = data.data.classTeacherUserId ? true : false;
      data.data.accessToModules.floorIncharge = data.data.floorId ? true : false;
      setFormData(data.data);
      for (let i = 0; i < rolesArray.length; i++) {
        let pos = data.data.userRoles.userRole.indexOf(rolesArray[i])
        if (pos != -1) {
          checkedState[i] = true
        }
      }
      setCheckedState(checkedState)
    } else {
      toast.error(data.msg);
    }
  };

  const uploadImage = async (value) => {
    let Staff = new StaffMember();
    const data = new FormData();
    data.append("file", value[0]);
    await Staff.uploadFile(data, (upload) => {
      let pg = { ...fileProgress };
      pg["file"] = Math.round((100 * upload.loader) / upload.total);
      setFileProgress(pg);
    })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let form = { ...fromData };
          form.photoUrl = data.fileUrl;
          setFormData(form);
          toast.success("Photo is uploaded");
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  };

  const handleChanges = (e) => {
    let FormData = { ...fromData },
      checking = false;
    let { name, value } = e.target;
    switch (name) {
      case "firstName":
        FormData.firstName = value;
        break;
      case "lastName":
        FormData.lastName = value;
        break;
      case "email":
        FormData.email = value;
        break;
      case "address":
        FormData.address.address = value;
        break;
      case "city":
        FormData.address.city = value;
        break;
      case "pincode":
        FormData.address.pinCode = value;
        break;
      case "state":
        FormData.address.state = value;
        break;
      case "country":
        FormData.address.country = value;
        break;
      case "photo":
        FormData.photoUrl = value;
        break;
      case "subjectHead_id":
        FormData.subjectHead_id = value;
        break;
      case "introduction":
        FormData.introduction = value;
        break;
      case "mobileNo":
        FormData.mobile = value;
        break;
      case "userRoles":
        break;
      case "checkedRolesArray":
        for (let i = 0; i < checkedState.length; i++) {
          if (i == value) {
          } else {
            if (i != 2 && i != 3) {
              checkedState[i] = false
            }
          }
        }
        checkedState[value] = !checkedState[value]
        setCheckedState(checkedState)
        if (checkedState[1]) {
          FormData.markAttendance = true
        } else {
          FormData.markAttendance = false
        }
        if (checkedState[0] || checkedState[1]) {
          Object.keys(FormData.accessToModules).forEach(
            (v) => (FormData.accessToModules[v] = true)
          );
        } else {
          FormData.accessToModules = {
            class: false,
            students: true,
            staffMembers: false,
            nonTeachingStaff: false,
            studentFees: false,
            busData: false,
            timeTable: true,
            courseStr: true,
            examSchedule: true,
            concessions: false,
            subjects: false,
            feesData: false,
            holidays: false,
            manageFloors: false,
            nonTeachingStaffRoles: false,
            chequeStatus: false,
          };
        }
        break;
      case "classTeacherUserId":
        FormData.classTeacherUserId = value;
        break;
      case "floor_id":
        FormData.floorId = value;
        break;
      case "class":
        if (value.split(" ").length == 3) {
          setSelectedClass({
            id: value.split(" ")[0],
            name: value.split(" ")[1] + " " + value.split(" ")[2],
          });
        } else {
          setSelectedClass({
            id: value.split(" ")[0],
            name: value.split(" ")[1],
          });
        }
        classes.map((e) => {
          if (e._id == value.split(" ")[0]) {
            let sub = [];
            if (e.subject_docs) {
              e.subject_docs.map((data) => {
                sub.push({ label: data.subjectName, value: data._id });
              });
            }
            setSubject(sub);
          }
        });
        break;
      case "markAttendance":
        FormData.markAttendance = !FormData.markAttendance;
        break;
      case "subjectHead":
        FormData.accessToModules.subjectHead =
          !FormData.accessToModules.subjectHead;
        break;
      case "classTeacher":
        FormData.accessToModules.classTeacher =
          !FormData.accessToModules.classTeacher;
        break;
      case "floorIncharge":
        FormData.accessToModules.floorIncharge =
          !FormData.accessToModules.floorIncharge;
        break;
      case "feeCollector":
        FormData.accessToModules.feeCollector =
          !FormData.accessToModules.feeCollector;
        break;
      case "timeTable":
        FormData.accessToModules.timeTable =
          !FormData.accessToModules.timeTable;
        break;
      case "asAdmin":
        FormData.accessToModules.asAdmin = !FormData.accessToModules.asAdmin;
        break;
    }
    setFormData(FormData);
  };

  const submitChanges = async (e) => {
    e.preventDefault();
    if (fromData._id) {
      fromData.userRoles.userRole = []
    }

    if (rolesArray.length == 0) {
      toast.error("Please Click on Principal Or Teacher Checkbox")
    }

    for (let i = 0; i < rolesArray.length; i++) {
      if (checkedState[i]) {
        fromData.userRoles.userRole.push(rolesArray[i])
      } else if (rolesArray[i] != "parent" || rolesArray[i] != "coach") {
        let pos = fromData.userRoles.userRole.indexOf(rolesArray[i])
        if (pos != -1) {
          fromData.userRoles.userRole.splice(pos, 1)
        }
      }
    }
    if (fromData.accessToModules.subjectHead && fromData.accessToModules.classTeacher == false) {
      toast.error("Please Click on Class Teacher Checkbox")
      return;
    }
    let StaffApi = new StaffMember();
    let suc;
    if (fromData._id) {
      suc = await StaffApi.editStaffMember({
        fromData,
      });
      toast.success(suc.msg);
      history.push("/staffmembers");
    } else if (fromData.classes) {
      suc = await StaffApi.createStaffMember({
        fromData,
      });
      if (suc.error) {
        toast.error(suc.msg);
      } else {
        toast.success(suc.msg);
        cleanFields();
        history.push("/staffmembers");
      }
    } else {
      toast.error("class and subject fields cannot be empty");
    }
  };

  const cleanFields = () => {
    let resetdata = {
      _id: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      address: {
        address: "",
        city: "",
        pinCode: "",
        state: "punjab",
        country: "india",
      },
      photoUrl: "",
      userRoles: {
        userRole: "",
      },
      accessToModules: {
        class: false,
        students: false,
        staffMembers: false,
        nonTeachingStaff: false,
        studentFees: false,
        busData: false,
        timeTable: false,
        courseStr: false,
        examSchedule: false,
        concessions: false,
        subjects: false,
        feesData: false,
        holidays: false,
        manageFloors: false,
        nonTeachingStaffRoles: false,
        subjectHead: false,
        classTeacher: false,
        floorIncharge: false,
        feeCollector: false,
        chequeStatus: false,
        adAdmin: false,
      },
      classTeacherUserId: "",
      floorId: "",
      markAttendance: false,
      accessToModules: {},
      classes: [],
      subjectHead_id: "",
      classteacher: "",
      introduction: "",
    };
    setFormData(resetdata);
  };

  const getsubject = async () => {
    let SubjectApi = new Subject();
    await SubjectApi.getSubject()
      .then(async (response) => {
        if (response.data && response.data.length) {
          let sub = [];
          if (response.data) {
            response.data.map((data) => {
              sub.push({ label: data.subjectName, value: data._id });
            });
          }
          setSubject2(sub);
        } else {
          setSubject2([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const field = () => {
    const fields = [
      {
        title: "Class",
        render: showClasses,
      },
      {
        title: "Subjects",
        render: showSubjects,
      },
      {
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  };

  const addClassandSubject = () => {
    let data = { ...fromData };
    if (selectedClass.id && selectedClass.name && selectedSubject.length) {
      const check = data.classes.findIndex(item =>
        selectedClass.id == item.id
      )
      if (check == -1) {
        data.classes.push({
          id: selectedClass.id,
          name: selectedClass.name,
          subject: selectedSubject,
        });
      } else {
        toast.error("Class already allocated.");
      }
    } else {
      toast.error("fields cannot be empty");
    }
    setFormData(data);
    setSelectedSubject([]);
    setSelectedClass({ id: "", name: "" });
  };

  // ================================== code type ===============================

  // =================================== rander code ============================

  const showClasses = (props) => {
    if (props.name) {
      return <span>{props.name}</span>;
    }
  };
  const showSubjects = (props) => {
    let d = "";
    if (props.subject) {
      props.subject.map((data) => {
        d += data.label + ", ";
      });

      return <span>{d.replace(/,\s*$/, "")}</span>;
    }
  };
  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>

        <button
          data-tip
          onClick={() => deleteClassAndSubject(props.tableData.id)}
          data-for="Delete"
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const deleteClassAndSubject = (index) => {
    let formdata = { ...fromData };
    let data = formdata.classes;
    data.splice(index, 1);
    setFormData(formdata);
  };

  // =================================== rander code ============================

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">{fromData._id ? "Update" : "Add"} Staff </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {fromData._id ? "Update" : "Add"} Staff Member
              </h4>
              <form onSubmit={(e) => submitChanges(e)} className="forms-sample">
                {/* ================================================ */}
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        First Name <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData.firstName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Last Name
                        {/* <span style={data.startstyle}>*</span> */}
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={(e) => handleChanges(e)}
                          value={fromData.lastName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Mobile No. <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="number"
                          placeholder="Mobile No."
                          name="mobileNo"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData.mobile}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Email
                        {/* <span style={data.startstyle}>*</span> */}
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          onChange={(e) => handleChanges(e)}
                          value={fromData.email}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Country <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="country"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData && fromData.address && fromData.address.country && capitalize(fromData.address.country) || "india"}
                        >
                          <option value="">----------</option>
                          <option>India</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        State <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="state"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData && fromData.address && fromData.address.state && capitalize(fromData.address.state) || "Punjab"}
                        >
                          <option value="">--------</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        City <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputCity1"
                          placeholder="Location"
                          name="city"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData && fromData.address && fromData.address.city &&  fromData.address.city}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Pin code
                        {/* <span style={data.startstyle}>*</span> */}
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputName12"
                          placeholder="Pin code"
                          name="pincode"
                          //required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData && fromData.address && fromData.address.pinCode &&  fromData.address.pinCode}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Address</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          placeholder="address"
                          name="address"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={fromData && fromData.address && fromData.address.address &&  fromData.address.address}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row m-0">
                      <label className="col-sm-3 col-form-label"> Photo </label>
                      <div className="custom-file col-sm-9">
                        <Form.Control
                          type="file"
                          className="form-control visibility-hidden"
                          id="customFileLang"
                          lang="es"
                          name="photo"
                          onChange={(e) => uploadImage(e.target.files)}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLang"
                        >
                          Browse
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label className="col-sm-3"></label>
                      {fromData.photoUrl ? (
                        <span className="col-sm-9">
                          <Image
                            src={`${fromData.photoUrl}`}
                            style={{ height: "200px" }}
                            thumbnail
                          />
                        </span>
                      ) : (
                        <span className="col-sm-9">
                          <Image src={window.location.protocol + "//" + window.location.hostname + "/public/staff/" + fromData.mobile + ".jpeg"}
                            style={{ height: "200px" }} thumbnail />
                        </span>
                      )}

                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        DOB <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Datetime
                          dateFormat={true}
                          timeFormat={false}
                          onChange={(e) => {
                            let staffFromData = { ...fromData };
                            staffFromData.dob = moment(e);
                            setFormData(staffFromData);
                          }}
                          value={moment(fromData.dob)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ================== associate teacher class======================= */}
                <div className="row mb-4">
                  <div className="col-md-9">
                    <Form.Group className="row">
                      <label className="col-sm-2 col-form-label">
                        Select Role<span style={data.startstyle}>*</span>
                      </label>
                      {
                        rolesArray.map((val, index) => {
                          return (
                            <div className="col-md-2">
                              <Form.Group>
                                <div className="form-check form-check-success">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={checkedState[index]}
                                      name="checkedRolesArray"
                                      onChange={(e) => handleChanges(e)}
                                      value={index}
                                      disabled={
                                        rolesArray[index] == "parent" || rolesArray[index] == "coach"
                                          ? true
                                          : false
                                      }
                                    />{" "}
                                    {capitalize(rolesArray[index])}
                                    <i className={
                                      (rolesArray[index] == "parent" || rolesArray[index] == "coach") && checkedState[index]
                                        ? "input-helper disable"
                                        : "input-helper"
                                    }></i>
                                  </label>
                                </div>
                              </Form.Group>
                            </div>
                          )
                        })
                      }
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={fromData.markAttendance}
                            name="markAttendance"
                            onChange={(e) => handleChanges(e)}
                            value={fromData.markAttendance}
                            disabled={
                              checkedState[0]
                                ? false
                                : true
                            }
                          />{" "}
                          Attendance is Compulsory
                          <i className={
                            checkedState[1]
                              ? "input-helper disable"
                              : "input-helper"
                          }></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <Form.Group className="mb-0">
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="subjectHead"
                            onChange={(e) => handleChanges(e)}
                            checked={fromData.accessToModules.subjectHead}
                          />{" "}
                          Subject Head
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <div className="col-sm-11">
                        <select
                          disabled={!fromData.accessToModules.subjectHead}
                          name="subjectHead_id"
                          className="form-control"
                          onChange={(e) => handleChanges(e)}
                          required={fromData.accessToModules.subjectHead}
                          value={fromData.subjectHead_id}
                        >
                          <option value="">---------------</option>
                          {subject2.map((data) => {
                            return (
                              <option key={data.value} value={data.value}>
                                {data.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group className="mb-0">
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="classTeacher"
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => handleChanges(e)}
                            checked={fromData.accessToModules.classTeacher}
                          />{" "}
                          Class Teacher
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <div className="col-sm-11">
                        <select
                          disabled={!fromData.accessToModules.classTeacher}
                          name="classTeacherUserId"
                          className="form-control mb-0"
                          required={fromData.accessToModules.classTeacher}
                          onChange={(e) => handleChanges(e)}
                          value={fromData.classTeacherUserId}
                        >
                          <option value="">---------------</option>
                          {classes.map((data) => {
                            if (data.section) {
                              return (
                                <option key={data._id} value={data._id}>
                                  {data.className}{data && data.section ? "-" : ""}{data.section}
                                </option>
                              );
                            } else {
                              return (
                                <option key={data._id} value={data._id}>
                                  {data.className}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group className="mb-0">
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="floorIncharge"
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => handleChanges(e)}
                            checked={fromData.accessToModules.floorIncharge}
                          />{" "}
                          Floor Incharge
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <div className="col-sm-11">
                        <select
                          disabled={!fromData.accessToModules.floorIncharge}
                          name="floor_id"
                          className="form-control mb-0"
                          required={fromData.accessToModules.floorIncharge}
                          onChange={(e) => handleChanges(e)}
                          value={fromData.floorId}
                        >
                          <option value="">---------------</option>
                          {floors.map((data) => {
                            return (
                              <option key={data._id} value={data._id}>
                                {data.floorName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="asAdmin"
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => handleChanges(e)}
                            checked={fromData.accessToModules.asAdmin}
                            value="asAdmin"
                          />{" "}
                          As Admin
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="timeTable"
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => handleChanges(e)}
                            value="timetable"
                            checked={fromData.accessToModules.timeTable}
                          />{" "}
                          TimeTable
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="feeCollector"
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => handleChanges(e)}
                            value="feeCollector"
                            checked={fromData.accessToModules.feeCollector}
                          />{" "}
                          Fee Collector
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <h4 className="card-title">
                  Associate Teacher With Class & Subjects
                </h4>

                <div className="row">
                  <Form.Group className="col-md-12">
                    <label htmlFor="exampleTextarea1">
                      Introduction About Teacher
                    </label>
                    <textarea
                      name="introduction"
                      className="form-control"
                      id="exampleTextarea1"
                      placeholder="150-200 words"
                      rows="4"
                      onChange={(e) => handleChanges(e)}
                      value={fromData.introduction}
                    ></textarea>
                  </Form.Group>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Class <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          name="class"
                          className="form-control mb-0"
                          onChange={(e) => handleChanges(e)}
                          value={selectedClass.id + " " + selectedClass.name}
                        >
                          <option value="">---------------</option>
                          {classes.map((data) => {
                            if (data.section != undefined && data.section) {
                              return (
                                <option
                                  key={data._id}
                                  value={
                                    data._id +
                                    " " +
                                    data.className +
                                    "-" +
                                    data.section
                                  }
                                >
                                  {data.className}{data && data.section ? "-" : ""}{data.section}
                                </option>
                              );
                            } else {
                              return (
                                <option
                                  key={data._id}
                                  value={data._id + " " + data.className}
                                >
                                  {data.className}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-5">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Subject <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <MultiSelect
                          options={subject}
                          labelledBy="Select"
                          value={selectedSubject}
                          onChange={(e) => setSelectedSubject(e)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-sm-1">
                    <button
                      type="button"
                      onClick={() => addClassandSubject()}
                      className="btn btn-primary ml-2"
                      style={{
                        lineHeight: 1.5,
                        float: "right",
                        borderRadius: "25px",
                        width: "45px",
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <Grid
                      columns={field()}
                      rows={fromData.classes}
                      settings={{
                        search: false,
                        paging: false,
                        exportButton: false,
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary ml-2 btn-fw"
                      style={{ lineHeight: 1.5, float: "right" }}
                    >
                      {fromData._id ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
                {/* ========================================================== */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;