import React, { useContext, useEffect, useState } from 'react';
import Grid from "../../../components/dataTable";
import { Form, Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
// import ToggleDays from "./ToggleDays";
import { ToastContainer, toast } from "react-toastify";
import BusRoute from "../../../api/busRouteApi";
import class4Employees from "../../../api/addclass4Employees";
import BaseApi from "../../../api/baseApi";
import DeleteModal from "../../../components/deleteAlertPopup";
import Loader from "../../../components/loader";
import Buses from "../../../components/bulkupload/buses";
import ExportBusData from "../../../components/export/busData";
import RouteShowModal from './RouteShowModal';
const ManageBusData = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: 'red',
      fontSize: '14px'
    },
    isModalVisible: false,
    rowPropsId: "",
    selectedRoute: {},
  })
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [DAYS, setDAYS] = useState([]);
  const [busDrivers, setBusDrivers] = useState([]);
  const [busMonitors, setBusMonitors] = useState([]);
  const [driverList, setDriverList] = useState();
  const [monitorList, setMonitorList] = useState();

  useEffect(() => {
    getList();
    getDefaultSchoolSettings();
    getBusDrivers();
    getBusMonitors();
  }, []);

  const getList = async () => {
    setDataLoader(true);
    let BusRouteApi = new BusRoute();
    await BusRouteApi.getBusRoutes().then(async (response) => {
      if (response.data && response.data.length) {
        setRows(response.data)
        setSelectedRows(response.data)
      } else {
        setRows([]);
        setSelectedRows([]);
      }
    }).catch((error) => {
      toast.error(error.toString());
      console.error("There is an error!", error);
    });
    setDataLoader(false);
  }

  const getDefaultSchoolSettings = async () => {
    const baseApi = new BaseApi();
    const defaultSchoolSettings = await baseApi.getDefaultSchoolSettings();
    if (defaultSchoolSettings && defaultSchoolSettings.success) {
      setDAYS(defaultSchoolSettings.data.daysOfWeek);
    }
  }

  const renderAction = (props) => {

    return (
      <span>
        <ReactTooltip id='Edit' type='warning' effect='solid'>
          <span>Edit</span>
        </ReactTooltip>
        <button data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }} onClick={() => history.push(`/addbusroute?id=${props._id}`)}>
          <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
        </button>

        <ReactTooltip id='Delete' type='error' effect='solid'>
          <span>Delete</span>
        </ReactTooltip>

        <button data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{ padding: '8px' }} onClick={() => isVisible2(props._id)} >
          <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
        </button>
      </span>
    )
  }

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  }

  const deleteBusRoute = async (id) => {
    let BusRouteApi = new BusRoute();
    isVisible2();
    await BusRouteApi.deleteBusRoute(data.rowPropsId)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg)
          getList();
        } else {
          toast.error(response.msg)
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is ERROR!", error)
      })
  }

  const DeactiveAction = (props, index) => {
    return (
      <span>
        <ReactTooltip id='activate' type='error' effect='solid'>
          <span>Activate/Deactive</span>
        </ReactTooltip>
        <span data-tip data-for="activate" className="form-check form-check-success" style={{ display: 'inline-flex' }}>
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" defaultChecked />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    )
  }

  const field = () => {
    const fields = [
      {
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: DeactiveAction,
      },
      {
        title: "Route No.",
        field: "routeNo",
        render: routeHyperlink
      },
      {
        title: "Bus No.",
        field: "busNo",
      },
      {
        title: "Driver",
        field: "driver.name",
        render: DriverHyperlink
      },
      {
        title: "Monitor",
        field: "monitor.name",
        render: MonitorHyperlink
      },
      {
        title: "Start",
        field: "leaveFrom",
      },
      {
        title: "Capacity",
        field: "capacity",
        render: studentHyperlink
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  }

  const DriverHyperlink = (props) => {
    return (
      <>
        <Link to="/busdata">{props.driver.name}</Link>
      </>
    )
  }
  const MonitorHyperlink = (props) => {
    return (
      <>
        <Link to="/busdata">{props.monitor.name}</Link>
      </>
    )
  }

  const studentHyperlink = (props) => {
    return (
      <>
        <Link to="/busdata">{props.capacity}</Link>
      </>
    )
  }
  const routeHyperlink = (props) => {
    return (
      <span>
        <ReactTooltip id='subjects' type='warning' effect='solid'>
          <span>show route details</span>
        </ReactTooltip>
        <button data-tip data-for="subjects" onClick={() => handleOpen(props)} type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
          {props.routeNo}
        </button>
      </span>
    )
  }

  const handleOpen = (props) => {
    let d = { ...data };
    d.selectedRoute = props;
    d.isModalVisible = !d.isModalVisible;
    setData(d);
  }

  const handleClose = () => {
    let d = { ...data };
    d.selectedRoute = {};
    d.isModalVisible = false;
    setData(d);
  }

  const getBusDrivers = async (e) => {
    let getBusDrivers = new class4Employees();
    await getBusDrivers.getClassEmployees().then(async (response) => {
      if (response.data && response.data.length) {
        const nonTeachingRole = response.data.filter(data => {
          if (data.userRoles.subRole == "Driver") {
            return data
          }
        })
        setBusDrivers(nonTeachingRole);
      } else {
        setBusDrivers([]);
      }
    })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const getBusMonitors = async (e) => {
    let getBusMonitors = new class4Employees();
    await getBusMonitors.getClassEmployees().then(async (response) => {
      if (response.data && response.data.length) {
        const nonTeachingRole = response.data.filter(data => {
          if (data.userRoles.subRole == "Monitor") {
            return data
          }
        })
        setBusMonitors(nonTeachingRole);
      } else {
        setBusMonitors([]);
      }
    })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const chooseDriver = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];

    if (value) {
       rows.map((datas) =>{
        let name = '';
        if(datas.driver && datas.driver.name){
          name = datas.driver.name.trim().toUpperCase();
        }
        return datas.driver.userId == value ? selectedrow.push(datas) : null
       })
    } else {
      selectedrow = rows
    }
     setDriverList(value);
    setSelectedRows(selectedrow)
  }

  const chooseMonitor = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    
    if (value) {
      await rows.map((datas) => {
        if(datas.driver && datas.driver.name){
          name = datas.monitor.name.trim().toUpperCase();
        }
        return datas.monitor.userId == value ? selectedrow.push(datas) : null
      })
    } else {
      selectedrow = rows
    }
    setMonitorList(value);
    setSelectedRows(selectedrow)
  }


  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Bus Data </h3>
        <Link className="nav-link" to="/addbusroute">
          <button type="submit" className="btn btn-primary">Add</button>
        </Link>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Bus Driver</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => chooseDriver(e)} value={driverList} className="form-control">
                        <option value="">---------</option>
                        {busDrivers && busDrivers.map((datas) => {
                          return (
                            <option
                              key={datas.firstName}
                              value={datas._id}
                            >
                              {datas.firstName} {datas.lastName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-5">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Bus Monitor</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => chooseMonitor(e)} value={monitorList} className="form-control">
                        <option value="">---------</option>
                        {busMonitors && busMonitors.map((datas) => {
                          return (
                            <option
                              key={datas.firstName}
                              value={datas._id}
                            >
                              {datas.firstName} {datas.lastName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2">
              <div className="col-md-6 import">
                <Buses />
              </div>
              <div className="col-md-6 export">
                <ExportBusData busData={selectedRows} />
              </div>
            </div>
            <Grid columns={field()} rows={selectedRows} />
          </div>
        </div>
        <DeleteModal show={isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteBusRoute} />
      </div>
      {/* ================================= */}
      
      {/* ================================= */}
      <RouteShowModal data={data} handleClose={handleClose} DAYS={DAYS}/>
    </div >
  )
}

export default ManageBusData;