import BaseApi from './baseApi';

class class4Employees extends BaseApi {

    async getClassEmployees() {
        return await this.API(
            {
                url: "/class4employee/getclassemployees",
                method: "get"
            }
        )
    }

    async createClassEmployees(data) {
        return await this.API(
            {
                url: "/class4employee/createclassemployees",
                data:data,
                method: "post"
            }
        )
    }

    async editClassEmploye(data) {
        return await this.API(
            {
                url: "/class4employee/updateclassemployees",
                data:data,
                method: "post"
            }
        )
    }

    async deleteClassEmploye(data) {
        return await this.API(
            {
                url: "/class4employee/deleteclassemployees",
                data: { _id: data},
                method: "post"
            }
        )
    }

    async getClassEmploye(data) {
        return await this.API(
            {
                url: "/class4employee/getclassemployees/" + data,
                method:"get"
            }
        )
    }

    async activateClassEmployees(data) {
        return await this.API(
            {
                url: "/class4employee/activateclassemployees",
                data: data,
                method:"post"
            }
        )
    }

    async getAttendanceDetails(date) {
        return await this.API(
            {
                url: "/class4employee/getattendancedetails",
                params: {date:date},
                method:"get"
            }
        )
    }

    

}

export default class4Employees;