import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Image } from 'react-bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';
import StudentApi from "../../../api/studentApi";
import Class from "../../../api/classApi";
import Concession from "../../../api/concessionApi";
import { toast } from "react-toastify";
import { Link, useHistory } from 'react-router-dom';
import BusRoute from "../../../api/busRouteApi";
import "react-datetime/css/react-datetime.css";
import "react-tabs/style/react-tabs.css";
import Datetime from 'react-datetime';
import moment from "moment";

const AddStudent = () => {
  let history = useHistory();
  const [data, setData] = useState({
    startDate: new Date(),
    color: '#ffffff',
    fontcolor: '#ffffff',
    startstyle: {
      color: 'red',
      fontSize: '14px'
    }
  });

  const [formData, setFormData] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    admissionNo: "",
    admissionDate: "",
    dob: "",
    gender: "",
    photoUrl: "",
    fatherProfile: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      primary: "true"
    },
    motherProfile: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      primary: "false"
    },
    busId: "",
    concessionId: "",
    address: {
      country: "India",
      state: "",
      city: "",
      pinCode: "",
      address: ""
    },
    classId: "",
    sessionStart: "",
    passedout: "",
    bus: {
      private: true,
      route: null,
      destination: null
    }
  })
  const [fileProgress, setFileProgress] = useState({});
  const [classList, setClassList] = useState([])
  const [clist, setCList] = useState([])

  const [busList, setBusList] = useState([])
  const [destination, setDestination] = useState([])

  useEffect(() => {
    bsCustomFileInput.init();
    getClassList();
    getConcessionList();
    getBusList();
    const id = new URLSearchParams(history.location.search).get("id");
    if (id) {
      selectedStudent(id)
    }
  }, []);

  const getBusList = async (selectedId = null) => {
    let BusRouteApi = new BusRoute();
    await BusRouteApi.getBusRoutes().then(async (response) => {
      if (response.data && response.data.length) {
        setBusList(response.data)
        response.data.map(e => {
          if (e._id === selectedId) {
            setDestination(e.stops)
          }
        })
      } else {
        setBusList([]);
      }
    }).catch((error) => {
      toast.error(error.toString());
      console.error("There is an error!", error);
    });
  }

  const selectedStudent = async (id) => {
    let Api = new StudentApi();
    await Api.getStudent(id).then(async (response) => {
      if (response.data) {
        setFormData(response.data);
        getBusList(response.data.bus.route);
      } else {
        toast.error(response.msg.toString())
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getClassList = async () => {
    let clist = new Class();
    await clist.getClass().then(async (response) => {
      if (response.data && response.data.length) {
        setClassList(response.data);
      } else {
        setClassList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getConcessionList = async () => {
    let clist = new Concession();
    await clist.getConcession().then(async (response) => {
      if (response.data && response.data.length) {
        setCList(response.data);
      } else {
        setCList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    })
  }

  const handleChanges = (e) => {
    let FormData = { ...formData };
    let { name, value } = e.target;

    switch (name) {
      case "firstName":
        FormData.firstName = value;
        break;
      case "lastName":
        FormData.lastName = value;
        break;
      case "address":
        FormData.address.address = value;
        break;
      case "city":
        FormData.address.city = value;
        break;
      case "pinCode":
        FormData.address.pinCode = value;
        break;
      case "state":
        FormData.address.state = value;
        break;
      case "country":
        FormData.address.country = value;
        break;
      case "photoUrl":
        FormData.photoUrl = value;
        break;
      case "dob":
        FormData.dob = value;
        break;
      case "gender":
        FormData.gender = value;
        break;
      case "admissionNo":
        FormData.admissionNo = value;
        break;
      case "admissinDate":
        FormData.admissionDate = value;
        break;
      case "classId":
        FormData.classId = value;
        break;
      case "sessionStart":
        FormData.sessionStart = value;
        break;
      case "passedout":
        FormData.passedout = value;
        break;
      case "busId":
        FormData.busId = value;
        break;
      case "concessionId":
        FormData.concessionId = value;
        break;
      case "fatherFirstname":
        FormData.fatherProfile.firstName = value;
        break;
      case "fatherLastname":
        FormData.fatherProfile.lastName = value;
        break;
      case "fatherMobile":
        FormData.fatherProfile.mobile = value;
        break;
      case "fatherEmail":
        FormData.fatherProfile.email = value;
        break;
      case "MotherFirstName":
        FormData.motherProfile.firstName = value;
        break;
      case "MotherLastName":
        FormData.motherProfile.lastName = value;
        break;
      case "MotherMobile":
        FormData.motherProfile.mobile = value;
        break;
      case "MotherEmail":
        FormData.motherProfile.email = value;
        break;
      case "pinCode":
        FormData.motherProfile.pinCode = value;
        break;
      case "primary":
        if (value == "father") {
          FormData.motherProfile.primary = "false";
          FormData.fatherProfile.primary = "true";
        } else {
          FormData.motherProfile.primary = "true";
          FormData.fatherProfile.primary = "false";
        }
        break;
      case "private":
        FormData.bus = {
          private: value == "true" ? true : false,
          route: null,
          destination: null
        }
        break;
      case "destination":
        FormData.bus.destination = value;
        break;

    }
    setFormData(FormData);
  };

  async function uploadprofile(value) {
    let Api = new StudentApi();
    const data = new FormData();
    data.append("file", value[0]);
    await Api
      .uploadFile(data, (upload) => {
        let pg = { ...fileProgress };
        pg["file"] = Math.round((100 * upload.loaded) / upload.total);
        setFileProgress(pg);
      })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let form = { ...formData };
          form.photoUrl = data.fileUrl;
          setFormData(form);
          toast.success(data.msg);
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let Api = new StudentApi();
    let suc;
    console.log('formData',formData)
    if(formData.fatherProfile.primary == "true" && (formData.fatherProfile.email == "" || formData.fatherProfile.email == "")){
      toast.error("Father Mobile No. or Email is empty")
    }
    if(formData.motherProfile.primary == "true" && (formData.motherProfile.email == "" || formData.motherProfile.email == "")){
      toast.error("Mother Mobile No. or Email is empty")
    }
    // return;
    if (formData._id) {
      suc = await Api.updateStudent({
        formData
      })
      if (suc.error) {
        toast.error(suc.msg)
      } else {
        toast.success(suc.msg);
        cleanFields();
        history.push("/students");
      }
    } else {
      suc = await Api.addStudent({
        formData
      });

      if (suc.error) {
        toast.error(suc.msg)
      } else {
        toast.success(suc.msg)
        cleanFields();
        history.push("/students");
      }
    }
  }

  const cleanFields = () => {
    const clean = {
      firstName: "",
      lastName: "",
      admissionNo: "",
      admissionDate: "",
      dob: "",
      gender: "",
      photoUrl: "",
      fatherProfile: {
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        primary: "true"
      },
      motherProfile: {
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        primary: "false"
      },
      busId: "",
      concessionId: "",
      address: {
        country: "",
        state: "",
        city: "",
        pinCode: "",
        address: ""
      },
      classId: "",
      sessionStart: "",
      passedout: "",
      bus: {
        private: true,
        route: null,
        destination: null,
        id: "",
      }
    }
    setFormData(clean)
  }

  const selectedRoute = (e) => {
    let fD = { ...formData };
    let { name, value } = e.target;
    if (value === '') {
      setDestination([]);
      fD.bus = {
        private: false,
        route: null,
        destination: null
      }
    } else {
      busList.map(e => {
        if (e._id === value) {
          setDestination(e.stops);
          fD.bus = {
            private: false,
            route: e._id,
            destination: null
          }
        }
      })
    }
    setFormData(fD);
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> {formData._id ? "Update" : "Add"} Student  </h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Student Info</h4>
              <form onSubmit={(e) => handleSubmit(e)} className="forms-sample">

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Admission No.<span style={data.startstyle}>*</span></label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="admissionNo"
                          placeholder="Admission No"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.admissionNo}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Admission Date</label>
                      <div className="col-sm-9">

                        <Datetime
                          dateFormat={true}
                          timeFormat={false}
                          utc={true}
                          onChange={(e) => handleChanges({ target: { name: "admissinDate", value: moment(e) } })}
                          value={moment(formData.admissionDate)}
                        />

                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">First Name <span style={data.startstyle}>*</span></label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.firstName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Last Name</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          // required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.lastName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Date of Birth</label>
                      <div className="col-sm-9">
                        <Datetime
                          dateFormat={true}
                          timeFormat={false}
                          utc={true}
                          onChange={(e) => handleChanges({ target: { name: "dob", value: moment(e) } })}
                          value={moment(formData.dob)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label"> Photo</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="file"
                          className="form-control"
                          id="customFileLang"
                          lang="es"
                          name="photo"
                          onChange={(e) => uploadprofile(e.target.files)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label className="col-sm-3"></label>
                      {formData.photoUrl ? <span className="col-sm-9">
                        <Image src={`${formData.photoUrl}`} style={{ height: '200px' }} thumbnail />
                      </span> : (<span className="col-sm-9"><Image src={window.location.protocol + "//" + window.location.hostname + "/public/students/dummy.jpeg"} style={{ height: '200px' }} thumbnail /></span>)}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Gender</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="gender"
                          placeholder="Gender"
                          // required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.gender}
                        >
                          <option value="">--------</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Class <span style={data.startstyle}>*</span></label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="classId"
                          placeholder="Gender"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.classId}
                        >
                          <option value="">----------</option>
                          {
                            classList.map((e) => {
                              if (e.section) {
                                return (
                                  <option key={e._id} value={e._id}>{e.className} - {e.section}</option>
                                )
                              } else {
                                return (
                                  <option key={e._id} value={e._id}>{e.className}</option>
                                )
                              }

                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Session Start from</label>
                      <div className="col-sm-9">
                        <Datetime
                          dateFormat={true}
                          timeFormat={false}
                          utc={true}
                          onChange={(e) => handleChanges({ target: { name: "sessionStart", value: moment(e) } })}
                          value={moment(formData.sessionStart)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>


                {/* ===================================== Father ================================== */}
                <h4 className="card-title">Father's Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">First Name</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="fatherFirstname"
                          onChange={(e) => handleChanges(e)}
                          value={formData.fatherProfile.firstName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Last Name</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="fatherLastname"
                          onChange={(e) => handleChanges(e)}
                          value={formData.fatherProfile.lastName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Mobile No.</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="number"
                          name="fatherMobile"
                          onChange={(e) => handleChanges(e)}
                          value={formData.fatherProfile.mobile}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Email</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="email"
                          name="fatherEmail"
                          onChange={(e) => handleChanges(e)}
                          value={formData.fatherProfile.email}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="primary"
                            onClick={(e) => handleChanges(e)}
                            value="father"
                            checked={formData.fatherProfile.primary == "true" ? true : false}
                          />
                          <i className="input-helper"></i>
                          Primary
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ===================================== mother ================================== */}
                <h4 className="card-title">Mother's Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">First Name</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="MotherFirstName"
                          // required={formData.motherProfile.primary == "true" ? true : false}
                          onChange={(e) => handleChanges(e)}
                          value={formData.motherProfile.firstName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Last Name</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="MotherLastName"
                          onChange={(e) => handleChanges(e)}
                          value={formData.motherProfile.lastName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Mobile No.</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="number"
                          name="MotherMobile"
                          onChange={(e) => handleChanges(e)}
                          value={formData.motherProfile.mobile}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Email</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="email"
                          name="MotherEmail"
                          onChange={(e) => handleChanges(e)}
                          value={formData.motherProfile.email}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="primary"
                            onClick={(e) => handleChanges(e)}
                            value="mother"
                            checked={formData.motherProfile.primary == "true" ? true : false}
                          />
                          <i className="input-helper"></i>
                          Primary
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* =================================== Address ================================= */}
                <h4 className="card-title">Address</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Country</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="country"
                          // required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.address.country}
                        >
                          <option value="">-----------</option>
                          <option>India</option>

                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">State</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="state"
                          // required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.address.state}
                        >
                          <option value="">------------</option>
                          <option value="Andhra Pradesh">
                            Andhra Pradesh
                          </option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">
                            Daman and Diu
                          </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">
                            Madhya Pradesh
                          </option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">
                            Uttar Pradesh
                          </option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">City</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputCity1"
                          placeholder="Location"
                          name="city"
                          // required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.address.city}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Pin code</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputName12"
                          placeholder="Pin code"
                          name="pinCode"
                          // required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.address.pinCode}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <label htmlFor="exampleTextarea1">Address</label>
                  <textarea
                    className="form-control"
                    id="exampleTextarea1"
                    rows="4"
                    name="address"
                    // required={true}
                    onChange={(e) => handleChanges(e)}
                    value={formData.address.address}
                  ></textarea>
                </Form.Group>
                {/* =================================== other Details ============================== */}
                <h4 className="card-title">Other Details</h4>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="row">
                      <label className="col-sm-2 col-form-label">Concession id</label>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          name="concessionId"
                          onChange={(e) => handleChanges(e)}
                          value={formData.concessionId}
                        >
                          <option value="">-----------------</option>
                          {
                            clist.map((data) => {
                              if (data.active) {
                                return (
                                  <option key={data._id} value={data._id}>{data.concessionType} - {data.concessionAmount}{data.concessionPercent ? "%" : ""}</option>
                                )
                              }
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Status</label>
                      <div className="col-sm-9">
                        <select className="form-control">
                          <option>on</option>
                          <option>off</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Passed Out</label>
                      <div className="col-sm-9">
                        <Datetime
                          dateFormat={true}
                          timeFormat={false}
                          utc={true}
                          onChange={(e) => handleChanges({ target: { name: "passedout", value: moment(e) } })}
                          value={moment(formData.passedout)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ===================================================================== */}
                {/* =================================== Bus============================== */}
                <h4 className="card-title">Bus/Private</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group>
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="private"
                            value="false"
                            checked={formData.bus && !formData.bus.private ? true : false}
                            onClick={e => handleChanges(e)}
                          />
                          <i className="input-helper"></i>
                          Bus
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group>
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="private"
                            value="true"
                            checked={formData.bus ? formData.bus.private : true}
                            onClick={e => handleChanges(e)}
                          />
                          <i className="input-helper"></i>
                          Private
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Route</label>
                      <div className="col-sm-9">
                        <select
                          required={formData.bus && !formData.bus.private ? true : false}
                          disabled={formData.bus ? formData.bus.private : true}
                          className="form-control"
                          name='route'
                          onChange={e => selectedRoute(e)}
                          value={formData.bus ? formData.bus.route : ""}
                        >
                          <option value="">Please select Route</option>
                          {
                            busList.map(e => {
                              return <option key={e._id} value={e._id}>{e.routeNo}</option>
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Destination</label>
                      <div className="col-sm-9">
                        <select
                          required={formData.bus && !formData.bus.private ? true : false}
                          disabled={formData.bus ? formData.bus.private : true}
                          className="form-control"
                          name="destination"
                          onChange={(e) => handleChanges(e)}
                          value={formData.bus ? formData.bus.destination : ""}
                        >
                          <option value="">Please select Destination</option>
                          {
                            destination.map(e => {
                              return <option key={e._id} value={e._id}>{e.destination}</option>
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ========================================================== */}
                <button type="submit" className="btn btn-primary mr-2"> {formData._id ? "Update" : "Submit"}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddStudent;