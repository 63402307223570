import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import Class from '../../../api/classApi';
import SubjectsApi from '../../../api/subjectsApi';
import StaffMemberApi from '../../../api/staffMember';
import moment from 'moment';
import Loader from "../../../components/loader";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
// const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    height: '44px',
    padding: '3px',
    borderBottom: 'solid',
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "White",

    // styles we need to apply on draggables
    ...draggableStyle
});
const TimeTable = () => {

    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        isModalVisible: false,
    })

    const [formData, setFormData] = useState({
        selectedClass: '',
        classId: '',
        dayName: '',
        subjectId: '',
        subjectName: '',
        timeFrom: '',
        timeTo: '',
        teacherId: '',
        subjectTimingsId: '',
        daysOfWeek: []
    });

    const [classSubjects, setClassSubjects] = useState([]);
    const [teachersList, setTeachersList] = useState([]);
    const [staffTeachersList, setStaffTeachersList] = useState([]);
    const [rows, setRows] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [editCheck, setEditCheck] = useState(false);
    /*const [teachersAlert, setTeachersAlert] = useState({
        className: '',
        dayName: '',
        time: ''
    });*/

    useEffect(() => {
        getList();
        getStaffMembersByRole('teacher,principal'); // no space between commas.
    }, []);

    const getList = async () => {
        setDataLoader(true);
        const ClassApi = new Class();
        await ClassApi.getClass().then(async (response) => {
            // console.log(response.data)
            if (response.data && response.data.length) {
                formData.selectedClass = response.data[0]._id;
                // console.log(response.data, '===========================')
                setRows(response.data)
            } else {
                setRows([])
            }
        }).catch((error) => {
            toast.error(error.toString());
            console.error('There is an error!', error);
        });
        setDataLoader(false)
    }

    const getStaffMembersByRole = async (role) => {
        setDataLoader(true);
        const staffMemberApi = new StaffMemberApi();
        await staffMemberApi.getStaffMembersByRole(role).then(async (response) => {
            if (response.data && response.data.length) {
                setStaffTeachersList(response.data);
            } else {
                setStaffTeachersList([])
            }
            return response;
        }).catch((error) => {
            // toast.error(error.toString());
            console.error('There is an error!', error);
        });
        setDataLoader(false);
    }

    const handleChanges = (event) => {
        // console.log(formData);
        const f = { ...formData }
        const { name, value } = event.target;
        // console.log(name, value );
        if (name === 'className') {
            getSubjectsForClass(value);
            return;
        } else if (name === 'subject') {
            const valueArr = value.split('-');
            f.subjectId = valueArr[0];
            f.subjectName = valueArr[1];
            getTeacherBySubjectId(f.classId, f.subjectId);
            if (editCheck) {
                //
            } else {
                f.timeFrom = '';
                f.timeTo = '';
            }
        } else if (name === 'dayName') {
            loadDayData(value)
            return;
        } else if (name === 'timeFrom') {
            const check = validateSlotTime(name, value, f.subjectTimingsId);
            if (check) {
                f.timeFrom = value
            } else {
                f.timeFrom = '';
                alert('Time is in beween existing slot');
            }
            if (f.teacherId) {
                f.teacherId = '';
            }
            f.timeTo = '';
        } else if (name === 'timeTo') {
            const selectedTimeSeconds = moment(value, 'HH:mm');
            if (f.timeFrom) {
                const timeFrom = moment(f.timeFrom, 'HH:mm');
                if (timeFrom.isSameOrAfter(selectedTimeSeconds)) {
                    alert('Time To less than Time From');
                } else {
                    const check = validateSlotTime(name, value, f.subjectTimingsId);
                    if (check) {
                        f.timeTo = value
                    } else {
                        f.timeTo = '';
                        alert('Time is in beween existing slot');
                    }
                    if (f.teacherId) {
                        f.teacherId = '';
                    }
                }
            } else {
                alert('Choose Time From First');
            }
        } else if (name === 'teacher') {
            if (f.timeFrom && f.timeTo) {
                const check = validateTeacherTime(value)
                // console.log(check);
                if (check) {
                    f.teacherId = value;
                } else {
                    f.teacherId = '';
                    // alert in validate function.
                }
            } else {
                if (f.timeFrom) {
                    alert('Please select Time To');
                } else if (f.timeTo) {
                    alert('Please select Time From');
                } else {
                    alert('Please select Time First');
                }
            }
        } else if (name === 'classFilter') {
            f.selectedClass = value;
        }
        setFormData(f);
    }

    const loadDayData = (value) => {
        // console.log(value);
        let f = { ...formData }
        f.dayName = value;
        f.subjectId = '';
        f.timeFrom = '';
        f.timeTo = '';
        f.daysOfWeek = [];
        let selectedCls = rows.find(o => o._id === f.classId);
        console.log("selectedCls",selectedCls);
        if (selectedCls && selectedCls.timeTable) {
            let selectedDay = selectedCls.timeTable.find(o => o.dayName === value);
            if (selectedDay && selectedDay.subjectsTiming) {
                f.daysOfWeek = selectedDay.subjectsTiming
            }
        }
        setFormData(f);

        setTimeout(() => {
            console.log("f.daysOfWeek",f.daysOfWeek);
        }, 2000);
    }

    // const handleAddMore = (event) => {
    //     // console.log('data=======')
    //       let d = { ...formData}
    //     if(d.subjectId !== '' && d.timeFrom !== '' && d.timeTo !== ''){
    //         /* let subExists = d.daysOfWeek.find(o => o.subjectId == d.subjectId);
    //         if(!subExists){ */
    //             let newObj = {subjectId: formData.subjectId,subjectName: formData.subjectName, timeFrom: formData.timeFrom, timeTo: formData.timeTo}
    //             d.daysOfWeek.push(newObj);
    //             setFormData(d);
    //             clearItem();
    //         /* }else{
    //             alert("same");
    //         } */
    //     }
    //     //console.log(formData)
    // }

    const validateTeacherTime = (teacherId) => {
        let response = true;
        if (rows && rows.length) {
            loop1: for (let row of rows) {
                loop2: for (let tt of row.timeTable) {
                    loop3: for (let st of tt.subjectsTiming) {
                        if (st.teacherId === teacherId && tt.dayName === formData.dayName) {
                            response = validateSlotTimeForTeacher(formData, st);
                            if (response === false) {
                                const time = st.timeFrom + ' to ' + st.timeTo;
                                alert('Teacher is already assigned class ' + row.className + '-' + row.section + ' at ' + tt.dayName + ' from ' + time);
                                break loop1;
                            }
                        }
                    }
                }
            }
        }
        return response;
    }

    // const validateSlotTimeForTeacher = (formData, compareData) => {
    //     const formStartTimeSeconds = moment(formData.timeFrom, 'HH:mm');
    //     const formEndTimeSeconds = moment(formData.timeTo, 'HH:mm');;
    //     const compareStartTimeSeconds = moment(compareData.timeFrom, 'HH:mm');
    //     const compareEndTimeSeconds = moment(compareData.timeTo, 'HH:mm');
    //     if (editCheck && formData.subjectTimingsId) {
    //         formData.daysOfWeek.map((item) => {
    //             if (item._id === compareData._id && compareData._id === formData.subjectTimingsId) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         })
    //     } else if (formStartTimeSeconds.isBetween(compareStartTimeSeconds, compareEndTimeSeconds, undefined, '[)')) {
    //         return false;
    //     } else if (formEndTimeSeconds.isBetween(compareStartTimeSeconds, compareEndTimeSeconds, undefined, '(]')) {
    //         return false;
    //     } else if (moment(formStartTimeSeconds).isBefore(compareStartTimeSeconds) && moment(formEndTimeSeconds).isAfter(compareStartTimeSeconds)) {
    //         return false;
    //     }
    //     return true;
    // }

    const validateSlotTimeForTeacher = (formData, compareData) => {
        // const formStartTimeSeconds = moment(formData.timeFrom, 'HH:mm');
        // const formEndTimeSeconds = moment(formData.timeTo, 'HH:mm');;
        console.log('formData.subjectTimingsId',formData.subjectTimingsId)
        const formStartTimeSeconds = moment(formData.timeFrom).format("HH:mm:ss");
        console.log('formStartTimeSeconds',formStartTimeSeconds)
        const formEndTimeSeconds = moment(formData.timeTo).format("HH:mm:ss");
        console.log('formEndTimeSeconds',formEndTimeSeconds)
        const compareStartTimeSeconds = moment(compareData.timeFrom).format("HH:mm:ss");
        console.log('compareStartTimeSeconds',compareStartTimeSeconds)
        const compareEndTimeSeconds = moment(compareData.timeTo).format("HH:mm:ss");
        console.log('compareEndTimeSeconds',compareEndTimeSeconds)
        if (editCheck && formData.subjectTimingsId) {
            formData.daysOfWeek.map((item) => {
                if (item._id === compareData._id && compareData._id === formData.subjectTimingsId) {
                    return true;
                } else {
                    return false;
                }
            })
        } else if (formStartTimeSeconds >= compareStartTimeSeconds && formStartTimeSeconds <= compareEndTimeSeconds) {
            return false;
        } else if (formEndTimeSeconds >= compareStartTimeSeconds && formEndTimeSeconds <= compareEndTimeSeconds) {
            return false;
        } 
        // else if (formStartTimeSeconds < compareStartTimeSeconds && formEndTimeSeconds  > compareStartTimeSeconds) {
        //     return false;
        // }
        return true;
    }

    const validateSlotTime = (name, selectedTime, subjectTimingsId) => {
        // var selectedTimeSeconds = changeTime(selectedTime);
        // let flag = true;
        // console.log(formData)
        // formData.daysOfWeek.map((data) => {
        //     var startTimeSeconds =  changeTime(data.timeFrom);
        //     var endTimeSeconds =  changeTime(data.timeTo);

        //     var selectedTimeSecondsParsed = hmsToSecondsOnly(selectedTimeSeconds) //pass to convert to seconds function
        //     var startTimeSecondsParsed = hmsToSecondsOnly(startTimeSeconds)
        //     var endTimeSecondssParsed = hmsToSecondsOnly(endTimeSeconds)
        //     console.log(selectedTimeSecondsParsed, startTimeSecondsParsed, endTimeSecondssParsed)
        //     if (name === 'timeFrom' && selectedTimeSecondsParsed === startTimeSecondsParsed) {
        //         flag = false;
        //     }
        //     if (name === 'timeTo' && selectedTimeSecondsParsed === endTimeSecondssParsed) {
        //         flag = false;
        //     }
        //     if (selectedTimeSecondsParsed > startTimeSecondsParsed && selectedTimeSecondsParsed < endTimeSecondssParsed) { //if its between
        //         flag = false;
        //         // alert("Time in beween existig slot");
        //     }/* else {
        //      //alert("Time is not with in the time slot");
        //     }*/
        // })
        // return flag;

        // let schoolSettingsTo = { ...schoolSettings };
        // if (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
        //     || (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
        //     toast.error(`Please select Greater than start time`);
        // } else {
        //     schoolSettingsTo.slotsTime[index].endTime = moment(e);
        //     setSchoolSettings(schoolSettingsTo);
        // }

        // const selectedTimeSeconds = moment(selectedTime, 'HH:mm');
        const selectedTimeSeconds = moment(selectedTime).format("HH:mm:ss");
        let flag = true;
        console.log('formData.daysOfWeek',formData.daysOfWeek)
        formData.daysOfWeek.map((data) => {
            // const startTimeSeconds = moment(data.timeFrom, 'HH:mm');
            const startTimeSeconds = moment(data.timeFrom).format("HH:mm:ss");
            const endTimeSeconds = moment(data.timeTo).format("HH:mm:ss");
            console.log("selectedTimeSeconds",selectedTimeSeconds)
            console.log("startTimeSeconds",startTimeSeconds)
            console.log("endTimeSeconds",endTimeSeconds)
            if (name === 'timeFrom' && selectedTimeSeconds == startTimeSeconds) {
                flag = false;
            }
            if (name === 'timeTo' && selectedTimeSeconds == endTimeSeconds) {
                flag = false;
            }
            // if (selectedTimeSeconds.isBetween(startTimeSeconds, endTimeSeconds)) {
            if (selectedTimeSeconds > startTimeSeconds && selectedTimeSeconds < endTimeSeconds) {
                flag = false;
            }
            if (editCheck && subjectTimingsId && subjectTimingsId === data._id) {
                flag = true;
            }
        })
        return flag;
    }

    // var selectedTime = '12:30 Am'
    // var startTime = '12:00 AM'
    // var endTime = '13:00 AM'

    /*function changeTime(time){
        var thisTime;
        var thisHour = +time.substring(0,2);
        if( time.substring(6,8) === 'PM') {
            //add 12 hours to make it 24 hour clock
            thisHour +=12;
        }
        return thisHour+time.substring(2,5)
    }*/


    /*function hmsToSecondsOnly(str) {
        var p = str.split(':'),
            s = 0,
            m = 1;

        while (p.length > 0) {
            s += m * parseInt(p.pop(), 10);
            m *= 60;
        }

        return s;
    }*/

    const getSubjectsForClass = (id) => {
        let f = { ...formData };
        f.classId = id;
        f.subjectId = '';
        f.timeFrom = '';
        f.timeTo = '';
        f.dayName = '';
        f.daysOfWeek = [];
        setFormData(f);
        rows.map((data) => {
            if (data._id === id) {
                setClassSubjects(data.subject_docs);
            }
        });
    }

    const getTeacherBySubjectId = async (classId, subjectId) => {
        const subjectApi = new SubjectsApi();
        await subjectApi.getTeacherBySubjectId(classId, subjectId)
            .then((data) => {
                setTeachersList(data.data);
            })
            .catch((error) => {
                console.error('There is ERROR!', error);
                toast.error(error.toString());
            })
    }

    /*const clearItem = () => {
        let f = { ...formData};
        f.subjectId = '';
        f.timeFrom = '';
        f.timeTo = '';
        f.subjectTimingsId = '';
        setFormData(f);
    }*/

    const cleanFields = () => {
        let f = { ...formData };
        f.classId = '';
        f.dayName = '';
        f.subjectId = '';
        f.timeFrom = '';
        f.timeTo = '';
        f.teacherId = '';
        f.daysOfWeek = [];
        f.subjectTimingsId = ''
        setFormData(f);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const {
            classId,
            dayName,
            daysOfWeek
        } = formData;
        // console.log(formData);
        const ClassApi = new Class()
        if (formData.classId) {
            await ClassApi.updateTimeTable(formData)
                .then((data) => {
                    if (data.success) {
                        toast.success(data.msg.toString());
                        cleanFields();
                        getList();
                        setEditCheck(false);
                    } else {
                        toast.error(data.msg);
                    }
                })
                .catch((error) => {
                    toast.error(error.toString());
                    console.error("There was an error!", error);
                });
        }
    }

    const editTimeTable = (props, dayName) => {
        let index = -1;
        const f = { ...formData }
        if (rows && rows.length) {
            loop1: for (let row of rows) {
                loop2: for (let tt of row.timeTable) {
                    loop3: for (let value of tt.subjectsTiming) {
                        index++
                        if (value._id === props._id && tt.dayName === dayName) {
                            console.log('value.timeFrom',value.timeFrom)
                            f.daysOfWeek = tt.subjectsTiming;
                            f.subjectId = value.subjectId;
                            f.subjectName = value.subjectName;
                            f.timeFrom = value.timeFrom;
                            f.timeTo = value.timeTo;
                            f.teacherId = value.teacherId;
                            f.subjectTimingsId = value._id;
                            getTeacherBySubjectId(f.classId, f.subjectId);
                            setFormData(f);
                            setEditCheck(true);
                            break;
                        }
                    }
                }
            }
        }
    }

    const renderAction = (props, dayName) => {
        return (
            <span className="col-3">
                {/*<ReactTooltip id='Edit' type='warning' effect='solid'>
                    <span>Edit</span>
                </ReactTooltip>
                <button data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{padding:'8px'}} onClick={() => {editTimeTable(props,dayName)}}>
                    <i className="mdi mdi-border-color" style={{fontSize:'17px'}}></i>
                </button>
                
                <ReactTooltip id='Delete' type='error' effect='solid'>
                    <span>Delete</span>
                </ReactTooltip>
                <button data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{padding:'8px'}} onClick={() => {deleteDay(props._id,dayName)}}>
                    <i className="mdi mdi-delete"style={{fontSize:'17px'}}></i>
                </button>*/}
            </span>
        )
    }

    const showSubjectSchedule = (record) => {
        // console.log('timetableee',record);
        return record.timeTable.map((dt) =>
            <React.Fragment key={dt._id}>
                <div className="col-lg-4 mb-3" id="">
                    <h4 className="mt-0 mb-3 text-dark op-8 font-weight-bold">
                        {dt.dayName ? dt.dayName : ''}
                    </h4>
                    {renderAction(record, dt.dayName)}
                    <ul className="list-timeline list-timeline-primary">
                        {showSheduledDayTime(dt)}
                    </ul>
                </div>
            </React.Fragment>
        )
    }

    const showSheduledDayTime = (shedule) => {
        // console.log("shedule",shedule)
        shedule.subjectsTiming.map((dt) => {
            const teacherName = staffTeachersList.find(item => {
                return item._id === dt.teacherId;
            })
            if (teacherName) {
                const name = teacherName.firstName + ' ' + teacherName.lastName;
                dt.teacherName = name;
            }
        })
        return shedule.subjectsTiming.map((dt) =>
            <React.Fragment key={dt._id}>
                <li className="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column">
                    <p className="my-0 text-dark flex-fw text-sm text-uppercase">
                        {/* <span className="text-inverse op-8">{dt.timeFrom ? moment(dt.timeFrom, 'HH: mm').format('hh:mm A') : '--'} - {dt.timeTo ? moment(dt.timeTo, 'HH: mm').format('hh:mm A') : '--'}
                        </span>  */}
                        <span className="text-inverse op-8">{dt.timeFrom ? moment(dt.timeFrom).format('hh:mm A') : '--'} - {dt.timeTo ? moment(dt.timeTo).format('hh:mm A') : '--'}
                        </span> 
                        - {dt.subjectName ? dt.subjectName : '--'}
                        <span><br />({dt.teacherName})</span>
                    </p>
                </li>
            </React.Fragment>
        )
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            formData.items,
            result.source.index,
            result.destination.index
        );

        setFormData(
            formData.items = items
        );
    }

    const removeTimeTableItem = async (subjectsTiming, index) => {
        const f = { ...formData };
        const ClassApi = new Class();
        await ClassApi.deleteSubjectTimings({ _id: subjectsTiming, index: index })
            .then((response) => {
                if (response.success) {
                    toast.success(response.msg.toString());
                    f.daysOfWeek.splice(index, 1);
                    setFormData(f);
                } else {
                    toast.error(response.msg);
                }
            })
            .catch((error) => {
                console.error('There is ERROR!', error);
                toast.error(error.toString());
            })
    }

    /*const deleteDay = async(classId,dayName) => {
        let ClassApi = new Class()
        await ClassApi.deleteDaySchedule({_id: classId, dayName: dayName})
        .then((response)=>{
            // console.log(response);
            toast.success(response.msg.toString());
        })
        .catch((error)=>{
            console.error("There is ERROR!", error);
            toast.error(error.toString());
        })
        getList()
    }*/

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Time Tables </h3>
                <Link className="nav-link" to="/timetable/autotimetable">
                    <button type="submit" className="btn btn-primary">Automated Time Table</button>
                </Link>
            </div>
            {dataLoader ? <Loader /> : null}
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-12 col-form-label">Select Class<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-control"
                                                    name="className"
                                                    onChange={handleChanges}
                                                    value={formData && formData.classId !== '' ? formData.classId : ''}
                                                    required={true}
                                                >
                                                    <option value="">---</option>
                                                    {
                                                        rows.map((data) =>
                                                            <option key={data._id} value={data._id}>{data.className}{data && data.section ? "-" : ""}{data.section}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Group className="row">
                                            <label className="col-sm-12 col-form-label">Day<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-control"
                                                    name="dayName"
                                                    onChange={handleChanges}
                                                    value={formData && formData.dayName !== '' ? formData.dayName : ''}
                                                    required={true}
                                                >
                                                    <option value="">---</option>
                                                    <option value="Monday">Monday</option>
                                                    <option value="Tuesday">Tuesday</option>
                                                    <option value="Wednesday">Wednesday</option>
                                                    <option value="Thursday">Thursday</option>
                                                    <option value="Friday">Friday</option>
                                                    <option value="Saturday">Saturday</option>
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-2">
                                        <Form.Group className="row">
                                            <label className="col-sm-12 col-form-label">Subject<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-control"
                                                    name="subject"
                                                    onChange={handleChanges}
                                                    value={formData && formData.subjectId !== '' ? formData.subjectId + '-' + formData.subjectName : ''}
                                                    required={true}
                                                >
                                                    <option value="">---</option>
                                                    {
                                                        classSubjects.map((data) =>
                                                            <option key={data._id} value={data._id + '-' + data.subjectName}> {data.subjectName} </option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-7">
                                        <Form.Group className="row">
                                            <label className="col-sm-12 col-form-label">Time change<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-5">
                                                {/* <Form.Control
                                                    type="time"
                                                    format="hh:mm"
                                                    name="timeFrom"
                                                    placeholder="Start Time"
                                                    onChange={handleChanges}
                                                    value={formData && formData.timeFrom !== '' ? formData.timeFrom : ''}
                                                    required={true}
                                                /> */}
                                                <Datetime
                                                    dateFormat={false}
                                                    // name="timeFrom"
                                                    value={formData && formData.timeFrom != ''? moment(formData.timeFrom) : ""}
                                                    // initialvalue={
                                                    //     moment('2023-09-18T02:30:00.000Z').format("hh:mm A")
                                                    // }
                                                    onChange={(e) => {

                                                        let selectedTimeSeconds = moment(e).format("HH:mm:ss");
                                                        let formDataFrom = { ...formData };
                                                        if (formDataFrom.timeTo) {
                                                            console.log('inedit mode')
                                                            let timeTo = moment(formDataFrom.timeTo).format("HH:mm:ss");
                                                            if (timeTo == selectedTimeSeconds || timeTo < selectedTimeSeconds) {
                                                                alert('Time From greater than Time To');
                                                            } else {

                                                                let check = validateSlotTime("timeFrom", (e), formDataFrom.subjectTimingsId);
                                                                // let formDataFrom = { ...formData };
                                                                console.log('check',check)
                                                                if (!check) {
                                                                    // formData.timeFrom = moment(e)
                                                                    // setFormData(formData);
                                                                    // if (formData.teacherId) {
                                                                    //     formData.teacherId = '';
                                                                    //     setFormData(formData);
                                                                    // }
                                                                    alert('Time is in beween existing slot');
                                                                } else {
                                                                    formDataFrom.timeFrom = moment(e)
                                                                    // formDataFrom.timeTo = '';
                                                                    if (formDataFrom.teacherId) {
                                                                        formDataFrom.teacherId = '';
                                                                        // setFormData(formData);
                                                                    }
                                                                    setFormData(formDataFrom);
                                                                    // formData.timeFrom = '';
                                                                }
                                                            }
                                                        }else{
                                                            let check = validateSlotTime("timeFrom", (e), formData.subjectTimingsId);
                                                                // let formDataFrom = { ...formData };
                                                                console.log('check',check)
                                                                if (!check) {
                                                                    // formData.timeFrom = moment(e)
                                                                    // setFormData(formData);
                                                                    // if (formData.teacherId) {
                                                                    //     formData.teacherId = '';
                                                                    //     setFormData(formData);
                                                                    // }
                                                                    alert('Time is in beween existing slot');
                                                                } else {
                                                                    formDataFrom.timeFrom = moment(e)
                                                                    formDataFrom.timeTo = '';
                                                                    if (formDataFrom.teacherId) {
                                                                        formDataFrom.teacherId = '';
                                                                        // setFormData(formData);
                                                                    }
                                                                    setFormData(formDataFrom);
                                                                    // formData.timeFrom = '';
                                                                }
                                                        }
                                                        // formData.timeTo = '';

                                                        setTimeout(() => {
                                                            console.log('f.timeFrom',formData.timeFrom)
                                                            console.log('f.timeTo',formData.timeTo)
                                                        }, 2000);


                                                        // let schoolSettingsTo = { ...schoolSettings };
                                                        // if (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
                                                        //   || (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
                                                        //   toast.error(`Please select Greater than start time`);
                                                        // } else {
                                                        //   schoolSettingsTo.slotsTime[index].endTime = moment(e);
                                                        //   setSchoolSettings(schoolSettingsTo);
                                                        // }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-sm-1 mt-3"> to </div>
                                            <div className="col-sm-5">
                                                {/* <Form.Control
                                                    type="time"
                                                    format="hh:mm"
                                                    name="timeTo"
                                                    placeholder="End Time"
                                                    onChange={handleChanges}
                                                    value={formData && formData.timeFrom !== '' ? formData.timeTo : ''}
                                                    required={true}
                                                /> */}
                                                <Datetime
                                                    dateFormat={false}
                                                    // name="timeFrom"
                                                    // value={moment(formData && formData.timeTo ? formData.timeTo : '2023-09-18T02:30:00.000Z')}
                                                    value={formData && formData.timeTo != '' ? moment(formData.timeTo): ""}
                                                    // initialvalue={
                                                    // moment('2023-09-18T02:30:00.000Z').format("hh:mm A")
                                                    // }
                                                    onChange={(e) => {

                                                        let formDataTo = { ...formData };
                                                        const selectedTimeSeconds = moment(e).format("HH:mm:ss");
                                                        if (formDataTo.timeFrom) {
                                                            const timeFrom = moment(formDataTo.timeFrom).format("HH:mm:ss");
                                                            if (timeFrom == selectedTimeSeconds || timeFrom > selectedTimeSeconds) {
                                                                alert('Time To less than Time From');
                                                            } else {
                                                                const check = validateSlotTime("timeTo", (e), formDataTo.subjectTimingsId);
                                                                console.log('check timeTo',check)
                                                                if (!check) {
                                                                    // formData.timeTo = moment(e)
                                                                    // setFormData(formData);
                                                                    // if (formData.teacherId) {
                                                                    //     formData.teacherId = '';
                                                                    //     setFormData(formData);
                                                                    // }
                                                                    alert('Time is in beween existing slot');
                                                                } else {
                                                                    formDataTo.timeTo = moment(e)
                                                                    setFormData(formDataTo);
                                                                    if (formDataTo.teacherId) {
                                                                        formDataTo.teacherId = '';
                                                                        setFormData(formDataTo);
                                                                    }
                                                                    // formData.timeTo = '';
                                                                }
                                                            }
                                                        } else {
                                                            alert('Choose Time From First');
                                                        }

                                                        setTimeout(() => {
                                                            console.log('f.timeTo',formData.timeTo)
                                                        }, 2000);

                                                        // const check = validateSlotTime("timeFrom", e, formData.subjectTimingsId);
                                                        // if (check) {
                                                        //     formData.timeFrom = moment(e)
                                                        // } else {
                                                        //     formData.timeFrom = '';
                                                        //     alert('Time is in beween existing slot');
                                                        // }
                                                        // if (formData.teacherId) {
                                                        //     formData.teacherId = '';
                                                        // }
                                                        // formData.timeTo = '';
                                                        
                                                        // let schoolSettingsTo = { ...schoolSettings };
                                                        // if (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
                                                        //   || (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
                                                        //   toast.error(`Please select Greater than start time`);
                                                        // } else {
                                                        //   schoolSettingsTo.slotsTime[index].endTime = moment(e);
                                                        //   setSchoolSettings(schoolSettingsTo);
                                                        // }
                                                    }}
                                                />
                                            </div>
                                            {/*<div className="col-md-1">
                                                <button type="button" className="btn btn-link btn-rounded btn-fw" onClick={handleAddMore}>Add More</button>
                                            </div>*/}
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-3">
                                        <Form.Group className="row">
                                            <label className="col-sm-12 col-form-label">Teacher<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-control"
                                                    name="teacher"
                                                    onChange={handleChanges}
                                                    value={formData && formData.teacherId}
                                                    required={true}
                                                >
                                                    <option value="">---</option>
                                                    {teachersList.map((data) =>
                                                        <option key={data._id} value={data._id}> {data.name} </option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="row">
                                            <label className="col-sm-2 col-form-label"></label>
                                            <div className="col-sm-8">
                                                {/* ================================================================================== */}
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {formData.daysOfWeek.map((item, index) => (
                                                                    item._id && <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                            >
                                                                                <span className="row">
                                                                                    <span className="col-4">{item.subjectName} </span>
                                                                                    {/* <span className="col-2">{moment(item.timeFrom, 'HH: mm').format('hh:mm A')} </span>
                                                                                    <span className="col-2">{moment(item.timeTo, 'HH: mm').format('hh:mm A')} </span> */}
                                                                                    <span className="col-2">{moment(item.timeFrom).format('hh:mm A')} </span>
                                                                                    <span className="col-2">{moment(item.timeTo).format('hh:mm A')} </span>
                                                                                    <span className="col-3 text-right">
                                                                                        <ReactTooltip id='Edit' type='warning' effect='solid'>
                                                                                            <span>Edit</span>
                                                                                        </ReactTooltip>
                                                                                        <button data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }} onClick={() => { editTimeTable(item, formData.dayName) }}>
                                                                                            <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                                                                                        </button>
                                                                                        <ReactTooltip id='Delete' type='error' effect='solid'>
                                                                                            <span>Delete</span>
                                                                                        </ReactTooltip>
                                                                                        <button data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{ padding: '8px' }} onClick={() => removeTimeTableItem(item._id, index)}>
                                                                                            <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
                                                                                        </button>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                {/* ================================================================================== */}
                                            </div>
                                            <label className="col-sm-2 col-form-label"></label>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }}>Add</button>
                                    </div>

                                </div>

                                {/* ========================================================== */}
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <nav aria-label="breadcrumb">
                            <Form.Group className="row mt-2 ml-1">
                                <label className="col-sm-1 col-form-label">Select Class</label>
                                <div className="col-sm-5">
                                    <select className="form-control" name="classFilter" onChange={handleChanges} value={formData && formData.selectedClass !== '' ? formData.selectedClass : ''}>
                                        {rows.map((data) => <option key={data._id} value={data._id}>{data.className}{data && data.section ? "-" : ""}{data.section}</option>)}
                                    </select>
                                </div>
                            </Form.Group>
                        </nav>

                        <div className="container py-7">
                            <div className="row">
                                {
                                    rows.map((dt) => {
                                        // console.log(dt)
                                        if (formData.selectedClass && formData.selectedClass === dt._id) {
                                            return (
                                                <React.Fragment key={dt._id}>
                                                    <h2 className="col-12 text-uppercase text-letter-spacing-xs my-0 text-primary font-weight-bold" key={dt._id}>
                                                        Schedule ({dt.className}{dt && dt.section ? "-" : ""}{dt.section})
                                                    </h2>
                                                    <p className="col-12 text-sm text-dark mt-0 mb-5">Weakly Time table for class <span className="btn btn-link ml-0 pl-0">{dt.className}{dt && dt.section ? "-" : ""}{dt.section}</span></p>
                                                    {showSubjectSchedule(dt)}
                                                </React.Fragment>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ================================= */}

            {/* ================================= */}
        </div>
    )
}

export default TimeTable;
