import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ClassApi from "../../api/classApi";

const Classes = () => {
  const [isChooseFileDisplay, setIsChooseFileDisplay] = useState(false);

  function downloadClassCSVFormat() {
    let headers = [
      { label: "Class Name", key: "className" },
      { label: "Section", key: "section" },
    ];

    return (
      <CSVLink
        data={[{
          className: "II",
          section: "A"
        }]}
        filename={"School_Sample_Classes_Import.csv"}
        headers={headers}
      >
        (Download Sample CSV)
      </CSVLink>
    );
  }

  async function uploadCsvFile(claassData) {
    let classNameColumnIndex = -1;
    let sectionColumnIndex = -1;
    let imported = [];
    let firstRowIndex = -1;
    claassData.every(async (row, index) => {
      if (classNameColumnIndex == -1 && row.indexOf("Class Name") > -1) {
        classNameColumnIndex = row.indexOf("Class Name");
      }
      if (sectionColumnIndex == -1 && row.indexOf("Section") > -1) {
        sectionColumnIndex = row.indexOf("Section");
      }
      if (firstRowIndex === -1) {
        firstRowIndex = index + 1;
      }

      if (index >= firstRowIndex) {
        let className = row[classNameColumnIndex];
        let section = ''
        if (row[sectionColumnIndex]) {
          section = row[sectionColumnIndex].toUpperCase();
        }
        if (!className) {
          // blank row
          return false;
        }
        if (!className) {
          toast("Class Name is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          className = className.trim();
        }
        if (section) {
          section = section.trim();
        }
        imported.push({
          className,
          section
        });
      }
    })
    saveClassCsv(imported);
  }

  async function saveClassCsv(imported) {
    if (window.confirm("Are your sure for upload the class csv file ?")) {
      let Api = new ClassApi();
      await Api.saveClassCsv(imported)
        .then(async (response) => {
          toast.success("Csv file is save successfully");
          window.location.reload(false);
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There is an error!", error);
        });
    }
  }

  function uploadClassCsv() {
    return (
      <CSVReader
        onFileLoaded={(studentsData) => uploadCsvFile(studentsData)}
        inputStyle={{ fontSize: "12px" }}
      />
    );
  }

  return (
    <>
      <div className="col-md-12 textRight">
        <a href="javascript:void(0)" onClick={() => setIsChooseFileDisplay(!isChooseFileDisplay)}>
          <><i className="mdi mdi-apple-keyboard-caps"></i> Import Class Data </>
        </a>
        <a className="text-sm">
          {downloadClassCSVFormat()}
        </a>
      </div>
      <div className="col-md-5 text-sm">
        <div>{isChooseFileDisplay ? uploadClassCsv() : null}</div>
      </div>
    </>
  );

}

export default Classes;