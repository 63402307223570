import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import SchoolApi from "../../api/schoolApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const history = useHistory();
  let location = useLocation();
  let user = localStorage.getItem("user_info");
  user = JSON.parse(user);
  let schoolId = localStorage.getItem("schoolId");
  useEffect(() => {
    getAdminSchools(user.email);
  }, []);
  const [schools, setSchools] = useState([]);
  const [aciveSchoolIndex, setActiveSchoolIndex] = useState(0);

  const dispatch = useDispatch();
  const schoolData = useSelector((state) => state && state.schoolReducer.schoolList && state.schoolReducer.schoolList.data);
  console.log("schoolData", schoolData);

  async function getAdminSchools(email) {
    let schoolApi = new SchoolApi();
    await schoolApi
      .getAdminSchools(email)
      .then((data) => {
        if (data.success) {
          // if(data.data[aciveSchoolIndex] && data.data[aciveSchoolIndex].colorScheme.schoolLogo.indexOf("https://edvantumdev.76east.com")){
          //   let arr = data.data[aciveSchoolIndex].colorScheme.schoolLogo.split('/')
          //   data.data[aciveSchoolIndex].colorScheme.schoolLogo = "https://edvantum.com/" + arr[3]+'/' + arr[4]+'/' + arr[5]
          // }
          setSchools(data.data);
        } else {
          toast.error(data.msg);
          localStorage.removeItem("token");
          localStorage.removeItem("user_info");
          history.push("/login");
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user_info");
    history.push("/login");
  }
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  if(schools[aciveSchoolIndex] && schools[aciveSchoolIndex].colorScheme && schools[aciveSchoolIndex].colorScheme.schoolLogo){

    console.log('schools[aciveSchoolIndex].colorScheme.schoolLogo',schools[aciveSchoolIndex].colorScheme.schoolLogo)
  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div
        className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
        style={{ background: "#fff" }}
      >
        <div className="col-10">
          <Link className="navbar-brand brand-logo" to="/">
            <img
              src={
                schools[aciveSchoolIndex] &&
                  schools[aciveSchoolIndex].colorScheme &&
                  schools[aciveSchoolIndex].colorScheme.schoolLogo
                  ? schools[aciveSchoolIndex].colorScheme.schoolLogo
                  : require("../../assets/images/Edvantum.png")
              }
              style={{
                height: "unset",
                maxWidth: "100%",
                maxHeight: "60px",
                margin: "auto",
                verticalAlign: "middle",
              }}
              alt="logo"
              loading="lazy"
            />
          </Link>
          {
            <Link className="navbar-brand brand-logo-mini" to="/">
              <span
                style={{
                  background: "white",
                  display: "block",
                  margin: "10px",
                  borderRadius: "12px",
                  fontWeight: "bolder",
                  fontSize: "21px",
                }}
              >
                SA
              </span>
            </Link>
          }
        </div>
        <div className="col-2 navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <ul className="navbar-nav navbar-nav-right">
          <li>
            <select
              className="form-control"
              id="selected_school"
              value={schoolId}
              onChange={(e) => {
                localStorage.setItem("schoolId", e.target.value);
                let selectedIndex =
                  document.getElementById("selected_school").selectedIndex;
                setActiveSchoolIndex(selectedIndex);
                localStorage.setItem(
                  "schoolColorScheme",
                  JSON.stringify(schools[selectedIndex].colorScheme)
                );
                localStorage.setItem("schoolId", e.target.value);
                if ((location.path = "/dashboard")) {
                  window.location.reload(false);
                } else {
                  history.push("/dashboard");
                }
              }}
            >
              {schools.map((item, i) => {
                return (
                  <option key={i} value={item._id}>
                    {item.schoolName}
                  </option>
                );
              })}
            </select>
          </li>
          <li className="nav-item nav-profile nav-language">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{user.firstName}</p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <div className="p-2">
                  <h5 className="dropdown-header text-uppercase pl-2 text-dark">
                    User Options
                  </h5>
                  <div role="separator" className="dropdown-divider"></div>
                  <Dropdown.Item className="justify-content-between align-items-center dropdown-item">
                    <Link className="nav-link" to="/changepassword">
                      Change Password
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item d-flex align-items-center justify-content-between"
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <span onClick={() => logout()}>Log Out</span>
                    <i className="mdi mdi-logout ml-1"></i>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={(e) => toggleOffcanvas()}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};
export default Header;
