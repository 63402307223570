import BaseApi from "./baseApi";

class FeeData extends BaseApi {

    async createFeeData(data) {
        return await this.API(
            {
                url: "/feedata/createfeedata",
                method:"post",
                data: data
            }
        )
    }

    async getFeeDataList(session) {
        return await this.API(
            {
                url: "/feedata/getfeedatalist",
                method:"get",
                params: { session: session }
            }
        )
    }

    async getFeeData(data) {
        return await this.API(
            {
                url: "/feedata/getfeedata/" + data,
                method:"get",
            }
        )
    }

    async updateFeeData(data) {
        return await this.API(
            {
                url: "/feedata/updatefeedata",
                method:"post",
                data: data
            }
        )
    }

    async deleteFeeData(id, className) {
        return await this.API(
            {
                url: '/feedata/deletefeedata',
                method:"post",
                data:  { _id: id, className: className }
            }
        )
    }

    async updateFeeDetails(data) {
        return await this.API(
            {
                url: '/feedata/updatefeedetails',
                method: 'post',
                data: data
            }
        )
    }

    async saveFeeDataCsv(data) {
        return await this.API(
            {
                url: '/feedata/savefeedatacsv',
                method: 'post',
                data: data
            }
        )
    }

    
}

export default FeeData;
