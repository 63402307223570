import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../action/getSchoolAction";

const slice = createSlice({
    name: "school",
    initialState: {
        schoolList: [],
        loading: false,
    },

    reducers: {
        menuRequested: (school, action) => {
            school.loading = true;
        },
        menuReceived: (school, action) => {
            school.schoolList = action.payload;
            school.loading = false;
        },

        menuRequestFailed: (school, action) => {
            school.loading = false;
        },
    },
});

export default slice.reducer;

const { menuRequested, menuReceived, menuRequestFailed } = slice.actions;

export const loadschool = (id) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "/school/getschool",
            method: "get",
            params: { id: id },
            onStart: menuRequested.type,
            onSuccess: menuReceived.type,
            onError: menuRequestFailed.type,
        })
    );
};