import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import About from "../../../api/aboutApi";

const UpdateAbout = () => {
    const [data, setData] = useState({
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        _id: "",
        title: "",
        description: ""
    })

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let AboutApi = new About();
        await AboutApi.getAboutContent().then(async (response) => {
            if (response.success && response.data) {
                setData(response.data[0]);
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const handleChanges = (event) => {
        let d = { ...data }
        const { name, value } = event.target;
        if (name == "title") {
            d.title = value
        } else if (name === 'description') {
            d.description = value;
        }
        setData(d);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let AboutApi = new About();
        let {
            _id,
            title,
            description
        } = data

        await AboutApi.updateAboutContent({
            _id,
            title,
            description
        }).then((data) => {
            if (data.success) {
                toast.success(data.msg.toString());
            } else {
                toast.error(data.msg.toString());
            }
        })
            .catch((error) => {
                toast.error(error.toString());
            });
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">Update About Edvantum Content</h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Title<span style={data && data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text" name="title" onChange={handleChanges} value={data && data.title ? data.title : ''} required />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Description<span style={data && data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control as="textarea" rows={5} name="description" onChange={handleChanges} value={data && data.description ? data.description : ''} required />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5, float: 'right' }}>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default UpdateAbout;