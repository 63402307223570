import React from "react";
import { CSVLink } from "react-csv";

const ExportActivitySchools = ({activitySchoolData=null}) => {
    function downloadActivitySchoolsCSVFormat() {
        let headers = [
            {label:"Activity School",key:"schoolName"},
            {label:"Location",key:"location"},
            {label:"Contact",key:"contact"},
            {label:"Mobile",key:"mobile"},
            {label:"Coaches",key:"coaches"}
          ];
          return (
            <CSVLink
              data={activitySchoolData}
              filename={"Activity_Schools.csv"}
              headers={headers}
            >
            <i className="mdi mdi-download"></i> Export Data
            </CSVLink>
          );
    }

    return (
      <div>{downloadActivitySchoolsCSVFormat()}</div>
		);

}

export default ExportActivitySchools;