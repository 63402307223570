import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import ActivityClass from "../../../api/activityClassApi";
import Grid from "../../../components/dataTable";
import DeleteModal from "../../../components/deleteAlertPopup";
import Loader from "../../../components/loader";

const ManageActivities = () => {
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    rowPropsId: "",
    _id: "",
    className: "",
  });

  const [rows, setRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  // ======================================= coding part =======================================

  const getList = async () => {
    setDataLoader(true);
    let ActivityApi = new ActivityClass();
    await ActivityApi.getActivityClassList()
      .then(async (response) => {
        console.log(response.data)
        if (response.data && response.data.length) {
          setRows(response.data);
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const handleChanges = (event) => {
    let c = { ...data };
    const { name, value } = event.target;
    if (name == "className") {
      c.className = value;
    }
    setData(c);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let ActivityApi = new ActivityClass();
    let { _id, className } = data;

    if (_id) {
      await ActivityApi.editActivityClass({
        _id,
        className,
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList();
          } else {
            toast.error(data.msg.toString());
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    } else {
      await ActivityApi.createActivityClass({
        className
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList();
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    }
  };

  const cleanFields = () => {
    let d = { ...data };
    d._id = "";
    d.className = "";
    setData(d);
  };

  const editActivity = (props) => {
    let c = { ...data };
    c._id = props._id;
    c.className = props.className;
    setData(c);
  };

  const deleteClass = async () => {
    let ActivityApi = new ActivityClass();
    isVisible2();
    await ActivityApi.deleteActivityClass(data.rowPropsId)
      .then((response) => {
        // console.log(response);
        toast.success(response.msg.toString());
      })
      .catch((error) => {
        console.error("There is ERROR!", error);
        toast.error(error.toString());
      });
    getList();
  };
  // ========================/ coding part /=====================

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="Edit"
          onClick={() => editActivity(props)}
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>

        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>

        <button
          data-tip
          data-for="Delete"
          onClick={() => isVisible2(props._id)}
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      // console.log(id)
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  };

  const field = () => {
    const fields = [
      {
        title: "Activities Classes",
        field: "className",
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Manages Activities Classes </h3>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit} className="forms-sample">
                <div className="row">
                  <div className="col-md-9">
                    <Form.Group className="row">
                      <label className="col-sm-2 col-form-label">
                        ClassName<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="text"
                          required={true}
                          name="className"
                          onChange={handleChanges}
                          value={data.className}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <button
                      type="submit"
                      className="btn btn-primary ml-2 btn-fw"
                      style={{ lineHeight: 1.5 }}
                    >
                      {data._id ? "Update" : "Add"}
                    </button>
                  </div>
                </div>

                {/* ========================================================== */}
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <Grid columns={field()} rows={rows} />
          </div>
        </div>
        <DeleteModal
          show={isModalVisible2}
          isModalVisible2={isVisible2}
          deleteData={deleteClass}
        />
      </div>
    </div>
  );
};

export default ManageActivities;
