import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { StudentFields } from "./StudentFields";
import activitySchoolApi from "../../../api/activitySchoolApi";
import ActivityStudent from "../../../api/activityStudentApi";
import ActivityApi from "../../../api/activityApi";
import SchoolApi from "../../../api/schoolApi";
import ActivityClass from "../../../api/activityClassApi";
import { toast } from "react-toastify";
import Datetime from 'react-datetime';
import moment from "moment";

const AddStudent = () => {
  let history = useHistory();

  const [studentFormData, setStudentFormData] = useState(StudentFields);
  const [fileProgress, setFileProgress] = useState({});
  const [schoolList, setSchoolList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [activities, setActivities] = useState([]);

  const id = new URLSearchParams(history.location.search).get("id");

  useEffect(() => {
    if (id) {
      getActivityStudent(id);
    } else {
      clearForm();
    }
  }, []);

  useEffect(() => {
    getActivities();
    getClassList();
    getSchoolList();
  }, []);

  async function uploadLogo(value) {
    let schoolApi = new SchoolApi();
    const data = new FormData();
    data.append("file", value[0]);
    await schoolApi
      .uploadFile(data, (upload) => {
        let pg = { ...fileProgress };
        pg["file"] = Math.round((100 * upload.loaded) / upload.total);
        setFileProgress(pg);
      })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let schoolForm = { ...studentFormData };
          schoolForm.photoUrl = data.fileUrl;
          setStudentFormData(schoolForm);
          toast.success(data.msg);
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  }

  async function submitHandler(e) {
    e.preventDefault();
    let ActivityStudentApi = new ActivityStudent();

    if (studentFormData._id) {
      await ActivityStudentApi.updateActivityStudent({ studentFormData })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            clearForm();
            history.push("/manageactivityschool/studentlist");
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    }
  }

  function clearForm() {
    let schoolForm = { ...studentFormData };
    schoolForm._id = "";
    schoolForm.firstName = "";
    schoolForm.lastName = "";
    schoolForm.dob = "";
    schoolForm.gender = "";
    schoolForm.photoUrl = "";
    schoolForm.mobile = "";

    schoolForm.schoolId = "";
    schoolForm.classId = "";
    schoolForm.sportsActivityIds = "";

    schoolForm.address.state = "Punjab";
    schoolForm.address.country = "India";
    schoolForm.address.city = "";
    schoolForm.address.pinCode = "";
    schoolForm.address.address = "";

    setStudentFormData(schoolForm);
  }

  function onChange(e) {
    let schoolForm = { ...studentFormData };
    let { name, value } = e.target;
    switch (name) {
      case "firstName":
        schoolForm.firstName = value;
        break;
      case "lastName":
        schoolForm.lastName = value;
        break;
      case "dob":
        schoolForm.dob = value;
        break;
      case "gender":
        schoolForm.gender = value;
        break;
      case "mobile":
        schoolForm.mobile = value;
        break;
      case "schoolId":
        schoolForm.schoolId = value;
        break;
      case "className":
        schoolForm.className = value;
        break;
      case "ActivityId":
        schoolForm.sportsActivityIds = value;
        break;
      // address
      case "country":
        schoolForm.address.country = value;
        break;
      case "state":
        schoolForm.address.state = value;
        break;
      case "city":
        schoolForm.address.city = value;
        break;
      case "pinCode":
        schoolForm.address.pinCode = value;
        break;
      case "address":
        schoolForm.address.address = value;
        break;

      // Parent fields
      case "PFirstName":
        schoolForm.ParentsDetail.firstName = value;
        break;
      case "pLastName":
        schoolForm.ParentsDetail.lastName = value;
        break;
      default:
      // code block
    }
    setStudentFormData(schoolForm);
  }

  async function getActivityStudent(id) {
    let activityStudent = new ActivityStudent();
    await activityStudent
      .getActivityStudentById(id)
      .then((data) => {
        if (data.success) {
          setStudentFormData(data.data);
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  const getSchoolList = async () => {
    let sLIst = new activitySchoolApi();
    await sLIst.getActivitySchoolList().then(async (response) => {
      if (response.data && response.data.length) {
        setSchoolList(response.data);
      } else {
        setSchoolList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getClassList = async () => {
    let cLIst = new ActivityClass();
    await cLIst.getActivityClassList().then(async (response) => {
      if (response.data && response.data.length) {
        setClassList(response.data);
      } else {
        setClassList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  async function getActivities() {
    let alist = new ActivityApi();
    await alist
      .getActivities()
      .then((data) => {
        if (data.success) {
          setActivities(data.data);
        } else {
          setActivities([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <div className="page-header">
        <h3 className="page-title">Student</h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Student Info</h4>
              <form
                className="forms-sample"
                name="reset"
                onSubmit={(e) => submitHandler(e)}
              >

                {/* ==================== Student Detail ================ */}

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        First Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          required={true}
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          onChange={(e) => onChange(e)}
                          value={studentFormData.firstName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        LastName
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          required={true}
                          className="form-control"
                          placeholder="Last Name"
                          name="lastName"
                          onChange={(e) => onChange(e)}
                          value={studentFormData.lastName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Date of Birth</label>
                      <div className="col-sm-9">
                        <Datetime
                          placeholder="dob"
                          dateFormat={true}
                          timeFormat={false}
                          utc={true}
                          onChange={(e) => onChange({ target: { name: "dob", value: moment(e) } })}
                          value={moment(studentFormData.dob)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Mobile No.
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="mobile"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.mobile}
                          pattern="[0-9]{4,12}"
                          title="Enter vaild mobile number"
                        />
                      </div>
                    </Form.Group>
                  </div>

                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">School <span style={{ color: "red", fontSize: 14 }}>*</span></label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="schoolId"
                          placeholder="School"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.schoolId}
                        >
                          {
                            schoolList.map((e) => {
                              return (
                                <option key={e._id} value={e._id}>{e.schoolName}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Class <span style={{ color: "red", fontSize: 14 }}>*</span></label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="className"
                          placeholder="Class"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.className}
                        >
                          {
                            classList.map((e) => {
                              return (
                                <option key={e.className} value={e.className}>{e.className}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Gender<span style={{ color: "red", fontSize: 14 }}>*</span></label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="gender"
                          placeholder="Gender"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.gender}
                        >
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Upload Image<span style={{ color: "red", fontSize: 14 }}>*</span></label>
                      <div className="col-sm-9">

                        <div className="row">
                          <div className="col-md-10">
                            <div className="custom-file">
                              <Form.Control
                                type="file"
                                className="form-control visibility-hidden"
                                id="customFileLang"
                                lang="es"
                                onChange={(e) => {
                                  uploadLogo(e.target.files);
                                }}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileLang"
                              >
                                {studentFormData.photoUrl
                                  ? studentFormData.photoUrl
                                  : "Upload Image"}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-2">
                            {studentFormData.photoUrl ? (
                              <img
                                src={studentFormData.photoUrl}
                                style={{ width: 70 }}
                                alt="img"
                                loading="lazy"
                              />
                            ) : (
                              <img
                                src={require("../../../assets/images/dummy.png")}
                                style={{ width: 70 }}
                                alt="dummy"
                                loading="lazy"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* ==================== Student Detail ================ */}
                {/* ==================== address ================ */}
                <h4 className="card-title">Address</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Country
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="country"
                          onChange={(e) => onChange(e)}
                          value={studentFormData.address && studentFormData.address.country ? studentFormData.address.country : ""}
                        // required={true}
                        >
                          <option>India</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        State
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="state"
                          onChange={(e) => onChange(e)}
                          value={studentFormData.address && studentFormData.address.state ? studentFormData.address.state : ""}
                        // required={true}
                        >
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        City
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="City"
                          name="city"
                          // required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.address && studentFormData.address.city ? studentFormData.address.city : ""}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Pin code
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Pin code"
                          name="pinCode"
                          // required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.address && studentFormData.address.pinCode ? studentFormData.address.pinCode : ""}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3" htmlFor="exampleTextarea1">
                        Address
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          rows="4"
                          name="address"
                          // required={true}
                          onChange={(e) => onChange(e)}
                          value={studentFormData.address && studentFormData.address.address ? studentFormData.address.address : ""}
                        ></textarea>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ==================== address ================ */}
                {/* ======================= Parent Info ============================ */}
                <h4 className="card-title">Parent Info</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        First Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="PFirstName"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            studentFormData.ParentsDetail &&
                            studentFormData.ParentsDetail.firstName
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Last Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="pLastName"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            studentFormData.ParentsDetail &&
                            studentFormData.ParentsDetail.lastName
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ======================== Parent Info =============================== */}
                <button type="submit" className="btn btn-primary mr-2">
                  {studentFormData._id ? "Update" : "Submit"}
                </button>
                <Link
                  to="/manageactivityschool/studentlist"
                  className="btn btn-light"
                >
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudent;