import BaseApi from './baseApi';

class StaffMember extends BaseApi {

    async getStaffMembers() {
        return await this.API(
            {
                url: "/staffmember/getstaffmembers",
                method: "get"
            }
        )
    }

    async createStaffMember(data) {
        return await this.API(
            {
                url: "/staffmember/createstaff",
                data: data,
                method: "post"
            }
        )
    }

    async editStaffMember(data) {
        return await this.API(
            {
                url: "/staffmember/updatestaff",
                data: data,
                method: "post"
            }
        )
    }

    async deleteStaffMember(data) {
        return await this.API(
            {
                url: "/staffmember/deletestaff",
                data: { _id: data },
                method: "post"
            }
        )
    }

    async getStaffMember(data) {
        return await this.API(
            {
                url: "/staffmember/getstaff/" + data,
                method: "get"
            }
        )
    }

    async getStaffMemberByMobile(data) {
        console.log("data",data);
        return await this.API(
            {
                url: "/staffmember/getstaffmemberbymobile",
                data: data,
                method: "post"
            }
        )
    }

    async activateStaffMember(data) {
        return await this.API(
            {
                url: "/staffmember/activatestaff",
                data: data,
                method: "post"
            }
        )
    }

    async getStaffMembersByRole(role) {
        return await this.API(
            {
                url: "/staffmember/getstaffmembersbyrole",
                method: "get",
                params: { role: role }
            }
        )
    }

    async getAllSchoolMembers() {
        return await this.API(
            {
                url: "/staffmember/getallschoolmembers",
                method: "get"
            }
        )
    }

    async getSchoolMember(_id) {
        return await this.API(
            {
                url: '/staffmember/getschoolmember/' + _id,
                method: 'get'
            }
        )
    }

    async save(data) {
        if (data && data._id) {
            return await this.API(
                {
                    url: '/staffmember/updatestaffmemberaccess/' + data._id,
                    data: data,
                    method: 'put'
                }
            )
        } else {
            return await this.API(
                {
                    url: '/staffmember/createstaffmemberaccess',
                    data: data,
                    method: 'post',
                }
            );
        }
    }

    async saveStaffCsv(data) {
        return await this.API(
            {
                url: '/staffmember/savestaffcsv',
                data: data,
                method: 'post'
            }
        )
    }

    async getStaffAttendance(date, sortColName, sort, sreachKeyword) {
        return await this.API(
            {
                url: '/staffattendance/getstaffattendancedetails',
                params: { date:date, sortColName:sortColName, sort:sort, sreachKeyword:sreachKeyword },
                method: 'get',
            }
        )
    }
}

export default StaffMember;