import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Form, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import StudentFee from "../../api/studentFeeApi";
import CSVERRORDATAModal from "../csvDownloadPopup";
import Loader from "../loader";
const StudentFeeData = () => {
  const [isChooseFileDisplay, setIsChooseFileDisplay] = useState(false);
  const [IsRulesShow, setIsRulesShow] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  let headers = [
    { label: "Admission No", key: "admissionNo" },
    { label: "Class Name", key: "className" },
    { label: "Section", key: "section" },
    { label: "Concession Type", key: "concessionType" },
    { label: "Schedule Months", key: "scheduleMonths" },
    { label: "Fee Months", key: "feeForMonths" },
    { label: "Admission Fees", key: "admissionFees" },
    { label: "Annual Fees", key: "annualFees" },
    { label: "Tuition Fees", key: "tuitionFees" },
    { label: "Fee Session", key: "feeForSession" },
    { label: "Concession Amount", key: "concessionAmount" },
    { label: "Paid Admission Fees", key: "paidAdmissionFees" },
    { label: "Paid Annual Fees", key: "paidAnnualFees" },
    { label: "Paid Tuition Fees", key: "paidTuitionFees" },
    { label: "Late Fee Amount", key: "lateFeeAmount" },
    { label: "Payment Mode", key: "paymentMode" },
    { label: "Bank Name", key: "bankName" },
    { label: "Branch Name", key: "branchName" },
    { label: "Cheque Number", key: "chequeNumber" },
    { label: "Card Number", key: "cardNumber" },
    { label: "Cheque Status", key: "chequeStatus" },
    { label: "Payment Date", key: "paymentDate" },
    { label: "Release Date", key: "releaseDate" },
    { label: "Receipt Pdf Url", key: "feeReceiptPdfUrl" },
    { label: "Error", key: "error" },
  ];
  function downloadStudentFeeDataCSVFormat() {
    let headers = [
      { label: "Admission No", key: "admissionNo" },
      { label: "Class Name", key: "className" },
      { label: "Section", key: "section" },
      { label: "Concession Type", key: "concessionType" },
      { label: "Schedule Months", key: "scheduleMonths" },
      { label: "Fee Months", key: "feeForMonths" },
      { label: "Admission Fees", key: "admissionfees" },
      { label: "Annual Fees", key: "annualfees" },
      { label: "Tuition Fees", key: "tuitionfees" },
      { label: "Fee Session", key: "feeForSession" },
      { label: "Concession Amount", key: "concessionAmount" },
      { label: "Paid Admission Fees", key: "paidadmissionfees" },
      { label: "Paid Annual Fees", key: "paidannualfees" },
      { label: "Paid Tuition Fees", key: "paidtuitionfees" },
      { label: "Late Fee Amount", key: "lateFeeAmount" },
      { label: "Payment Mode", key: "paymentMode" },
      { label: "Bank Name", key: "bankName" },
      { label: "Branch Name", key: "branchName" },
      { label: "Cheque Number", key: "chequeNumber" },
      { label: "Card Number", key: "cardNumber" },
      { label: "Cheque Status", key: "chequeStatus" },
      { label: "Payment Date", key: "paymentDate" },
      { label: "Release Date", key: "releaseDate" },
      { label: "Receipt Pdf Url", key: "feeReceiptPdfUrl" },
    ];

    function convert(num) {
      if (typeof num !== "number") return false;

      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ],
        roman_num = "",
        i = 3;
      while (i--) roman_num = (key[+digits.pop() + i * 10] || "") + roman_num;
      return Array(+digits.join("") + 1).join("M") + roman_num;
    }

    return (
      <Modal show={IsRulesShow} onHide={() => setIsRulesShow(false)}>
        <Modal.Header style={{ backgroundColor: "#af0000" }} closeButton>
          <Modal.Title style={{ color: "beige" }}>Warning!</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            minHeight: "133px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <ul className="list-group list-group-flush">
            <li className="list-group-item text-left">
              First upload the Classes data , Students data & Fee Data then
              upload the Student Fee Data.
            </li>
            <li className="list-group-item text-left">
              Please insert School Fee Details with Title & Value after Fee
              Months Column.
            </li>
            <li className="list-group-item text-left">
              Please insert Paid Fees Details with Title & Value after
              Concession Amount Column.
            </li>
            <li className="list-group-item text-left">
              Any Date format should be MM-DD-YYYY
            </li>
            <li className="list-group-item text-left">
              At one time maximum 2000 records can be uploaded
            </li>
          </ul>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "white" }}>
          <CSVLink
            data={[
              {
                admissionNo: "4217",
                className: "V",
                section: "A",
                concessionType: "Forpoor",
                scheduleMonths: "4,5,6",
                feeForMonths: "4,5,6",
                admissionfees: "3000",
                annualfees: "5000",
                tuitionfees: "1000",
                feeForSession: "2012",
                concessionAmount: "500",
                paidadmissionfees: "4000",
                paidannualfees: "4000",
                paidtuitionfees: "1000",
                lateFeeAmount: "1000",
                paymentMode: "Cheque",
                bankName: "SBI",
                branchName: "Mohali",
                chequeNumber: "444444444444",
                chequeStatus: "Active",
                cardNumber: "444444444444",
                paymentDate: "12-12-2007",
                releaseDate: "12-09-2010",
                feeReceiptPdfUrl: "http://localhost:3000/studentsfees",
              },
            ]}
            filename={"School_Sample_Student_Fees_Import.csv"}
            headers={headers}
          >
            (Download Sample CSV)
          </CSVLink>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setIsRulesShow(false)}
            style={{ padding: "16px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function uploadCsvFile(feeData) {
    if (feeData.length > 2001) {
      toast.error("At one time maximum 2000 records can be uploaded");
      return;
    }
    let admissionColumnIndex = -1;
    let classNameColumnIndex = -1;
    let sectionColumnIndex = -1;
    let concessionTypeColumnIndex = -1;
    let scheduleMonthsColumnIndex = -1;
    let feeMonthsColumnIndex = -1;
    let sessionColumnIndex = -1;
    let concessionAmountColumnIndex = -1;
    let lateFeeAmountColumnIndex = -1;
    let paymentModeColumnIndex = -1;
    let bankNameColumnIndex = -1;
    let branchNameColumnIndex = -1;
    let chequeNumberColumnIndex = -1;
    let cardNumberColumnIndex = -1;
    let chequeStatusColumnIndex = -1;
    let paymentDateColumnIndex = -1;
    let releaseDateColumnIndex = -1;
    let feeReceiptPdfUrlColumnIndex = -1;
    let admissionFeesColumnIndex = -1;
    let annualFeesColumnIndex = -1;
    let tuitionFeesColumnIndex = -1;
    let imported = [];
    let firstRowIndex = -1;
    let admissions = [];
    let classes = [];
    let concessionTypes = [];
    feeData.every(async (row, index) => {
      let paymentDetails = {};
      let totalAmount = 0;
      let paidAmount = 0;
      let schoolFeeDetails = [];
      let paidFeeDetails = [];
      if (admissionColumnIndex == -1 && row.indexOf("Admission No") > -1) {
        admissionColumnIndex = row.indexOf("Admission No");
      }

      if (
        admissionFeesColumnIndex == -1 &&
        row.indexOf("Admission Fees") > -1
      ) {
        admissionFeesColumnIndex = row.indexOf("Admission Fees");
      }

      if (annualFeesColumnIndex == -1 && row.indexOf("Annual Fees") > -1) {
        annualFeesColumnIndex = row.indexOf("Annual Fees");
      }

      if (tuitionFeesColumnIndex == -1 && row.indexOf("Tuition Fees") > -1) {
        tuitionFeesColumnIndex = row.indexOf("Tuition Fees");
      }

      if (classNameColumnIndex == -1 && row.indexOf("Class Name") > -1) {
        classNameColumnIndex = row.indexOf("Class Name");
      }

      if (sectionColumnIndex == -1 && row.indexOf("Section") > -1) {
        sectionColumnIndex = row.indexOf("Section");
      }

      if (
        concessionTypeColumnIndex == -1 &&
        row.indexOf("Concession Type") > -1
      ) {
        concessionTypeColumnIndex = row.indexOf("Concession Type");
      }
      if (feeMonthsColumnIndex == -1 && row.indexOf("Fee Months") > -1) {
        feeMonthsColumnIndex = row.indexOf("Fee Months");
      }
      if (sessionColumnIndex == -1 && row.indexOf("Fee Session") > -1) {
        sessionColumnIndex = row.indexOf("Fee Session");
      }
      if (
        concessionAmountColumnIndex == -1 &&
        row.indexOf("Concession Amount") > -1
      ) {
        concessionAmountColumnIndex = row.indexOf("Concession Amount");
      }
      if (
        lateFeeAmountColumnIndex == -1 &&
        row.indexOf("Late Fee Amount") > -1
      ) {
        lateFeeAmountColumnIndex = row.indexOf("Late Fee Amount");
      }
      if (paymentModeColumnIndex == -1 && row.indexOf("Payment Mode") > -1) {
        paymentModeColumnIndex = row.indexOf("Payment Mode");
      }

      if (
        scheduleMonthsColumnIndex == -1 &&
        row.indexOf("Schedule Months") > -1
      ) {
        scheduleMonthsColumnIndex = row.indexOf("Schedule Months");
      }

      if (bankNameColumnIndex == -1 && row.indexOf("Bank Name") > -1) {
        bankNameColumnIndex = row.indexOf("Bank Name");
      }
      if (branchNameColumnIndex == -1 && row.indexOf("Branch Name") > -1) {
        branchNameColumnIndex = row.indexOf("Branch Name");
      }
      if (chequeNumberColumnIndex == -1 && row.indexOf("Cheque Number") > -1) {
        chequeNumberColumnIndex = row.indexOf("Cheque Number");
      }
      if (chequeStatusColumnIndex == -1 && row.indexOf("Cheque Status") > -1) {
        chequeStatusColumnIndex = row.indexOf("Cheque Status");
      }

      if (cardNumberColumnIndex == -1 && row.indexOf("Card Number") > -1) {
        cardNumberColumnIndex = row.indexOf("Card Number");
      }

      if (paymentDateColumnIndex == -1 && row.indexOf("Payment Date") > -1) {
        paymentDateColumnIndex = row.indexOf("Payment Date");
      }

      if (releaseDateColumnIndex == -1 && row.indexOf("Release Date") > -1) {
        releaseDateColumnIndex = row.indexOf("Release Date");
      }

      if (
        feeReceiptPdfUrlColumnIndex == -1 &&
        row.indexOf("Receipt Pdf Url") > -1
      ) {
        feeReceiptPdfUrlColumnIndex = row.indexOf("Receipt Pdf Url");
      }
      let oneTimePayment = 0;
      let oneTimePaidPayment = 0;
      row.forEach((column, columnIndex) => {
        if (
          columnIndex > feeMonthsColumnIndex &&
          columnIndex < sessionColumnIndex
        ) {
          let obj = {};
          if (index >= firstRowIndex) {
            if (feeData[0][columnIndex] == "Admission Fees") {
              oneTimePayment += parseInt(row[columnIndex]);
            } else if (feeData[0][columnIndex] == "Annual Fees") {
              oneTimePayment += parseInt(row[columnIndex]);
            } else {
              totalAmount += parseInt(row[columnIndex]);
            }
            obj["particular"] = feeData[0][columnIndex];
            obj["amount"] = row[columnIndex];
            schoolFeeDetails.push(obj);
          }
        }
        if (
          columnIndex > concessionAmountColumnIndex &&
          columnIndex < lateFeeAmountColumnIndex
        ) {
          let obj = {};
          if (index >= firstRowIndex) {
            if (feeData[0][columnIndex] == "Paid Admission Fees") {
              oneTimePaidPayment += parseInt(row[columnIndex]);
            } else if (feeData[0][columnIndex] == "Paid Annual Fees") {
              oneTimePaidPayment += parseInt(row[columnIndex]);
            } else {
              paidAmount += parseInt(row[columnIndex]);
            }
            obj["particular"] = feeData[0][columnIndex];
            obj["amount"] = row[columnIndex];
            paidFeeDetails.push(obj);
          }
        }
      });

      if (firstRowIndex === -1) {
        firstRowIndex = index + 1;
      }

      if (index >= firstRowIndex) {
        let admissionNo = row[admissionColumnIndex];
        let className = row[classNameColumnIndex];
        let section = row[sectionColumnIndex];
        let concessionType = row[concessionTypeColumnIndex];
        let feeForMonths = row[feeMonthsColumnIndex];
        let feeForSession = row[sessionColumnIndex];
        let concessionAmount = row[concessionAmountColumnIndex];
        let lateFeeAmount = row[lateFeeAmountColumnIndex];
        let scheduleMonths = row[scheduleMonthsColumnIndex];
        let annualFee = row[annualFeesColumnIndex];
        let admissionFee = row[admissionFeesColumnIndex];
        let scheduleTotalAmount = 0;
        if (scheduleMonths) {
          let schArray = [];
          let scheduleArray = scheduleMonths.split(",");
          scheduleArray.forEach((fee) => {
            schArray.push(parseInt(fee));
          });
          scheduleMonths = "[" + schArray + "]";
          let scheduleTotal = schArray.length * totalAmount;
          scheduleTotal = parseInt(oneTimePayment) + parseInt(scheduleTotal);
          scheduleTotalAmount = scheduleTotal;

          totalAmount = scheduleMonths.length * totalAmount;
          totalAmount = parseInt(totalAmount) - parseInt(concessionAmount);
          totalAmount = parseInt(oneTimePayment) + parseInt(totalAmount);
          totalAmount = parseInt(totalAmount) + parseInt(lateFeeAmount);
        }

        if (feeForMonths) {
          let feeArray = [];
          let feeForArray = feeForMonths.split(",");
          feeForArray.forEach((fee) => {
            feeArray.push(parseInt(fee));
          });
          feeForMonths = "[" + feeArray + "]";
          let paidTotal = feeArray.length * paidAmount;
          paidTotal = parseInt(paidTotal) - parseInt(concessionAmount);
          paidTotal = parseInt(oneTimePaidPayment) + parseInt(paidTotal);
          paidTotal = parseInt(paidTotal) + parseInt(lateFeeAmount);
          paidAmount = parseInt(paidTotal);
        }

        let paymentDate = row[paymentDateColumnIndex];
        let releaseDate = row[releaseDateColumnIndex];
        let feeReceiptPdfUrl = row[feeReceiptPdfUrlColumnIndex];
        paymentDetails["paymentMode"] = row[paymentModeColumnIndex];
        paymentDetails["bankName"] = row[bankNameColumnIndex];
        paymentDetails["branchName"] = row[branchNameColumnIndex];
        paymentDetails["chequeNumber"] = row[chequeNumberColumnIndex];
        paymentDetails["chequeStatus"] = row[chequeStatusColumnIndex];
        paymentDetails["cardNumber"] = row[cardNumberColumnIndex];
        admissions.push(admissionNo);
        classes.push(className);
        concessionTypes.push(concessionType);

        if (!className) {
          // blank row
          return false;
        }
        if (!className) {
          toast("Class Name is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          if (Number.isNaN(className)) {
            className = className.trim();
            className = className.toUpperCase();
          } else {
            var digits = String(+className).split(""),
              key = [
                "",
                "C",
                "CC",
                "CCC",
                "CD",
                "D",
                "DC",
                "DCC",
                "DCCC",
                "CM",
                "",
                "X",
                "XX",
                "XXX",
                "XL",
                "L",
                "LX",
                "LXX",
                "LXXX",
                "XC",
                "",
                "I",
                "II",
                "III",
                "IV",
                "V",
                "VI",
                "VII",
                "VIII",
                "IX",
              ],
              roman_num = "",
              i = 3;
            while (i--)
              roman_num = (key[+digits.pop() + i * 10] || "") + roman_num;
            className = Array(+digits.join("") + 1).join("M") + roman_num;
          }
        }
        if (!paymentDetails["paymentMode"]) {
          toast("Payment Mode is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          paymentDetails["paymentMode"] = paymentDetails["paymentMode"].trim();
        }

        if (!feeForSession) {
          let year = new Date().getFullYear();
          feeForSession = year;
        }

        imported.push({
          admissionNo,
          className,
          section,
          concessionType,
          scheduleTotalAmount,
          feeForMonths,
          schoolFeeDetails,
          paidFeeDetails,
          totalAmount,
          feeForSession,
          concessionAmount,
          lateFeeAmount,
          paymentDetails,
          paymentDate,
          paidAmount,
          releaseDate,
          feeReceiptPdfUrl,
        });
      }
    });
    let data = {
      admissions,
      classes,
      concessionTypes,
      studentsFees: imported,
    };
    saveFeeDataCsv(data);
  }

  async function saveFeeDataCsv(imported) {
    if (window.confirm("Are your sure for upload the fee data csv file ?")) {
      setIsLoader(true);
      let Api = new StudentFee();
      await Api.saveFeeDataCsv(imported)
        .then(async (response) => {
          if (response.success) {
            toast.success("Csv file is save successfully");
            if (response.data && response.data.length) {
              setIsModal(true);
              setErrorRecords(response.data);
            }
          } else if (response.error) {
            toast.error(response.msg.toString());
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There is an error!", error);
        });
      setIsLoader(false);
    }
  }

  function uploadStudentFeeDataCsv() {
    return (
      <CSVReader
        onFileLoaded={(studentsData) => uploadCsvFile(studentsData)}
        inputStyle={{ fontSize: "12px" }}
      />
    );
  }
  async function isModal2() {
    setIsModal(!isModal);
  }
  return (
    <>
      <div className="col-md-4 textRight">
        <a href="javascript:void(0)" onClick={() => setIsChooseFileDisplay(!isChooseFileDisplay)}>
          <><i className="mdi mdi-apple-keyboard-caps"></i> Import Students Fee Data</>
        </a>
      </div>
      <CSVERRORDATAModal
        show={isModal}
        isModal={isModal2}
        filename={"Data_Error_Student_Fees_Import.csv"}
        headers={headers}
        errorRecords={errorRecords}
      />
      {isLoader ? <Loader /> : null}
      <div className="text-sm">
        {" "}
        <a href="javascript:void(0)" onClick={(e) => setIsRulesShow(true)}>
          (Download Sample CSV)
        </a>
      </div>
      <div className="col-md-4">
        {downloadStudentFeeDataCSVFormat()}
      </div>
      <div className="col-md-12 text-sm">
        <div>{isChooseFileDisplay ? uploadStudentFeeDataCsv() : null}</div>
      </div>
    </>
  );
};

export default StudentFeeData;
