import React, { useEffect, useState } from "react";
import Grid from "../../../components/dataTable";
import { Form } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import StudentFeeApi from "../../../api/studentFeeApi";
import moment from 'moment';
import Loader from "../../../components/loader";

const ChequeStatus = () => {
    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: "red",
            fontSize: "14px",
        },
        tabstyle: {
            border: "1px solid #333",
            borderRadius: "12px",
            padding: "2px 5px",
            margin: "5px",
            color: "#333",
            fontSize: "13px",
        },
        tabCrossIconStyle: {
            fontSize: "15px",
            color: "#333",
        },
        _id: ''
    });
    const [rows, setRows] = useState([]);
		const [dataLoader, setDataLoader] = useState(false);
    useEffect(() => {
        getChequesList();
    }, []);

    const [chequeForm, setChequeForm] = useState({
        chequeNumber: '',
        releaseDate: '',
        chequeStatus: ''
    })

    const getChequesList = async () => {
				setDataLoader(true);
        const studentFeeApi = new StudentFeeApi();
        await studentFeeApi.getStudentFeeChequesList()
        .then(async (response) => {
            // console.log(response.data);
            if (response.data && response.data.length) {
                setRows(response.data);
            } else {
                setRows([]);
            }
        })
        .catch((error) => {
            toast.error(error.toString());
            console.error('There is an error!', error);
        });
				setDataLoader(false);
    };

    const renderAction = (props) => {
        return (
            <span>
                <ReactTooltip id="Edit" type="warning" effect="solid">
                    <span>Edit</span>
                </ReactTooltip>
                <button
                    onClick={() => editChequeForm(props)}
                    data-tip
                    data-for="Edit"
                    type="button"
                    className="btn btn-outline-warning"
                    style={{ padding: "8px" }}
                >
                    <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
                </button>
            </span>
        );
    };

    const showChequeStatus = (props) => {
        return <span>{props.chequeStatus ? props.chequeStatus : 'open'}</span>;
    }

    const showMomentReleaseDate = (props) => {
        if (props.releaseDate) {
            return <span>{moment(props.releaseDate).format('DD-MM-YYYY')}</span>;
        } else {
            return <span></span>;
        }
    }

    const field = () => {
        const fields = [
            {
                title: 'Cheque No.',
                field: 'chequeNumber',
                width: "0%",
            },
            {
                title: 'Student Name',
                field: 'studentName',
                width: "0%",
            },
            {
                title: 'Class Name',
                field: 'className',
                width: "0%",
            },
            {
                title: 'Status',
                render: showChequeStatus
            },
            {
                title: 'Release Date',
                render: showMomentReleaseDate
            },
            {
                name: 'action',
                title: 'Actions ',
                width: '0%',
                render: renderAction,
            }
        ];
        return fields;
    };

    // ================================== coding part ==================================

    const handleChanges = (event) => {
        const c = { ...chequeForm };
        const { name, value } = event.target;
        if (name === 'chequeNumber') {
            c.chequeNumber = value;
        } else if (name === 'releaseDate') {
            c.releaseDate = value;
        } else if (name === 'chequeStatus') {
            c.chequeStatus = value;
        }
        setChequeForm(c);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const studentFeeApi = new StudentFeeApi();
        if (chequeForm._id) {
            await studentFeeApi.updateStudentFeeChequePayment(chequeForm)
            .then((data) => {
                if (data.success) {
                    toast.success(data.msg.toString());
                    cleanFields();
                    getChequesList();
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                toast.error(error.toString());
                console.error('There was an error!', error);
            });
        } else {
            alert('Id not selected')
        }
    };

    const cleanFields = () => {
        let c = { ...chequeForm };
        c._id = '';
        c.chequeNumber = '';
        c.releaseDate = '';
        c.chequeStatus = '';
        setChequeForm(c);
    };

    const editChequeForm = (props) => {

        const c = { ...chequeForm };
        c._id = props._id;
        c.chequeNumber = props.chequeNumber;
        c.releaseDate = moment(props.releaseDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
        if (props.chequeStatus) {
            c.chequeStatus = props.chequeStatus;
        } else {
            c.chequeStatus = 'open';
        }
        setChequeForm(c);
    };

    // ===============================/ coding part /===============================

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Cheque Status </h3>
            </div>
						{dataLoader ? <Loader /> : null}
            <div className="row">
                { chequeForm && chequeForm._id ?
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit} className="forms-sample">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="row">
                                                <label className="col-sm-3 col-form-label">Cheque No.: <span style={data.startstyle}>*</span></label>
                                                <div className="col-sm-9">
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        name="chequeNumber"
                                                        onChange={(e) => handleChanges(e)}
                                                        required={true}
                                                        value={chequeForm.chequeNumber}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="row">
                                                <label className="col-sm-3 col-form-label">Release Date: <span style={data.startstyle}>*</span></label>
                                                <div className="col-sm-9">
                                                    <Form.Control
                                                        type="date"
                                                        className="form-control"
                                                        name="releaseDate"
                                                        onChange={(e) => handleChanges(e)}
                                                        required={true}
                                                        value={chequeForm.releaseDate}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="row">
                                                <label className="col-sm-3 col-form-label">Cheque Status:<span style={data.startstyle}>*</span></label>
                                                <div className="col-sm-9">
                                                    <select
                                                        className="form-control"
                                                        name="chequeStatus"
                                                        onChange={(e) => handleChanges(e)}
                                                        required={true}
                                                        value={chequeForm.chequeStatus}
                                                    >
                                                        <option value="">--------</option>
                                                        <option>open</option>
                                                        <option>close</option>
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-9">
                                        </div>
                                        <div className="col-md-3">
                                            <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{lineHeight:1.5}}>Update</button>
                                        </div>
                                    </div>

                                    {/* ================================================= */}
                                </form>
                            </div>
                        </div>
                    </div>
                    : ''
                }

                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <nav aria-label="breadcrumb">
                            <div className="row">
                            </div>
                        </nav>
                        <Grid columns={field()} rows={rows} />
                    </div>
                </div>
            </div>
          {/* ================================= */}
          {/* ================================= */}
        </div>
   );
};

export default ChequeStatus;
