import React from "react";
import { CSVLink } from "react-csv";

const ExportAccessLevels = ({ accessLevels = null }) => {

  function downloadAccessLevelsCSVFormat() {
    let headers = [
      { label: "Role", key: "role" },
      { label: "Username", key: "username" },
    ];
    return (
      <CSVLink
        data={accessLevels}
        filename={"Access_Levels.csv"}
        headers={headers}
      >
        <i className="mdi mdi-download"></i>  Export Access Levels Data
      </CSVLink>
    );
  }

  return (
    <div className="">{downloadAccessLevelsCSVFormat()}</div>
  );

}

export default ExportAccessLevels;