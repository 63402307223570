import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
const Header = () => {
  const history = useHistory();
  let user = localStorage.getItem("user_info");
      user = JSON.parse(user);
  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user_info");
    history.push("/login");
  }
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div
        className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
        style={{ background: "#fff" }}
      >
      <div className="col-10">
        <Link className="navbar-brand brand-logo" to="/">
          <img
            src={require("../../assets/images/Edvantum.png")}
            style={{ height: "unset" }}
            alt="logo"
            loading="lazy"
          />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <span
            style={{
              background: "white",
              display: "block",
              margin: "10px",
              borderRadius: "12px",
              fontWeight: "bolder",
              fontSize: "21px",
            }}
          >
            E
          </span>
        </Link>
        </div>
        <div className="col-2 navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile nav-language">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">Super Admin</p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <div className="p-2">
                  <h5 className="dropdown-header text-uppercase pl-2 text-dark">
                    User Options
                  </h5>
                  <div role="separator" className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="justify-content-between align-items-center dropdown-item">
                    <Link className="nav-link" to="/changepassword">Change Password
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item d-flex align-items-center justify-content-between"
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                   <span onClick={() => logout()}>Log Out</span>
                    <i className="mdi mdi-logout ml-1"></i>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={(e) => toggleOffcanvas()}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};
export default Header;
