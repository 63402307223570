import BaseApi from "./baseApi";

class Holiday extends BaseApi {
    async getHoliday() {
        return await this.API(
            {
                url: "/holiday/getholidays",
                method:"get",
            }
        )
    }

    async createHoliday(data) {
        return await this.API(
            {
                url: "/holiday/createholiday",
                data: data,
                method:"post"
            }
        )
    }

    async editHoliday(data) {
        return await this.API(
            {
                url: "/holiday/updateholiday",
                data: data,
                method:"post"
            }
        )
    }

    async deleteHoliday(data) {
        return await this.API(
            {
                url: "/holiday/deleteholiday",
                data: { _id: data },
                method:"post"
            }
        )
    }

}

export default Holiday;