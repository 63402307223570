import React, { useEffect, useState } from "react";
import { Form, Image, Modal } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import StatusModal from "../../../components/alertPopup";
import Loader from "../../../components/loader";
import Grid from "../../../components/dataTable";
import DeleteModal from "../../../components/deleteAlertPopup";
import ExportActivityStudentCSV from "../../../components/export/activityStudent";
import activitySchoolApi from "../../../api/activitySchoolApi";
import ActivityStudentApi from "../../../api/activityStudentApi";
import ActivityApi from "../../../api/activityApi";
import CoachApi from "../../../api/coachApi";
import ClassApi from "../../../api/classApi";
import moment from "moment";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import ViewAttendanceModal from "./ViewAttendanceModal";

const mLocalizer = momentLocalizer(moment)

const ManageStudent = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    isModalVisible2: false,
    isModalVisible3: false,
    rowPropsId: "",
  });
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [archiveListActive, setArchiveListActive] = useState(false);
  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: "",
  });
  const [selectedUser, setSelectedUser] = useState({});
  const [searchData, setSearchData] = useState({
    school: "",
    coach: "",
    activity: "",
  });
  const [coachList, setCoachList] = useState([])
  const [schoolList, setSchoolList] = useState([])
  const [activities, setActivities] = useState([]);
  const [classList, setClassList] = useState([]);

  const [defaultDate, setDefaultDate] = useState(moment().format("YYYY, M"));
  const [totalAttendance, setTotalAttendance] = useState([])
  const [currentMonthAverage, setCurrentMonthAverage] = useState()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getList();
    getActivities();
    getCoachList();
    getSchoolList();
    getClassList();
  }, []);

  // ======================================= coding part =======================================
  const getCoachList = async () => {
    let clist = new CoachApi();
    await clist.getCoaches().then(async (response) => {
      if (response.data && response.data.length) {
        setCoachList(response.data);
      } else {
        setCoachList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getSchoolList = async () => {
    let sLIst = new activitySchoolApi();
    await sLIst.getActivitySchoolList().then(async (response) => {
      if (response.data && response.data.length) {
        setSchoolList(response.data);
      } else {
        setSchoolList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getClassList = async () => {
    let cLIst = new ClassApi();
    await cLIst.getDefaultClassesSettings().then(async (response) => {
      if (response.data.classes && response.data.classes.length) {
        setClassList(response.data.classes);
      } else {
        setClassList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  async function getActivities() {
    let alist = new ActivityApi();
    await alist
      .getActivities()
      .then((data) => {
        if (data.success) {
          setActivities(data.data);
        } else {
          setActivities([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const getList = async () => {
    let list = [];
    setDataLoader(true);
    let ActivityStudent = new ActivityStudentApi();
    await ActivityStudent.getActivityStudentList(searchData)
      .then(async (response) => {
        if (response.data && response.data.length) {
          await response.data.map((data) => {
            if (data.studentData.active && !archiveListActive) {
              list.push(data);
            } else if (!data.active && archiveListActive) {
              list.push(data);
            }
          });
          setRows(response.data);
          setSelectedRows(list);
        } else {

          setRows([]);
          setSelectedRows([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    setDataLoader(false);
  };

  const InactiveActionApi = async () => {
    let ActivityStudent = new ActivityStudentApi();
    let da = { _id: selectedUser._id, activate: !selectedUser.active };
    await ActivityStudent.activateOrDactivate(da)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    handleClose();
    await getList();

  };

  const deleteStudent = async () => {
    let ActivityStudent = new ActivityStudentApi();
    isVisible2();
    await ActivityStudent.deleteActivityStudent(data.rowPropsId)
      .then((response) => {
        toast.success(response.msg.toString());
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    getList();
  };

  const handleChanges = (e) => {
    let searchDataObj = { ...searchData };
    let { name, value } = e.target;

    switch (name) {
      case "school":
        searchDataObj.school = value;
        break;
      case "coach":
        searchDataObj.coach = value;
        break;
      case "activity":
        searchDataObj.activity = value;
        break;
    }
    setSearchData(searchDataObj);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getList();
  };

  // ===================================/ coding part /=======================================

  const checkArchive = async (archive) => {
    let list = [];
    setArchiveListActive(archive);
    if (rows) {
      rows.map((data) => {
        if (data.active && !archive) {
          list.push(data);
        } else if (!data.active && archive) {
          list.push(data);
        }
      });
    }
    setSelectedRows(list);
  };

  const viewStudentAttendance = async (id) => {
    const date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let monthYear = month + "-" + year
    let Api = new ActivityStudentApi();
    await Api.viewAttendaceMontlyCountByYear(id, monthYear).then(async (response) => {
      if (response.data && response.data.length) {
        setDefaultDate(moment(response.data[0].dateOfAttendance).format("YYYY, M, D"))
        let studentPresent = response.data.filter(data => {
          if (data.statusOfAttendance == "Present") {
            data["id"] = data._id;
            data["title"] = data.statusOfAttendance;
            data["allDay"] = true;
            data["start"] = data.dateOfAttendance;
            data["end"] = data.dateOfAttendance;
            return data
          }
        })
        const studentAbsent = response.data.filter(data => {
          if (data.statusOfAttendance == "Absent") {
            data["id"] = data._id;
            data["title"] = data.statusOfAttendance;
            data["allDay"] = true;
            data["start"] = data.dateOfAttendance;
            data["end"] = data.dateOfAttendance;
            return data
          }
        })
        let total = [...studentPresent, ...studentAbsent]
        setTotalAttendance(total);
      }
      handleShow();
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const renderPerformance = (props) => {
    let Performance = props.activityPerformancesData[0]?.activities[0]?.overallScore
    return (
      <span>{Performance ? (Performance + "%") : null}</span>
    )
  };

  const renderAttendance = (props) => {
    return (
      <button className='btn btn-outline-primary' onClick={() => viewStudentAttendance(props.activityStudentId)}>View</button>
    )
  };

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          onClick={() =>
            history.push(`/manageactivityschool/updatestudentdetails?id=${props.activityStudentId}`)
          }
          data-tip
          data-for="Edit"
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>
        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>
        <button
          onClick={() => {
            isVisible2(props.activityStudentId);
          }}
          data-tip
          data-for="Delete"
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  };

  const InactiveAction = (props, index) => {
    return (
      <span>
        <ReactTooltip
          id="activate"
          type={props.active ? "success" : "error"}
          effect="solid"
        >
          <span>Activate/Inactive</span>
        </ReactTooltip>
        <span
          data-tip
          data-for="activate"
          className={
            props.active
              ? "form-check form-check-success"
              : "form-check form-check-danger"
          }
          style={{ display: "inline-flex" }}
          onClick={() => {
            let d = { ...data };
            if (props.active) {
              setStatusMsg({
                msg: `Are you sure that you want to Deactivate ${props.firstName}`,
                buttonName: "Deactivate",
              });
            } else {
              setStatusMsg({
                msg: `Are you sure that you want to Activate ${props.firstName}`,
                buttonName: "Activate",
              });
            }
            d.isModalVisible3 = true;
            setData(d);
            setSelectedUser(props);
          }}
        >
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={props.active}
            />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    );
  };

  const handleClose = () => {
    let d = { ...data };
    d.selectedClass = "";
    d.isModalVisible2 = false;
    d.isModalVisible3 = false;
    setSelectedUser({});
    setStatusMsg({
      msg: "",
      buttonName: "",
    });
    setData(d);
    setShow(false);
  };

  const field = () => {
    const fields = [
      {
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: InactiveAction
      },
      {
        title: "FirstName",
        field: "studentData.firstName"
      },
      {
        title: "LastName",
        field: "studentData.lastName"
      },
      {
        title: "Class",
        field: "studentData.className"
      },
      {
        title: "Mobile No",
        field: "studentData.mobile"
      },
      {
        title: "Activity",
        field: "activityData.activityName"
      },
      {
        title: "Fee Status",
        field: "paymentStatus"
      },
      {
        title: "Performance",
        render: renderPerformance
      },
      {
        title: "Attendance",
        render: renderAttendance
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction
      },
    ];
    return fields;
  };

  return (
    <div>
      {dataLoader ? <Loader /> : null}
      <div className="page-header">
        <h3 className="page-title"> Manage Activity Students</h3>
      </div>

      <div className="row">
        {/* ============== Filter Section =============== */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={(e) => handleSearch(e)}>
                <h4 className="card-title">Search Student</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">School</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="school"
                          onChange={(e) => handleChanges(e)}
                          value={searchData.school || ''}
                        >
                          <option value="">------------</option>
                          {schoolList.map((e) => {
                            return (
                              <option key={e._id} value={e._id}>
                                {e.schoolName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Coach</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="coach"
                          onChange={(e) => handleChanges(e)}
                          value={searchData.coach || ''}
                        >
                          <option value="">------------</option>
                          {
                            coachList.map((e) => {
                              return (
                                <option key={e._id} value={e._id}>{e.firstName} {e.lastName}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Activity
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="activity"
                          onChange={(e) => handleChanges(e)}
                          value={searchData.activity || ''}
                        >
                          <option value="">------------</option>
                          {
                            activities.map((e) => {
                              return (
                                <option key={e._id} value={e._id}>{e.activityName}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ float: "right" }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* ============== Search Section =============== */}
        {/* <div className="col-6 mb-4">
          <div className="input-group">
            <div className="form-outline col-12">
              <input type="search" id="form1" className="form-control" placeholder="Search ..."
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    handleList(e, "search")
                  } else if (e.target.value.length == 0) {
                    setSreachKeyword("");
                  }
                }
                } />
            </div>
          </div>
        </div> */}

        {/* ============== Table Section =============== */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2" style={{ justifyContent: "flex-end" }}>
              <div className="col-md-5 export">
                <ExportActivityStudentCSV activityStudentData={selectedRows} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={async () => {
                    checkArchive(!archiveListActive);
                  }}
                  type="button"
                  className="btn btn-outline-primary float-right"
                  style={{ padding: "8px" }}
                >
                  {archiveListActive ? "Active List" : "Archive List"}
                </button>
              </div>
            </div>
            <Grid
              columns={field()}
              rows={selectedRows}
              settings={{
                search: true,
                sorting: true
              }} />
          </div>
        </div>
        {/* ========== delete model =========== */}
        <DeleteModal
          show={isModalVisible2}
          isModalVisible2={isVisible2}
          deleteData={deleteStudent}
        />
        {/* ========== Active/Inactive model =========== */}
        <StatusModal
          show={data.isModalVisible3}
          isModalVisible2={handleClose}
          deleteData={InactiveActionApi}
          body={statusMsg}
        />
      </div>
      {/* <div> View Attendance</div> */}
      <ViewAttendanceModal show={show} handleClose={handleClose} defaultDate={defaultDate} totalAttendance={totalAttendance} />
    </div>
  );
};

export default ManageStudent;
