import BaseApi from "./baseApi";

class Floor extends BaseApi {
    async getFloor() {
        return await this.API(
            {
                url: "/floor/getfloors",
                method:"get",
            }
        )
    }

    async createFloor(data) {
        return await this.API(
            {
                url: "/floor/createfloor",
                data: data,
                method:"post"
            }
        )
    }

    async editFloor(data) {
        return await this.API(
            {
                url: "/floor/updatefloor",
                data: data,
                method:"post"
            }
        )
    }

    async deleteFloor(data) {
        return await this.API(
            {
                url: "/floor/deletefloor",
                data: { _id: data },
                method:"post"
            }
        )
    }

}

export default Floor;