import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Loader from "../components/loader";
import { toast } from "react-toastify";
import AuthApi from "../api/authApi";
import StudentApi from "../api/studentApi";
import { Helmet } from 'react-helmet-async';

const Unsubscribe = () => {
    const history = useHistory();
    const [user, setUser] = useState({ mobile: "", otp: "" });
    const [isMobile, setIsMobile] = useState(false);
    const [isOTP, setIsOTP] = useState(false)
    const [showUserConsent, setShowUserConsent] = useState(false)
    const [userData, setUserData] = useState()
    const [showLoader, setShowLoader] = useState()

    function handleChange(event) {
        const { name, value } = event.target;
        let c = { ...user };
        if (name === "mobile") {
            c.mobile = value;
        } else if (name === 'otp') {
            c.otp = value
        }
        setUser(c);
    }

    async function checkMobile() {
        // Show loader
        setShowLoader(true);
        let authApi = new AuthApi();
        try {
            const res = await authApi.checkForParentMobileAndSendOTP(user.mobile);
            if (res.success && res.mobile) {
                setIsMobile(true);
                setIsOTP(true);
                toast.success(res.msg.toString());
                let element = document.getElementById("submitBtn")
                element.classList.remove("btn-block")
            } else if (res.error) {
                toast.error(res.msg.toString());
                let element = document.getElementById("submitBtn")
                element.classList.add("btn-block")
            }
        } catch (error) {
            toast.error(error.toString());
        } finally {
            // Hide loader
            setShowLoader(false);
        }
    }

    function hidePassOtpField() {
        let element = document.getElementById("submitBtn")
        element.classList.add("btn-block")
        setIsMobile(false)
        setIsOTP(false)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (isOTP) {
            let sendObj;
            if (isOTP) {
                sendObj = {
                    mobile: user.mobile,
                    otp: user.otp,
                    role: "parent"
                }
            }
            // Show loader
            setShowLoader(true);
            let authApi = new AuthApi();
            try {
                const res = await authApi.login(sendObj);
                if (res.success) {
                    localStorage.setItem("token", res.accessToken);
                    localStorage.setItem("schoolId", res.schools[0]._id);
                    setShowUserConsent(true);
                    setUserData(res);
                    toast.success(res.msg);
                } else {
                    toast.error(res.msg);
                }
            } catch (error) {
                toast.error(error.toString());
            } finally {
                // Hide loader
                setShowLoader(false);
            }
        } else {
            checkMobile();
        }
    }

    async function handleDataRemoval(event) {
        event.preventDefault();
        let studentApi = new StudentApi();
        let sendObj = {
            userId: userData.userId
        }
        await studentApi.removeUserData(sendObj).then((res) => {
            if (res.success) {
                setShowUserConsent(true);
                setUserData(res);
                toast.success(res.msg);
                history.push('/home')
            } else {
                toast.error(res.msg);
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    let dis = "Unsubscribe Parent";
    let keyword = "Parent, Unsubscribe";
    let tt = "EDVANTUM - Unsubscribe";
    return (
        <Fragment>
            <Helmet>
                <title>{tt}</title>
                <meta
                    name="description"
                    content={dis}
                />
                <meta
                    name="Keywords"
                    content={keyword}
                />
            </Helmet>
            <div style={{ "marginLeft": "130px" }}>
                {showLoader ? <Loader /> : null}
            </div>
            <div
                className="d-flex align-items-center auth px-0"
                style={{ height: "45pc" }}
            >
                <div className="row w-100 mx-0">
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                            <div className="brand-logo mb-3">
                                <Link to="/home">
                                    <img
                                        src={require("../assets/images/Edvantum.png")}
                                        alt="logo"
                                        style={{ width: "200px" }}
                                        loading="lazy"
                                    />
                                </Link>
                            </div>
                            <h4>Unsubscribe</h4>
                            <b>We will delete your data, including your Mobile, Email, Birthdate, Gender, Password, Photo, Bio, Chats, Messages, Notifications etc.</b>
                            {showUserConsent ?
                                <div>
                                    <h5>Are you sure, you want to remove your data ?</h5>
                                    <div className="button-container">
                                        <button className="btn btn-success" onClick={(e) => handleDataRemoval(e)}>Yes</button>
                                        <button className="btn btn-danger" onClick={() => history.push('/home')}>No</button>
                                    </div>
                                </div>
                                :
                                <Form className="pt-3" onSubmit={(e) => handleSubmit(e)}>
                                    <Form.Group className="d-flex search-field">
                                        <Form.Control
                                            type="text"
                                            onChange={(e) => handleChange(e)}
                                            placeholder="Mobile"
                                            size="lg"
                                            name="mobile"
                                            value={user.mobile || ''}
                                            className="h-auto"
                                            required={true}
                                            disabled={isMobile}
                                        />
                                    </Form.Group>
                                    {isOTP ?
                                        <Form.Group className="d-flex search-field">
                                            <Form.Control
                                                type="text"
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Enter OTP"
                                                size="lg"
                                                name="otp"
                                                className="h-auto"
                                            />
                                        </Form.Group> : null}
                                    <div className="mt-3">
                                        <Button
                                            type="submit"
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" id="submitBtn"
                                        >Submit</Button>
                                        {isMobile ? <Button type="button" className="btn btn-primary btn-lg font-weight-medium auth-form-btn cancel-login" id="cancelBtn" variant="secondary" onClick={() => hidePassOtpField()}>Back</Button> : null}
                                    </div>
                                </Form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Unsubscribe;