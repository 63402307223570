import React, { useEffect, useState } from 'react';
import Grid from "../../../components/dataTable";
import { Form, Modal, Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import Holiday from '../../../api/holidaysApi';
import SimpleReactValidator from 'simple-react-validator';
import DeleteModal from "../../../components/deleteAlertPopup";
import Moment from 'moment';
import Loader from "../../../components/loader";


const ManageHolidaysData = () => {
	const [data, setData] = useState({
		tooltipOpen: false,
		startstyle: {
			color: 'red',
			fontSize: '14px'
		},
		isModalVisible: false,
		rowPropsId: "",
		selectedClass: '',

		_id: '',
		festival: '',
		dateFrom: '',
		dateTo: ''
	})
	const [rows, setRows] = useState([]);
	const [dataLoader, setDataLoader] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [errors, setErrors] = useState({
		festival: '',
		dateFrom: '',
		dateTo: ''
	});

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		setDataLoader(true);
		let HolidayApi = new Holiday();
		await HolidayApi.getHoliday().then(async (response) => {
			// console.log(response.data)
			if (response.data && response.data.length) {
				setRows(response.data)
			} else {
				setRows([])
			}
		}).catch((error) => {
			toast.error(error.toString());
			console.error("There is an error!", error);
		});
		setDataLoader(false)
	}

	const renderAction = (props) => {

		return (
			<span>
				<ReactTooltip id='Edit' type='warning' effect='solid'>
					<span>Edit</span>
				</ReactTooltip>
				<button onClick={() => editHoliday(props)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
					<i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
				</button>

				<ReactTooltip id='Delete' type='error' effect='solid'>
					<span>Delete</span>
				</ReactTooltip>

				<button data-tip data-for="Delete" onClick={() => isVisible2(props._id)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
					<i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
				</button>
			</span>
		)
	}

	const isVisible2 = (id = null) => {
		let d = { ...data };
		if (id) {
			// console.log(id)
			d.rowPropsId = id;
		}
		setIsModalVisible2(!isModalVisible2);
		setData(d);
	}

	const DeactiveAction = (props, index) => {

		return (
			<span>
				<ReactTooltip id='activate' type='error' effect='solid'>
					<span>Activate/Deactive</span>
				</ReactTooltip>
				<span data-tip data-for="activate" className="form-check form-check-success" style={{ display: 'inline-flex' }}>
					<label className="form-check-label">
						<input type="checkbox" className="form-check-input" defaultChecked />
						<i className="input-helper"></i>
					</label>
				</span>
			</span>
		)
	}

	const editHoliday = (props) => {
		let c = { ...data };
		c._id = props._id;
		c.festival = props.festival;
		c.dateFrom = props.dateFrom;
		c.dateTo = props.dateTo;
		setData(c);
		clearErrors();
	}

	const deleteHoliday = async (id) => {
		let HolidayApi = new Holiday();
		isVisible2();
		await HolidayApi.deleteHoliday(data.rowPropsId)
			.then((response) => {
				// console.log(response);
				toast.success(response.msg.toString());
			})
			.catch((error) => {
				console.error("There is ERROR!", error)
				toast.error(error.toString());
			})
		getList();
	}

	const startDateFormat = (props) => {
		return Moment(props.dateFrom).format('DD/MM/YYYY');
	}

	const endDateFormat = (props) => {
		return Moment(props.dateTo).format('DD/MM/YYYY');
	}

	const field = () => {
		const fields = [
			/*{
				name: "action",
				title: "",
				width: "0%",
				align:"center",
				render: DeactiveAction,
			},*/
			{
				title: "Festivals",
				field: "festival",
			},
			{
				title: "Start Date(s)",
				field: "dateFrom",
				render: startDateFormat,
			},
			{
				title: "End Date(s)",
				field: "dateTo",
				render: endDateFormat,
			},
			{
				name: "action",
				title: "Actions ",
				render: renderAction,
			},
		];
		return fields;
	}

	// ================================================== coding part =========================================

	const handleChanges = (event) => {
		let c = { ...data }
		const { name, value } = event.target;
		if (name == "festival") {
			c.festival = value
		} else if (name == "dateFrom") {
			if (value != '' && c.dateTo != '')
				validator(name, value)
			c.dateFrom = value;
		} else if (name == "dateTo") {
			if (value != '' && c.dateFrom != '')
				validator(name, value)
			c.dateTo = value;
		}
		setData(c);
	}

	const validator = (name, value) => {
		let date = new Date(value);
		let check = true;
		if (name == 'dateTo') {
			let dateFrom = new Date(data.dateFrom);
			check = date.getTime() >= dateFrom.getTime();
			errors.dateTo = check ? "" : "End date can't be smaller than start date";
			errors.dateFrom = "";
		} else if (name == 'dateFrom') {
			let dateTo = new Date(data.dateTo);
			check = date.getTime() <= dateTo.getTime();
			errors.dateFrom = check ? "" : "Start date can't be bigger than End date";
			errors.dateTo = "";
		}
		return check;
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (errors.dateFrom != '' || errors.dateTo != '')
			return false;
		let {
			_id,
			festival,
			dateFrom,
			dateTo
		} = data;
		let HolidayApi = new Holiday()
		if (data._id) {
			await HolidayApi.editHoliday({
				_id,
				festival,
				dateFrom,
				dateTo
			}).then((data) => {
				if (data.success) {
					toast.success(data.msg.toString());
					cleanFields();
					getList();
				} else {
					toast.error(data.msg);
				}
			})
				.catch((error) => {
					toast.error(error.toString());
					console.error("There was an error!", error);
				});
		} else {
			await HolidayApi.createHoliday({
				festival,
				dateFrom,
				dateTo
			}).then((data) => {
				if (data.success) {
					toast.success(data.msg.toString());
					cleanFields();
					getList();
				} else {
					toast.error(data.msg);
				}
			})
				.catch((error) => {
					toast.error(error.toString());
					console.error("There was an error!", error);
				});
		}
	}

	const cleanFields = () => {
		let d = { ...data }
		d._id = "";
		d.festival = "";
		d.dateFrom = "";
		d.dateTo = "";
		setData(d);
	}

	const clearErrors = () => {
		errors.festival = "";
		errors.dateFrom = "";
		errors.dateTo = "";
	}

	// =================================================/ coding part /========================================

	return (
		<div>
			<div className="page-header">
				<h3 className="page-title"> Master Data </h3>
			</div>
			{dataLoader ? <Loader /> : null}
			<div className="row">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<form className="forms-sample" onSubmit={handleSubmit}>
								{/* =================================== Add holiday============================== */}
								<h4 className="card-title">Add Holiday</h4>
								<div className="row">
									<div className="col-md-3">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Festival<span style={data.startstyle}>*</span></label>
											<div className="col-sm-12">
												<Form.Control name="festival" value={data.festival} onChange={handleChanges} type="text" required />
												{/*{validator.message('festival', data.festival, 'required', {className:"text-danger"})}*/}
											</div>
										</Form.Group>
									</div>
									<div className="col-md-7">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Date<span style={data.startstyle}>*</span></label>
											<div className="col-sm-5">
												<Form.Control name="dateFrom" value={data.dateFrom} onChange={handleChanges} type="date" required />
												<small className="text-danger">{errors.dateFrom}</small>
											</div>
											<div className="col-sm-1 mt-3"> to </div>
											<div className="col-sm-5">
												<Form.Control name="dateTo" value={data.dateTo} onChange={handleChanges} type="date" required />
												<small className="text-danger">{errors.dateTo}</small>
											</div>
										</Form.Group>
									</div>


									<div className="col-md-2 mt-5">
										<button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }}>{data._id ? "Update" : "Add"}</button>
									</div>
								</div>

								{/* ========================================================== */}
							</form>
						</div>
					</div>
				</div>

				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<Grid columns={field()} rows={rows} />
					</div>
				</div>
			</div>
			<DeleteModal show={isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteHoliday} />
		</div>
	)
}


export default ManageHolidaysData;