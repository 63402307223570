import React, { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import { toast } from "react-toastify";
import class4Employees from "../../../api/addclass4Employees";
import NonTeachingStaffRoles from "../../../api/nonTeachingStaffRolesApi";
import { Link, useHistory } from "react-router-dom";

import { capitalize } from '../../../helpers/utils';

const AddClass4Employees = () => {
    let history = useHistory();
    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
          color: 'red',
          fontSize: '14px'
        },
        isModalVisible: false,
      })

      const [fromData, setFormData] = useState({
        _id: "",
        firstName:"",
        lastName:"",
        mobile:"",
        email:"",
        address: {
            address:"",
            city:"",
            pinCode:"",
            state:"punjab",
            country:"india"
        },
        photoUrl:"",
        userRoles:{ userRole: ""},
        introduction:""
      })
      const [fileProgress, setFileProgress] = useState({});
      const [categoryList, setCategoryList] = useState([])
      const [dataLoader, setDataLoader] = useState(false);
      const [rows, setRows] = useState([]);
    // =============================================== 

    useEffect(() => {
        const id = new URLSearchParams(history.location.search).get("id");
        getList();
        if(id){
            selectedEmployee(id)
        }
    },[])

    const getList = async () => {
        setDataLoader(true);
        let NonTeachingStaffRolesApi = new NonTeachingStaffRoles();
        await NonTeachingStaffRolesApi.getRole().then(async (response) => {
            console.log(response.data)
            if(response.data && response.data.length){
                setRows(response.data)
            } else {
              setRows([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
        setDataLoader(false) 
      }

    const selectedEmployee = async (id) => {
        let d = {...fromData}
        console.log(id);
        let class4Employe = new class4Employees();
        let data = await class4Employe.getClassEmploye(id);
        if(data.success){
            console.log(data);
            d._id = data.data._id;
            d.address = data.data.address;
            d.email = data.data.email;
            d.firstName = data.data.firstName;
            d.lastName = data.data.lastName;
            d.userRoles = data.data.userRoles;
            d.mobile = data.data.mobile;
            d.photoUrl = data.data.photoUrl;
            d.introduction = data.data.introduction;
        } else {
            toast.error(data.msg);
        }
        setFormData(d)
    }

    const cleanFields = () => {
        let resetdata = {
            _id: "",
            firstName:"",
            lastName:"",
            mobile:"",
            email:"",
            address: {
                address:"",
                city:"",
                pincode:"",
                state:"punjab",
                country:"india"
            },
            userRoles:{ userRole: ""},
            introduction:""
          }
          setFormData(resetdata)
    }

    const handleChanges = (e) => {
        let FormData = { ...fromData };
        let { name, value } = e.target;
        
        switch (name) {
            case "firstName":
                FormData.firstName = value;
            break;
            case "lastName":
                FormData.lastName = value;
            break;
            case "email":
                FormData.email = value;
            break;
            case "address":
                FormData.address.address = value;
            break;
            case "city":
                FormData.address.city = value;
            break;
            case "pincode":
                FormData.address.pinCode = value;
            break;
            case "state":
                FormData.address.state = value;
            break;
            case "country":
                FormData.address.country = value;
            break;
            case "mobileNo":
                FormData.mobile = value;
            break;
            case "subRole":
                FormData.userRoles.userRole = "nonTeaching";
                FormData.userRoles.subRole = value;
                
            break;
            case "introduction":
                FormData.introduction = value;
            break;
        }
        setFormData(FormData);
    }

    const submitChanges = async (e) => {
        e.preventDefault()
        let response;
        let class4Employe = new class4Employees();
        
        if(fromData._id) {
            response = await class4Employe.editClassEmploye({
                fromData
            });
            
        } else {
            response = await class4Employe.createClassEmployees({
                fromData
            });
        }

        if(response.error){
            toast.error(response.msg);
        } else {
            toast.success(response.msg);
            history.push("/class4employees");
        }
    }

    // ===============================================

    // ======================= photo upload function ==========================
    
    const uploadImage = async (value) => {
        let Staff = new class4Employees();
        const data = new FormData()
        data.append("file",value[0]);
        await Staff.uploadFile(data, (upload) => {
            let pg = { ...fileProgress };
            pg['file'] =  Math.round((100 * upload.loader) / upload.total);
            setFileProgress(pg);
        })
        .then((data) => {
            if(data.fileUrl && data.success) {
                let form = { ...fromData };
                form.photoUrl = data.fileUrl;
                setFormData(form);
                toast.success("Photo is uploaded")
                delete fileProgress["file"];
            }
        })
        .catch(() => {
            toast.error("Upload failed");
        })
    }
    
    // ======================= photo upload function ========================== 

    return(
        <div>
            <div className="page-header">
            <h3 className="page-title"> Non-Teaching Staff </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">    
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={(e)=>submitChanges(e)} className="forms-sample">
                                {/* ================================================ */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">First Name <span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                required={true}
                                                onChange={(e)=>handleChanges(e)}
                                                value={fromData.firstName}
                                            />
                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Last Name 
                                            </label>
                                            <div className="col-sm-9">
                                                <Form.Control 
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                    onChange={(e)=>handleChanges(e)}
                                                    value={fromData.lastName}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Mobile No. <span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <Form.Control 
                                                type="text"
                                                placeholder="Mobile No."
                                                name="mobileNo"
                                                required={true}
                                                onChange={(e)=>handleChanges(e)}
                                                value={fromData.mobile}
                                            />
                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Email</label>
                                            <div className="col-sm-9">
                                                <Form.Control 
                                                    type="email"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    onChange={(e)=>handleChanges(e)}
                                                    value={fromData.email}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Country <span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                        <select className="form-control"
                                            name="country"
                                            required={true}
                                            onChange={(e)=>handleChanges(e)}
                                            value={capitalize(fromData.address.country) || "India"}
                                        >
                                            <option value="">--------</option>
                                            <option>India</option>
                                        </select>
                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">State <span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <select 
                                                className="form-control"
                                                name="state"
                                                required={true}
                                                onChange={(e)=>handleChanges(e)}
                                                value={capitalize(fromData.address.state) || "Punjab"}
                                            >
                                            <option value="">--------</option>
                                            <option value="Andhra Pradesh">
                                                Andhra Pradesh
                                            </option>
                                            <option value="Andaman and Nicobar Islands">
                                                Andaman and Nicobar Islands
                                            </option>
                                            <option value="Arunachal Pradesh">
                                                Arunachal Pradesh
                                            </option>
                                            <option value="Assam">Assam</option>
                                            <option value="Bihar">Bihar</option>
                                            <option value="Chandigarh">Chandigarh</option>
                                            <option value="Chhattisgarh">Chhattisgarh</option>
                                            <option value="Dadar and Nagar Haveli">
                                                Dadar and Nagar Haveli
                                            </option>
                                            <option value="Daman and Diu">
                                                Daman and Diu
                                            </option>
                                            <option value="Delhi">Delhi</option>
                                            <option value="Lakshadweep">Lakshadweep</option>
                                            <option value="Puducherry">Puducherry</option>
                                            <option value="Goa">Goa</option>
                                            <option value="Gujarat">Gujarat</option>
                                            <option value="Haryana">Haryana</option>
                                            <option value="Himachal Pradesh">
                                                Himachal Pradesh
                                            </option>
                                            <option value="Jammu and Kashmir">
                                                Jammu and Kashmir
                                            </option>
                                            <option value="Jharkhand">Jharkhand</option>
                                            <option value="Karnataka">Karnataka</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Madhya Pradesh">
                                                Madhya Pradesh
                                            </option>
                                            <option value="Maharashtra">Maharashtra</option>
                                            <option value="Manipur">Manipur</option>
                                            <option value="Meghalaya">Meghalaya</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Sikkim">Sikkim</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">
                                                Uttar Pradesh
                                            </option>
                                            <option value="Uttarakhand">Uttarakhand</option>
                                            <option value="West Bengal">West Bengal</option>
                                            </select>
                                        </div>
                                    </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">City <span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <Form.Control 
                                                type="text"
                                                className="form-control" 
                                                id="exampleInputCity1" 
                                                placeholder="Location"
                                                name="city"
                                                required={true}
                                                onChange={(e)=>handleChanges(e)}
                                                value={fromData.address.city}
                                            />
                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">
                                            Pin code 
                                            </label>
                                        <div className="col-sm-9">
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                id="exampleInputName12"
                                                placeholder="Pin code"
                                                name="pincode"
                                                onChange={(e)=>handleChanges(e)}
                                                value={fromData.address.pinCode}
                                            />
                                        </div>
                                    </Form.Group>
                                    </div>
                                </div>
                    
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Role <span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                        <select 
                                            className="form-control"
                                            name="subRole"
                                            required={true}
                                            onChange={(e)=>handleChanges(e)}
                                            value={fromData.userRoles.subRole}
                                        >
                                            <option value="">-------</option>
                                            {
                                                rows.map((data)=>{
                                                    return(<option value={data.roleName}>{data.roleName}</option>)
                                                })
                                            }
                                        </select>
                                        </div>
                                        </Form.Group>
                                    </div>


                                    <div className="col-md-6">
                                        <Form.Group className="row m-0">
                                            <label className="col-sm-3 col-form-label"> Photo </label>
                                            <div className="custom-file col-sm-9">
                                                <Form.Control
                                                    type="file"
                                                    className="form-control visibility-hidden"
                                                    id="customFileLang"
                                                    lang="es"
                                                    name="photo"
                                                    onChange={(e)=> uploadImage(e.target.files)}
                                                />
                                                <label className="custom-file-label" htmlFor="customFileLang">Browse</label>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row">
                                            <label className="col-sm-3"></label>
                                            {fromData.photoUrl? <span className="col-sm-9">
                                                <Image src={`${fromData.photoUrl}`} style={{height: '200px'}} thumbnail />
                                            </span>:"" }
                                        </Form.Group>
                                    </div>


                                </div>

                                <div className="row">
                                    <Form.Group className="col-md-12">
                                        <label>Introduction</label>
                                        <Form.Control 
                                            as="textarea"
                                            name="introduction"
                                            rows={4}
                                            placeholder="150-200 words"
                                            onChange={(e)=>handleChanges(e)}
                                            value={fromData.introduction}
                                        />
                                    </Form.Group>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{lineHeight:1.5, float:'right'}}>{fromData._id?"Update":"Add"}</button>
                                    </div>
                                </div>
                                {/* ========================================================== */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddClass4Employees;