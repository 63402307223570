import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../../pages/login";
import ForgotPassword from "../../pages/forgotPassword";
import SetNewPassword from "../../pages/setNewPassword";
import Home from "../../pages/web/home";
import Footer from "../web/footer";
import Header from "../web/header";
import "../../assets/styles/web/imagehover.min.css";
import "../../assets/styles/web/style-new.css";

// const Home = React.lazy(() => import("../../pages/web/home"));
// const Header = React.lazy(() => import("../web/header"));
// const Footer = React.lazy(() => import("../web/footer"));
// const Login = React.lazy(() => import("../../pages/login"));
// const ForgotPassword = React.lazy(() => import("../../pages/forgotPassword"));
// const SetNewPassword = React.lazy(() => import("../../pages/setNewPassword"));
const PublicLayout = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/resetpassword" component={SetNewPassword} />
        <Route path="/">
          <Header />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home" component={Home} />
          <Footer />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
export default PublicLayout;