import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditSlots from '../pages/schooladmin/TimeTable/PopUps/EditSlots';

const AllClassTimeTable = ({
  workingDays, allClassData, staffTeachersList, slots, groupID
}) => {

  const [slotData, setSlotData] = useState({});
  const [restSubTeachData, setRestSubTeachData] = useState([])
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [classData, setClassData] = useState([])

  useEffect(()=>{
    setClassData(allClassData)
  },[allClassData])

  const editTimeTablePopup = (item, dayName, className, section, classId, restSubTeachData, totalClassData) => {
    setShowEditPopup(true)
    item.dayName = dayName
    item.className = `${className}-${section}`
    item.classId = classId
    item.slotId = item._id
    let arr = []
    for(let i =0; i < totalClassData.length; i ++){
      for(let j =0; j < totalClassData[i].timeTable.length; j++){
        if(totalClassData[i].timeTable[j].dayName == dayName){
          for(let k =0; k < totalClassData[i].timeTable[j].subjectsTiming.length; k++){
            if(totalClassData[i].timeTable[j].subjectsTiming[k].timeFrom  == item.timeFrom && totalClassData[i].timeTable[j].subjectsTiming[k].timeTo  == item.timeTo){
              arr.push(totalClassData[i].timeTable[j].subjectsTiming[k])
            }
          }
        }
      }
    }
    let thirdArray = restSubTeachData.filter((elem) => {
      return !arr.some((ele) => {
        return ele.teacherId == elem.teacherId;
      });
    });
    setSlotData(item)
    setRestSubTeachData(thirdArray)
  }

  const isModalVisible = () => {
    setShowEditPopup(false);
  };

  const updateParent = (updatedData) => {
    setClassData(updatedData)
  }

  return (  
    <>
      {(classData.length > 0) ?
        <table className='timetable table-responsive'>
          <thead>
            <tr>
              {
                workingDays && workingDays.map((day) =>
                  <th key={day}>{day}</th>
                )
              }
            </tr>
          </thead>
          <tbody >
            <tr className='text-sm'>
              {
                workingDays && workingDays.map((item, index) => {
                  return (
                    <td key={`main-${index}`}>
                      <table key={`table-${index}`} style={{ width: '650px', border: 'hidden' }}>
                        <tbody key={`tBody-${index}`}>
                          <tr key={`row-${index}`} style={{ height: '38px' }}>
                            {
                              slots && slots.map((item2, index2) => {
                                return <td key={`innerTd-${index2}`} style={{ minWidth: '117px',fontWeight:"bold" }}><span> {moment(item2.from).format('hh:mm A')} </span> - {moment(item2.to).format('hh:mm A')}</td>
                              })
                            }
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  )
                })
              }
            </tr>
            {

              classData && classData.map((classes, index2) => (
                <tr key={index2} >
                  {
                    classes.timeTable.map((tt, index) => {
                      let html;
                      html = (
                        <td className='text-md' key={index}>
                          <table style={{ width: '650px', border: 'hidden' }}>
                            <tbody>
                              <tr style={{ height: '124px' }}>
                                {
                                  tt.subjectsTiming && tt.subjectsTiming.map((item, index1) => {
                                    const teacherName = staffTeachersList.find(item1 => {
                                      return item1._id === item.teacherId
                                    })
                                    if (teacherName) {
                                      const name = teacherName.firstName + ' ' + teacherName.lastName
                                      item.teacherName = name;
                                    }
                                    return (
                                      <td key={`td-${item._id}`} style={{ cursor: 'pointer', minWidth: '114px' }} onClick={() => { editTimeTablePopup(item, tt.dayName, classes.className, classes.section, classes._id, tt.subjectsTiming, classData) }}>
                                        {item.subjectName}<span><br />({item.teacherName}) </span>
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                            </tbody>
                          </table>
                        </td>)
                      return html
                    })
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
        : null}
      {showEditPopup ? (
        <EditSlots
          update={updateParent}
          show={showEditPopup}
          isModalVisible={isModalVisible}
          slotData={slotData}
          restSubTechData={restSubTeachData}
          groupID={groupID}>
        </EditSlots>) : null}
    </>
  )
}

export default AllClassTimeTable;