import React, { useEffect } from 'react';
import * as d3 from 'd3';

const AllStudentsResult = ({ data }) => {
  console.log("data", data);
  useEffect(() => {
    const width = 200;
    const height = 400;
    const margin = { top: 20, right: 20, bottom: 60, left: 40 };

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3.select('#bar-graph')
      .attr('width', width)
      .attr('height', height);
    svg.selectAll('*').remove();

    const chart = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const colorScale = d3.scaleThreshold()
      .domain([20, 40, 60, 80, 100])
      .range(['gray', 'red', 'orange', 'yellow', 'green']);

    const xScale = d3.scaleBand()
      .domain(data.map(d => d.student))
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, 100])
      .nice()
      .range([innerHeight, 0]);

    chart.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => xScale(d.student))
      .attr('y', d => yScale(d.totalPercentage))
      .attr('width', xScale.bandwidth())
      .attr('height', d => innerHeight - yScale(d.totalPercentage))
      .attr('fill', (d) => colorScale(parseInt(d.totalPercentage)));

    chart
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('transform', 'rotate(-45)');

    chart
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale)
        .tickFormat(d => `${d}%`));
  }, [data]);

  return (
    <svg id="bar-graph"></svg>
  );
};

export default AllStudentsResult;