import BaseApi from "./baseApi";
class Update extends BaseApi {
    async getUpdates() {
        return await this.API(
            {
                url: "/schoolupdate/getupdates",
                method:"get",
            }
        )
    }

    async createUpdate(data) {
        return await this.API(
            {
                url: "/schoolupdate/createupdate",
                data: data,
                method:"post"
            }
        )
    }

    async editUpdate(data) {
        return await this.API(
            {
                url: "/schoolupdate/editupdate",
                data: data,
                method:"post"
            }
        )
    }

    async deleteUpdate(data) {
        return await this.API(
            {
                url: "/schoolupdate/deleteupdate",
                data: { _id: data },
                method:"post"
            }
        )
    }
		
		async activateOrDactivate(data) {
				return await this.API(
						{
								url: "/schoolupdate/activeupdate",
								data: data,
								method:"post"
						}
				)
		}

}

export default Update;