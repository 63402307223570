import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Form, Button, } from "react-bootstrap";
import Loader from "../../../components/loader";
import BaseApi from "../../../api/baseApi";
import Class from "../../../api/classApi";
import ReactTooltip from "react-tooltip";
import AccordionDisplay from "../../../components/Accordion";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import StudentGrid from "./Grids/studentGrid";
import TeacherGrid from "./Grids/teacherGrid";
import NonTeacherGrid from "./Grids/nonTeachingGrid";
import { MultiSelect } from "react-multi-select-component";
import BusRoute from "../../../api/busRouteApi";

const ManageReports = () => {
	const [isLoader, setIsLoader] = useState(false);
	const [classList, setClassList] = useState([]);
	const [isStudentView, setIsStudentView] = useState(true);
	const [isTeacherView, setIsTeacherView] = useState(false);
	const [isNonTeachingView, setIsNonTeachingView] = useState(false);
	const [bustRoute, setBusRoute] = useState([]);

	const [searchReport, setSearchReport] = useState({
		classes: "",
		feePaid: "",
		feeUnpaid: "",
		dateStartFee: "",
		dateEndFee: "",
		studPresent: "",
		studAbsent: "",
		studleave: "",
		dateStartAttend: "",
		dateEndAttend: ""
	});
	// const [rows, setRows] = useState([]);
	let inputFrom = {
		placeholder: "From"
	};
	let inputTo = {
		placeholder: "To"
	};

	useEffect(() => {
		getClassList();
		getDummyData();
		getBusRoute();
	}, [])

	const getDummyData = () => {
		setIsLoader(true);
		setIsLoader(false);
	}

	const getClassList = async () => {
		setIsLoader(true);
		let classOprions = [];
		let clist = new Class();
		await clist
			.getClass()
			.then((response) => {
				if (response.data && response.data.length) {
					response.data &&
						response.data.map((item) => {
							let className = item.className;
							let section = item.section ? item.section : "";
							let clsSect = className;
							if (section) {
								clsSect = className + "-" + section;
							}
							classOprions.push({ label: clsSect, value: item._id });
						});
					setClassList(classOprions);
				} else {
					setClassList([]);
				}
			})
			.catch((error) => {
				toast.error(error.toString());
			});
		setIsLoader(false);
	};

	const getBusRoute = async () => {
		let BusRouteApi = new BusRoute();
		await BusRouteApi.getBusRoutes().then(async (response) => {
			// console.log('response----',response.data)
			if (response.data && response.data.length) {
				setBusRoute(response.data)
			} else {
				setBusRoute([]);
			}
		}).catch((error) => {
			// toast.error(error.toString());
			console.error("There is an error!", error);
		});
	}

	const handleChanges = (e) => {
		let searchData = { ...searchReport };
		let { name, value } = e.target;
		switch (name) {
			case "keywordSearch":
				searchData.keywordSearch = value;
				break;
			case "class":
				searchData.classes = value;
				break;
			case "session":
				searchData.session = value;
				break;
			case "fee":
				searchData.fee = value;
				break;
		}
		setSearchReport(searchData);
	};

	const childAccordionStyle = {
		childClass:"card-subheader", childPadding: "child-padding", childContainer: "child-container"
	}

	const StudentsAccordionChildren = () => {
		return (
			<>
				<form className="forms-sample" onSubmit={(e) => handleStudentSubmit(e)}>
					<AccordionDisplay children={classChildren()}  title="Classes" childClass={childAccordionStyle} arrowPosition="right" forceDefaultHide={true}
					></AccordionDisplay>
					<AccordionDisplay children={classFeeAccordChild()} childClass={childAccordionStyle} title="Fee" arrowPosition="right" forceDefaultHide={true}></AccordionDisplay>
					<AccordionDisplay children={classAttendAcoordChild()} childClass={childAccordionStyle} title="Attendance" arrowPosition="right" forceDefaultHide={true}></AccordionDisplay>
					<AccordionDisplay children={studentBusAcoordChild()} childClass={childAccordionStyle} title="Bus Routes" arrowPosition="right" forceDefaultHide={true}></AccordionDisplay>
					<Button type="submit" className="btn btn-primary ml-2 btn-fw col" style={{ lineHeight: 1.5, float: "right" }}
					>
						Filter
					</Button>
				</form>
			</>
		)
	}

	const handleStudentSubmit = (e) => {
		setIsLoader(true)
		e.preventDefault()
		setIsStudentView(true)
		setIsNonTeachingView(false)
		setIsTeacherView(false)
		setTimeout(() => {
			setIsLoader(false)
		}, 1000)
	}
	const classChildren = () => {
		return (
			<Form.Group >
				<MultiSelect
					options={classList}
					labelledBy="Select"
				// value={schoolSettings.schoolTime[index].satHolidays}
				// onChange={(e) => {
				// 	setSelectedHolidays(e);
				// 	let schoolSettingsFrom = { ...schoolSettings };
				// 	schoolSettingsFrom.schoolTime[index].satHolidays = e;
				// 	setSchoolSettings(schoolSettingsFrom);
				// }}
				/>
			</Form.Group>
		)
	}

	const classAttendAcoordChild = () => {
		return (
			<>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="studPresent"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Present
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="studAbsent"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Absent
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="studleave"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Leave
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<div className="row">
					<div className="col-sm-6">
						<Form.Group className="row">
							<Datetime
								inputProps={inputFrom}
								dateFormat={true}
								timeFormat={false}
								utc={true}
								onChange={(e) => handleChanges({ target: { name: "dateStartAttend", value: moment(e) } })}
							// value={moment(formData.sessionStart)}
							/>
						</Form.Group>
					</div>
					<div className="col-sm-6">
						<Form.Group className="row">
							<Datetime
								inputProps={inputTo}
								dateFormat={true}
								timeFormat={false}
								utc={true}
								onChange={(e) => handleChanges({ target: { name: "dateEndAttend", value: moment(e) } })}
							// value={moment(formData.sessionStart)}
							/>
						</Form.Group>
					</div>
				</div>
			</>
		)
	}

	const studentBusAcoordChild = () => {
		return (
			<Form.Group>
				<MultiSelect
					options={bustRoute}
					labelledBy="Select"
				// value={}
				// onChange={(e) => {
				// 	setSelectedHolidays(e);
				// 	let schoolSettingsFrom = { ...schoolSettings };
				// 	schoolSettingsFrom.schoolTime[index].satHolidays = e;
				// 	setSchoolSettings(schoolSettingsFrom);
				// }}
				/>
			</Form.Group>
		)
	}

	const classFeeAccordChild = () => {
		return (
			<>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="feePaid"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Paid
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="feeUnpaid"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Un-Paid
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<div className="row">
					<div className="col-sm-6">
						<Form.Group className="row">
							<Datetime
								inputProps={inputFrom}
								dateFormat={true}
								timeFormat={false}
								utc={true}
								placeholder="From"
								onChange={(e) => handleChanges({ target: { name: "dateStartFee", value: moment(e) } })}
							// value={moment(formData.sessionStart)}
							/>
						</Form.Group>
					</div>
					<div className="col-sm-6">
						<Form.Group className="row">
							<Datetime
								inputProps={inputTo}
								dateFormat={true}
								timeFormat={false}
								utc={true}
								placeholder="From"
								onChange={(e) => handleChanges({ target: { name: "dateEndFee", value: moment(e) } })}
							// value={moment(formData.sessionStart)}
							/>
						</Form.Group>
					</div>
				</div>
			</>
		)
	}

	const UserAccordionChildren = () => {
		return (
			<>
				<form className="forms-sample" onSubmit={(e) => handleTeacherSubmit(e)}>
					<AccordionDisplay children={userTeachChild()} childClass={childAccordionStyle} title="Teaching Classes" arrowPosition="right" forceDefaultHide={true}></AccordionDisplay>
					<AccordionDisplay children={userSubjectChild()} childClass={childAccordionStyle} title="Subjects" arrowPosition="right" forceDefaultHide={true}></AccordionDisplay>
					<AccordionDisplay children={userAttendChild()} childClass={childAccordionStyle} title="Attendance" arrowPosition="right" forceDefaultHide={true}></AccordionDisplay>
					<Button type="submit" className="btn btn-primary ml-2 btn-fw col" style={{ lineHeight: 1.5, float: "right" }}
					>
						Filter
					</Button>
				</form>
			</>
		)
	}
	const handleTeacherSubmit = (e) => {
		setIsLoader(true)
		// console.log('in User Submit')
		e.preventDefault();
		setIsNonTeachingView(false)
		setIsStudentView(false)
		setIsTeacherView(true)
		setTimeout(() => {
			setIsLoader(false)
		}, 1000)
	}

	const userTeachChild = () => {
		return (
			<>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="pre-nursery"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Pre-Nursery
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="nursery"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Nursery
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
			</>
		)
	}

	const userSubjectChild = () => {
		return (
			<>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="math"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Math
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="english"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							English
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
			</>
		)
	}

	const userAttendChild = () => {
		return (
			<>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="userPresent"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Present
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="userAbsent"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Absent
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<Form.Group>
					<div className="form-check form-check-success">
						<label className="form-check-label">
							<input
								type="checkbox"
								className="form-check-input"
								name="userleave"
								onChange={(e) => handleChanges(e)}
							// checked={}
							/>{" "}
							Leave
							<i className="input-helper"></i>
						</label>
					</div>
				</Form.Group>
				<div className="row">
					<div className="col-sm-6">
						<Form.Group className="row">
							<Datetime
								inputProps={inputFrom}
								dateFormat={true}
								timeFormat={false}
								utc={true}
								placeholder="From"
								onChange={(e) => handleChanges({ target: { name: "dateStartUserAttend", value: moment(e) } })}
							// value={moment(formData.sessionStart)}
							/>
						</Form.Group>
					</div>
					<div className="col-sm-6">
						<Form.Group className="row">
							<Datetime
								inputProps={inputTo}
								dateFormat={true}
								timeFormat={false}
								utc={true}
								placeholder="From"
								onChange={(e) => handleChanges({ target: { name: "dateEndUserAttend", value: moment(e) } })}
							// value={moment(formData.sessionStart)}
							/>
						</Form.Group>
					</div>
				</div>
			</>
		)
	}

	const NonTeachAccordionChildren = () => {
		return (
			<>
				<form className="forms-sample" onSubmit={(e) => handleNonTecherSubmit(e)}>
					<Form.Group>
						<div className="form-check form-check-success">
							<label className="form-check-label">
								<input
									type="checkbox"
									className="form-check-input"
									name="nonTeachPresent"
									onChange={(e) => handleChanges(e)}
								// checked={}
								/>{" "}
								Present
								<i className="input-helper"></i>
							</label>
						</div>
					</Form.Group>
					<Form.Group>
						<div className="form-check form-check-success">
							<label className="form-check-label">
								<input
									type="checkbox"
									className="form-check-input"
									name="nonTeachAbsent"
									onChange={(e) => handleChanges(e)}
								// checked={}
								/>{" "}
								Absent
								<i className="input-helper"></i>
							</label>
						</div>
					</Form.Group>
					<Form.Group>
						<div className="form-check form-check-success">
							<label className="form-check-label">
								<input
									type="checkbox"
									className="form-check-input"
									name="nonTeachleave"
									onChange={(e) => handleChanges(e)}
								// checked={}
								/>{" "}
								Leave
								<i className="input-helper"></i>
							</label>
						</div>
					</Form.Group>
					<div className="row">
						<div className="col-sm-6">
							<Form.Group className="row">
								{/* <label className=" col-form-label">From</label> */}
								<Datetime
									inputProps={inputFrom}
									dateFormat={true}
									timeFormat={false}
									utc={true}
									onChange={(e) => handleChanges({ target: { name: "dateStartNonTeachAttend", value: moment(e) } })}
								// value={moment(formData.sessionStart)}
								/>
							</Form.Group>
						</div>
						<div className="col-sm-6">
							<Form.Group className="row" >
								{/* <label className=" col-form-label">To</label> */}
								<Datetime
									inputProps={inputTo}
									dateFormat={true}
									timeFormat={false}
									utc={true}
									onChange={(e) => handleChanges({ target: { name: "dateEndNonTeachAttend", value: moment(e) } })}
								// value={moment(formData.sessionStart)}
								/>
							</Form.Group>
						</div>
					</div>
					<Button type="submit" className="btn btn-primary ml-2 btn-fw col" style={{ lineHeight: 1.5, float: "right" }}
					>
						Filter
					</Button>
				</form>
			</>
		)
	}

	const handleNonTecherSubmit = (e) => {
		setIsLoader(true)
		e.preventDefault();
		// console.log('in Non Teacher Submit')
		setIsNonTeachingView(true)
		setIsStudentView(false)
		setIsTeacherView(false)
		setTimeout(() => {
			setIsLoader(false)
		}, 1000)
	}

	return (
		<div>
			<div className="page-header">
				<h3 className="page-title"> Reports </h3>
			</div>
			{isLoader ? <Loader /> : null}
			<div className="row">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-3">
									<AccordionDisplay children={StudentsAccordionChildren()} title="Students" arrowPosition="right" forceDefaultHide={true}>
									</AccordionDisplay>
									<AccordionDisplay children={UserAccordionChildren()} title="Teachers" arrowPosition="right" forceDefaultHide={true}>
									</AccordionDisplay>
									<AccordionDisplay children={NonTeachAccordionChildren()} title="Non-Teaching" arrowPosition="right" forceDefaultHide={true}>
									</AccordionDisplay>
								</div>
								<div className="col-md-9">
									{isStudentView ? <StudentGrid /> : (isTeacherView ? <TeacherGrid /> : (isNonTeachingView ? <NonTeacherGrid /> : <StudentGrid />))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageReports;
