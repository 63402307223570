import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, roles1, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = JSON.parse(localStorage.getItem('user_info'));
        const role = currentUser.role;
        const accessToModules = currentUser.accessToModules;
        const nonStaff = currentUser.nonStaff; // class 4 employees
        const modules = rest.path.split('/');
        const moduleName = modules[modules.length - 1];
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        if (role === 'principal' || role === 'teacher' || nonStaff) {
            let objKey, objKeys = Object.keys(accessToModules);
            let n = objKeys.length;
            const newobj = {}
            while (n--) {
                objKey = objKeys[n];
                newobj[objKey.toLowerCase()] = accessToModules[objKey];
            }
            const key4 = Object.keys(newobj).find(function (val, key) {
                return val === moduleName || moduleName.includes(val)
            });
            if (!newobj[key4]) {
                return <Redirect to={{ pathname: '/' }} />
            }
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)
