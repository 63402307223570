import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { Link } from 'react-router-dom';
import { Form, Modal, Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import StaffMember from "../../../api/staffMember";
import Loader from "../../../components/loader";
import ExportAccessLevels from "../../../components/export/accessLevels";
const AccessLevels = () => {
    const [records, setRecords] = useState({
        data: [],
        tooltipOpen: false,
        startstyle: {
            place: "top",
            type: "dark",
            effect: "float",
            condition: false,
            startstyle: {
                color: 'red',
                fontSize: '14px'
            }
        },
        isModalVisible: false,
        selectedRoute: '',
    })
    const [rolesData, setRolesData] = useState([])
    const [schoolMembers, setSchoolMembers] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);

    useEffect(() => {
        getAllSchoolMembers();
    }, []);

    const getAllSchoolMembers = async () => {
        setDataLoader(true);
        const staffApi = new StaffMember();
        const data = await staffApi.getAllSchoolMembers();
        if (data.success) {
            setRecords({ data: data.data });
            setSchoolMembers(data.data);
            const rolesArr = [];
            if (data.data && data.data.length) {
                data.data.map((item) => {
                    if (!rolesArr.includes(item.role)) {
                        rolesArr.push(item.role);
                    }
                })
            }
            setRolesData(rolesArr);
        } else {
            setRecords({ data: [] });
        }
        setDataLoader(false);
    }

    const onChangeRoles = async (e) => {
        const { value } = e.target;
        const dat = schoolMembers.filter((item) => {
            if (value) {
                return item.role === value;
            } else {
                return item.role;
            }
        })
        setRecords({ data: dat });
    }

    function renderAction(props, index) {
        return (
            <span>
                <ReactTooltip id='Edit' type='warning' effect='solid'>
                    <span>Edit</span>
                </ReactTooltip>
                <Link to={`/editaccesslevels/${props._id}`}>
                    <button data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                        <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                    </button>
                </Link>
            </span>
        );
    }

    const deactiveAction = (props, index) => {
        return (
            <span>
                <ReactTooltip id='activate' type='error' effect='solid'>
                    <span>Activate/Deactive</span>
                </ReactTooltip>
                <span data-tip data-for="activate" className="form-check form-check-success" style={{ display: 'inline-flex' }}>
                    <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" defaultChecked />
                        <i className="input-helper"></i>
                    </label>
                </span>
            </span>
        )
    }

    const userNameHyperlink = (props) => {
        return (
            <>
                <Link to="/accesslevels">{props.username}</Link>
            </>
        )
    }

    const field = () => {
        const fields = [
            {
                name: "action",
                title: "",
                width: "0%",
                align: "center",
                render: deactiveAction,
            },
            {
                title: "Username",
                render: userNameHyperlink,
                field: "username"
            },
            {
                title: "Role",
                field: "role",
            },
            {
                name: "action",
                title: "Actions ",
                render: renderAction,
            },
        ];
        return fields;
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Manage Access Levels </h3>
                <div className="col-sm-2">
                    <select
                        className="form-control"
                        name="roles"
                        onChange={(e) => onChangeRoles(e)}
                    >
                        <option value="">All</option>
                        {
                            rolesData.map((resp) => <option key={resp} value={resp}>{resp}</option>)
                        }
                    </select>
                </div>
            </div>

            {dataLoader ? <Loader /> : null}
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row mb-2">
                            <div className="col-md-12 text-right">
                                <ExportAccessLevels accessLevels={records.data} />
                            </div>
                        </div>
                        <MaterialTable
                            title=""
                            data={records.data}
                            columns={field()}
                            options={{ search: true, paging: false, exportButton: false }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessLevels;
