let user = localStorage.getItem('user_info');
let addedByUserId = '';
if (user) {
    user = JSON.parse(user);
    addedByUserId = user.userId;
}

const ExamSchedule = {
    _id: '',
    className: '',
    term: '',
    // subjectId: '',
    subjects: [{
        subjectId: '',
        dateOfExam: '',
        timeOfExam: {
           from: '',
           to: ''
        },
        monitorUserId: ''
    }],
    dateOfExam: '',
    fromTimeOfExam: '',
    toTimeOfExam: '',
    description: '',
    /*timeOfExam: {
        from: '',
        to: ''
    },*/
    addedByUserId: addedByUserId
};

module.exports = {
    ExamSchedule
}
