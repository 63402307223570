import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/dataTable";
import StudentFeeApi from "../../../api/studentFeeApi";
import SchoolApi from "../../../api/schoolApi";
import Concession from "../../../api/concessionApi";
import { toast } from "react-toastify";
import moment from "moment";
import BaseApi from "../../../api/baseApi";
import PreviousFeeRecordModal from "./PreviousFeeRecordModal";

const SubmitStudentFee = () => {
  const history = useHistory();
  const [data, setData] = useState({
    records: [],
    totalAmount: 2000,
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    isModalVisible2: false,
    selectedClass: "",
  });

  const [rows, setRows] = useState([]);
  const [clist, setClist] = useState([]);

  const [feeDetails, setFeeDetails] = useState([]);
  const [staticOriginalFee, setStaticOriginalFee] = useState([]);
  const [feeforMonths, setFeeforMonths] = useState([]);
  const [feeForSessionList, setFeeForSessionList] = useState([]);
  const [feeForSession, setFeeForSession] = useState(new Date().getFullYear());
  const [feeSchedulesData, setFeeSchedulesData] = useState();
  const [lateFeeAmount, setLateFeeAmount] = useState(0);
  const [choosenConcession, setChoosenConcession] = useState();
  const [concessionAmount, setConcessionAmount] = useState(0);
  const [monthsList, setMonthsList] = useState([]);
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [countMonths, setCountMonths] = useState(0);
  const [scheduleTotalAmount, setScheduleTotalAmount] = useState(0);
  const [studentInfo, setStudentInfo] = useState({
    _id: "",
    active: false,
    firstName: "",
    lastName: "",
    admissionNo: "",
    dob: "",
    gender: "",
    photoUrl: "",

    passedout: "",
    motherProfile: {
      name: "",
      mobile: "",
      email: "",
    },
    fatherProfile: {
      name: "",
      mobile: "",
      email: "",
    },
    admissionDate: "",
    concessionId: "",
    classInfo: {
      _id: "",
      className: "",
      section: "",
    },
    feeInfo: {},
    originalFee: {
      _id: "",
      session: "",
      feesDetails: [],
      totalAmount: "",
    },
  });

  useEffect(() => {
    const id = new URLSearchParams(history.location.search).get("id");
    if (id) {
      fetchData(id);
    }
    reorderMonthBySessionStart();

    // Loop and add the Year values to DropDownList.
    const year = [];
    for (
      let i = new Date().getFullYear() - 1;
      i <= new Date().getFullYear() + 1;
      i++
    ) {
      year.push(i);
    }
    setFeeForSessionList(year);
    if (localStorage.getItem("schoolId")) {
      getSchool(localStorage.getItem("schoolId"));
    }
  }, [feeForSession]);

  const fetchData = async (id) => {
    const studentFee = await getStudentFee(id);
    const chosedConcesion = await getConcessionList(id);
    if (
      chosedConcesion &&
      chosedConcesion._id &&
      studentFee &&
      studentFee._id
    ) {
      displayTotalAmount(chosedConcesion, studentFee);
    }
  };

  const [amountPaid, setAmountPaid] = useState({
    paymentDate: "",
    paymentMode: "",
    bankName: "",
    branchName: "",
    chequeNumber: "",
    cardNumber: "",
    releaseDate: "",
    chequeStatus: "",
  });

  const reorderMonthBySessionStart = async () => {
    const baseApi = new BaseApi();
    await baseApi.reorderMonthBySessionStart().then(async (response) => {
      if (response.success) {
        setMonthsList(response.data);
        let months = response.data;
        const id = new URLSearchParams(history.location.search).get("id");
        const detail = new StudentFeeApi();
        const response2 = await detail.feeHistory(id);
        setRows(response2.data);
        let feeMonths = [];
        if (response2.data) {
          response2.data.map((fee) => {
            if (fee.feeforMonths) {
              let sp = fee.feeforMonths;
              if (sp.length > 1) {
                sp.forEach((item) => {
                  feeMonths.push(item.toString());
                });
              } else {
                feeMonths.push(fee.feeforMonths.toString());
              }
            }
          });
          let arrayOfFeeMonthStatus = [];
          months.map((item) => {
            let i = 0;
            let findValue = feeMonths.find((f) => {
              return item.value == f;
            });
            if (findValue != undefined) {
              item = Object.assign({}, item);
              item["label"] = item.label + " --- Paid";
              item["disabled"] = true;
              arrayOfFeeMonthStatus.push(item);
            } else {
              item = Object.assign({}, item);
              item["label"] = item.label + " --- Unpaid";
              if (!i) {
                item["disabled"] = false;
                i++;
              } else {
                item["disabled"] = true;
              }
              arrayOfFeeMonthStatus.push(item);
            }
          });
          setMonthsList(arrayOfFeeMonthStatus);
        }
      } else {
        setMonthsList([]);
      }
    });
  };

  const getSchool = async (id) => {
    const schoolApi = new SchoolApi();
    await schoolApi
      .getSchool(id)
      .then(async (response) => {
        if (
          response.data &&
          response.data.feeSchedules &&
          response.data.feeSchedules.lateFees &&
          response.data.feeSchedules.lateFees.length
        ) {
          setFeeSchedulesData(response.data.feeSchedules);
        } else {
          setFeeSchedulesData([]);
        }
        return response;
      })
      .catch((error) => {
      });
  };

  const getConcessionList = async (id) => {
    const concessionApi = new Concession();
    const response = await concessionApi.getConcession();
    if (response.data && response.data.length) {
      let sConcession = null;
      response.data.find((item1) => {
        item1.student_docs.find((item2) => {
          if (item2._id === id) {
            sConcession = item1;
          }
        });
      });
      const cListData = [];
      if (sConcession && sConcession._id) {
        setChoosenConcession(sConcession);
        cListData.push(sConcession);
        setClist(cListData);
        return sConcession;
      } else {
        return {};
      }
    } else {
      setClist([]);
      return {};
    }
  };
  const handleClose2 = () => {
    let d = { ...data };
    d.selectedstudent = {};
    d.isModalVisible2 = false;
    setData(d);
  };

  const getStudentFee = async (id) => {
    let sessionYear = "";
    if (!isNaN(feeForSession)) {
      sessionYear = `${feeForSession}-${parseInt(feeForSession) + 1}`;
    }
    const detail = new StudentFeeApi();
    const response = await detail.getStudentFeeDetails(id, sessionYear);
    if (response.data) {
      setStudentInfo(response.data);
      if (response.data.originalFee) {
        setStaticOriginalFee(response.data.staticOriginalFee);
        setFeeDetails(response.data.originalFee.feesDetails);
      }
      return response.data;
    } else {
      toast.error(response.msg);
      return null;
    }
  };

  const displayTotalAmount = async (sConcession, studentInfo) => {
    let total = 0;
    if (sConcession.concessionAmount) {
      if (sConcession.concessionPercent) {
        const conAmount =
          (sConcession.concessionAmount * studentInfo.originalFee.totalAmount) /
          100;
        total = studentInfo.originalFee.totalAmount - conAmount;
        setConcessionAmount(conAmount);
      } else {
        total =
          studentInfo.originalFee.totalAmount - sConcession.concessionAmount;
        setConcessionAmount(sConcession.concessionAmount);
      }
    }
    let info = { ...studentInfo };

    info.originalFee.totalAmount = total;
    setStudentInfo(info);
  };

  const modifyLateFee = (e) => {
    let { value } = e.target;
    let modify = feeDetails;
    let info = { ...studentInfo };
    let total = 0;
    let firstMonthTotal = 0;
    modify.map((d) => {
      if (d.particular === "Annual Fees" || d.particular === "Admission Fees") {
        // This fees will be added for single time only.
        firstMonthTotal += Number(d.amount);
      } else {
        total += Number(d.amount);
      }
    });
    if (countMonths) {
      total *= countMonths;
    }
    total += firstMonthTotal;
    total += Number(value);
    let feeComissionAmount = 0;
    if (choosenConcession && choosenConcession._id) {
      if (choosenConcession.concessionPercent) {
        feeComissionAmount = (choosenConcession.concessionAmount * total) / 100;
      } else {
        feeComissionAmount = choosenConcession.concessionAmount;
      }
    }
    setConcessionAmount(feeComissionAmount);
    info.originalFee.totalAmount = total - feeComissionAmount;
    setLateFeeAmount(value);
    setStudentInfo(info);
  };

  const modifyFeeDetails = (e, index) => {
    let { value } = e.target;
    let modify = feeDetails;
    let info = { ...studentInfo };
    let total = 0;
    let firstMonthTotal = 0;
    const pay = { ...amountPaid };
    pay.paymentDate = "";
    setAmountPaid(pay);
    setLateFeeAmount(0);
    modify[index].amount = value;
    modify.map((d) => {
      if (d.particular === "Annual Fees" || d.particular === "Admission Fees") {
        // This fees will be added for single time only.
        firstMonthTotal += Number(d.amount);
      } else {
        total += Number(d.amount);
      }
    });
    if (countMonths) {
      total *= countMonths;
    }
    total += firstMonthTotal;
    if (lateFeeAmount) {
      total += Number(lateFeeAmount);
    }

    let feeComissionAmount = 0;
    if (choosenConcession && choosenConcession._id) {
      if (choosenConcession.concessionPercent) {
        feeComissionAmount = (choosenConcession.concessionAmount * total) / 100;
      } else {
        feeComissionAmount = choosenConcession.concessionAmount;
      }
    }
    setConcessionAmount(feeComissionAmount);
    info.originalFee.totalAmount = total - feeComissionAmount;
    setFeeDetails(modify);
    setStudentInfo(info);
  };

  const handleSetMonths = (e) => {
    let flag = 1;
    if (e.length && feeForSession) {
      rows.map((item) => {
        item.feeforMonths.map((item2) => {
          e.map((item3) => {
            if (
              parseInt(item3.value) === item2 &&
              item.feeForSession === parseInt(feeForSession)
            ) {
              flag = 0;
            }
          });
        });
      });
    }
    if (flag === 0) {
      return toast.error(
        "The fees for following months & year are already created."
      );
    }

    if (
      e.length &&
      e[e.length - 1].value != undefined &&
      e[e.length - 1].value
    ) {
      let m = monthsList;
      let fIndex = monthsList.findIndex((item) => {
        return parseInt(item.value) == parseInt(e[e.length - 1].value);
      });
      if (fIndex < 11) {
        m[fIndex + 1]["disabled"] = false;
      }
      setMonthsList(m);
    }
    setFeeforMonths(e);
    setCountMonths(e.length);
    const pay = { ...amountPaid };
    pay.paymentDate = "";
    setAmountPaid(pay);
    setLateFeeAmount(0);
    const sessionFirstMonth = monthsList[0].label;
    let sFM = sessionFirstMonth.split("---");
    const isSlctdSessionFirstMonth = e.filter((chooseM) => {
      let cm = chooseM.label.split("---");
      return sFM[0].trim() === cm[0].trim();
    });
    calucalteFee(e, isSlctdSessionFirstMonth);
  };

  const unsetConcessionFee = () => {
    let modify = feeDetails;
    let info = { ...studentInfo };
    let total = 0;

    modify.map((d) => {
      total += Number(d.amount);
    });
    let feeComissionAmount = 0;
    setConcessionAmount(feeComissionAmount);
    info.originalFee.totalAmount = total - feeComissionAmount;
    setFeeDetails(modify);
    setStudentInfo(info);
  };

  const modifyFeePaymentAmount = (lfa) => {
    let modify = feeDetails;
    let info = { ...studentInfo };
    let total = 0;
    let firstMonthTotal = 0;
    modify.map((d) => {
      if (d.particular === "Annual Fees" || d.particular === "Admission Fees") {
        // This fees will be added for single time only.
        firstMonthTotal += Number(d.amount);
      } else {
        total += Number(d.amount);
      }
    });

    if (countMonths) {
      total *= countMonths;
    }
    total += firstMonthTotal;
    const lateA = lfa ? lfa : 0;
    let feeComissionAmount = 0;
    if (choosenConcession && choosenConcession._id) {
      if (choosenConcession.concessionPercent) {
        feeComissionAmount = (choosenConcession.concessionAmount * total) / 100;
      } else {
        feeComissionAmount = choosenConcession.concessionAmount;
      }
    }
    info.originalFee.totalAmount = total - feeComissionAmount + lateA;
    setStudentInfo(info);
  };

  const calucalteFee = (e, isSlctdSessionFirstMonth, flag = null) => {
    if (isSlctdSessionFirstMonth.length && isSlctdSessionFirstMonth[0].value) {
      let modify = JSON.parse(JSON.stringify(staticOriginalFee));
      let info = { ...studentInfo };
      let studentFee = { ...studentInfo };
      let total = 0;
      let firstMonthTotal = 0;
      modify.map((d) => {
        if (
          d.particular === "Annual Fees" ||
          d.particular === "Admission Fees"
        ) {
          // This fees will be added for single time only.
          firstMonthTotal += Number(d.amount);
        } else {
          total += Number(d.amount);
        }
      });
      setCountMonths(e.length);
      if (e.length) {
        total *= e.length;
      }
      total += firstMonthTotal;

      if (flag == "paymentSchedule") {
        setScheduleTotalAmount(total);
      }

      let feeComissionAmount = 0;
      if (choosenConcession && choosenConcession._id) {
        if (choosenConcession.concessionPercent) {
          feeComissionAmount =
            (choosenConcession.concessionAmount * total) / 100;
        } else {
          feeComissionAmount = choosenConcession.concessionAmount;
        }
      }
      setConcessionAmount(feeComissionAmount);
      info.originalFee.totalAmount = total - feeComissionAmount;
      setFeeDetails(modify);
      setStudentInfo(info);
    } else {
      let modify = feeDetails;
      let info = { ...studentInfo };
      let total = 0;
      modify.map((d) => {
        if (d.particular === "Annual Fees") {
          d.amount = 0;
        }
        if (d.particular === "Admission Fees") {
          d.amount = 0;
        }
        total += Number(d.amount);
      });
      if (e.length) {
        total *= e.length;
      }
      setScheduleTotalAmount(total);
      let feeComissionAmount = 0;
      if (choosenConcession && choosenConcession._id) {
        if (choosenConcession.concessionPercent) {
          feeComissionAmount =
            (choosenConcession.concessionAmount * total) / 100;
        } else {
          feeComissionAmount = choosenConcession.concessionAmount;
        }
      }
      setConcessionAmount(feeComissionAmount);
      info.originalFee.totalAmount = total - feeComissionAmount;
      setFeeDetails(modify);
      setStudentInfo(info);
    }
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    const pay = { ...amountPaid };
    switch (name) {
      case "paymentMode":
        pay.paymentMode = value;
        if (value === "cheque") {
          pay.chequeStatus = "open";
        } else {
          pay.chequeStatus = "";
        }
        break;
      case "bankName":
        pay.bankName = value;
        break;
      case "branchName":
        pay.branchName = value;
        break;
      case "releaseDate":
        pay.releaseDate = value;
        break;
      case "chequeStatus":
        pay.chequeStatus = value;
        break;
      case "chequeNumber":
        pay.chequeNumber = value;
        break;
      case "cardNumber":
        pay.cardNumber = value;
        break;
      case "concessionId":
        if (!feeforMonths || !feeforMonths.length) {
          return toast.error("Please select Fees For Month field first");
        }
        if (!feeForSession) {
          return toast.error("Please select Year field first");
        }
        pay.concessionId = value;
        if (!value) {
          setChoosenConcession({});
          unsetConcessionFee();
        } else {
          const val = clist.find((item) => {
            return item._id === value;
          });
          if (val) {
            displayTotalAmount(val, studentInfo);
          }
        }
        pay.paymentDate = "";
        setLateFeeAmount(0);
        break;
      case "feeForSession":
        let flag = 1;
        if (feeforMonths.length && value) {
          rows.map((item) => {
            item.feeforMonths.map((item2) => {
              feeforMonths.map((item3) => {
                if (
                  parseInt(item3.value) === item2 &&
                  item.feeForSession === parseInt(value)
                ) {
                  flag = 0;
                }
              });
            });
          });
        }
        if (flag === 0) {
          setFeeForSession("");
          return toast.error(
            "The fees for following months & year are already created."
          );
        } else {
          setFeeForSession(value);
        }
        break;
      case "paymentDate":
        if (!feeforMonths || !feeforMonths.length) {
          return toast.error("Please select Fees For Month field first");
        }
        if (!feeForSession) {
          return toast.error("Please select Year field first");
        }
        pay.paymentDate = value;
        if (
          feeSchedulesData &&
          feeSchedulesData.lateFees &&
          feeSchedulesData.lateFees.length
        ) {
          const lastPaymentFeeType = feeSchedulesData.lastPaymentFeeType;
          let lateFeeAmount = feeSchedulesData.lateFeeAmount;
          loop1: for (let fsd of feeSchedulesData.lateFees) {
            const lastFeeDate = moment(fsd.lastFeeDate).format("YYYY-MM-DD");
            const months = fsd.months;
            loop2: for (let singelMonth of fsd.months) {
              loop3: for (const ffm of feeforMonths) {
                if (ffm.value === singelMonth.value) {
                  const dateDiff = moment(pay.paymentDate).diff(
                    fsd.lastFeeDate,
                    "days"
                  );
                  if (dateDiff > 0) {
                    if (lastPaymentFeeType === "perDay") {
                      lateFeeAmount = lateFeeAmount * dateDiff;
                    }

                    setLateFeeAmount(lateFeeAmount);
                    modifyFeePaymentAmount(lateFeeAmount);
                    break loop1;
                  } else {
                    setLateFeeAmount(0);
                  }
                }
              }
            }
          }
        }
        break;
      case "payable":
        studentInfo.originalFee.totalAmount = value;
        break;

      case "paymentSchedule":
        let months = [];
        feeSchedulesData &&
          feeSchedulesData.lateFees &&
          feeSchedulesData.lateFees.map((resp, k) => {
            if (resp._id.toString() == value.toString()) {
              resp.months.map((mth, i) => {
                var foundMonths = monthsList.findIndex(item => item.value == mth.value)
                if (foundMonths != -1) {
                  let enableMonths = monthsList[foundMonths].hasOwnProperty('disabled')
                  if (enableMonths) {
                    months.push({ label: mth.label, value: mth.value });
                  }
                }
              });
            }
          });
        setFeeforMonths(months);
        const sessionFirstMonth = monthsList[0].label;
        let sFM = sessionFirstMonth.split("---");
        const isSlctdSessionFirstMonth = months.filter((chooseM) => {
          return chooseM.label.split("---")[0].trim() == sFM[0].trim();
        });
        let index = e.nativeEvent.target.selectedIndex;
        let sedu = e.nativeEvent.target[index].text;
        let valueArray = sedu.split(" ");
        calucalteFee(valueArray, isSlctdSessionFirstMonth, "paymentSchedule");
        break;
      default:
    }
    setAmountPaid(pay);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const detail = new StudentFeeApi();
    const fMonths = [];
    if (!feeforMonths) {
      return;
    } else {
      feeforMonths.map((item) => {
        fMonths.push(item.value);
      });
    }
    if (!feeForSession) {
      return;
    }
    const data = {
      studentInfo,
      feeDetails,
      amountPaid,
      fMonths,
      feeForSession,
      lateFeeAmount,
      concessionAmount,
      scheduleTotalAmount,
    };
    await detail
      .submitFees(data)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
          history.push("/studentsfees");
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Pay Fees </h3>
        <button
          type="button"
          onClick={() => {
            let d = { ...data };
            d.isModalVisible2 = true;
            setData(d);
          }}
          className="btn btn-primary ml-2 btn-fw"
          style={{ lineHeight: 1.5 }}
        >
          Previous Fee Records
        </button>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form onSubmit={(e) => handleSubmit(e)} className="forms-sample">
                {/* ================================================ */}
                <h4 className="card-title">Student Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="col-sm-3 col-form-label">Name: </label>
                      <div className="col-sm-9 col-form-label">
                        <span>
                          {studentInfo.firstName} {studentInfo.lastName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="col-sm-3 col-form-label">
                        Admission No.
                      </label>
                      <div className="col-sm-9 col-form-label">
                        <span>{studentInfo.admissionNo}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="col-sm-3 col-form-label">
                        Father Name:{" "}
                      </label>
                      <div className="col-sm-9 col-form-label">
                        <span>{studentInfo.fatherProfile.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="col-sm-3 col-form-label">Class:</label>
                      <div className="col-sm-9 col-form-label">
                        <span>
                          {studentInfo.classInfo.className}-
                          {studentInfo.classInfo.section}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Payment Schedule<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="paymentSchedule"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                        >
                          <option value="">Select Schedule</option>
                          {feeSchedulesData &&
                            feeSchedulesData.lateFees &&
                            feeSchedulesData.lateFees.map((resp, k) => {
                              return (
                                <option key={k} value={resp._id}>
                                  {resp.months.map((m, p) => {
                                    return m.label + " ";
                                  })}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Fees For Month<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <MultiSelect
                          options={monthsList}
                          labelledBy="Select"
                          value={feeforMonths}
                          onChange={(e) => handleSetMonths(e)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* ============== here show dynamic filed ============== */}
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Year<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="feeForSession"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={feeForSession}
                        >
                          <option value="">Select Term</option>
                          {feeForSessionList.map((resp) => (
                            <option key={resp} value={resp}>
                              {resp}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  {feeDetails.map((d, index) => {
                    return (
                      <div className="col-md-6" key={index}>
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            {d.particular}
                            <span style={data.startstyle}>*</span>
                            <p>
                              {d.particular === "Annual Fees" ||
                                d.particular === "Admission Fees"
                                ? "(session start month)"
                                : "(per month)"}
                            </p>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="number"
                              className="form-control"
                              value={d.amount}
                              min="0"
                              onChange={(e) => modifyFeeDetails(e, index)}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    );
                  })}
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Concession<span style={data.startstyle}></span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="concessionId"
                          onChange={(e) => handleChanges(e)}
                          value={choosenConcession && choosenConcession._id}
                        >
                          <option value="">-----------------</option>
                          {clist.map((data) => {
                            if (data.active) {
                              return (
                                <option key={data._id} value={data._id}>
                                  {data.concessionType} -{" "}
                                  {data.concessionAmount}
                                  {data.concessionPercent ? "%" : ""}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* ============ here show dynamic filed end ============ */}
                <div className="row">
                  {lateFeeAmount ? (
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">
                          Late Fees
                          <span style={data.startstyle}></span>
                        </label>
                        <div className="col-sm-9">
                          <Form.Control
                            type="number"
                            className="form-control"
                            value={lateFeeAmount}
                            min="0"
                            onChange={(e) => modifyLateFee(e)}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Total Amount<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9 col-form-label">
                        <span>
                          {studentInfo.originalFee &&
                            studentInfo.originalFee.totalAmount}
                          {countMonths
                            ? countMonths === 1
                              ? ` for ${countMonths} month`
                              : ` for ${countMonths} months`
                            : ``}
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <h4 className="card-title">Payment Section</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Payment Date<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="date"
                          className="form-control"
                          name="paymentDate"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={amountPaid.paymentDate}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Payment Mode:<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="paymentMode"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={amountPaid.paymentMode}
                        >
                          <option value="">Select method</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="card">Card</option>
                          <option value="onlinePayment">Online Payment</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {amountPaid.paymentMode === "cheque" ? (
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">
                          Bank Name: <span style={data.startstyle}>*</span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            className="form-control"
                            name="bankName"
                            required={
                              amountPaid.paymentMode === "cheque" ? true : false
                            }
                            onChange={(e) => handleChanges(e)}
                            value={amountPaid.bankName}
                          >
                            <option value="">----------</option>
                            <option value="ALB">ALLAHABAD BANK (ALB)</option>
                            <option value="ANB">ANDHRA BANK (ANB)</option>
                            <option value="AXS">AXIS BANK (AXS)</option>
                            <option value="BOB">BOB</option>
                            <option value="BOI">BOI</option>
                            <option value="BOM">BOM</option>
                            <option value="CAB">CANARA BANK (CAB)</option>
                            <option value="CBI">CBOI (CBI)</option>
                            <option value="CITY">CITY</option>
                            <option value="COB">COOPERATIVE BANK (COB)</option>
                            <option value="DEB">DENA BANK (DEB)</option>
                            <option value="DCB">DCB</option>
                            <option value="EQUITAS">EQUITAS</option>
                            <option value="FB">FEDERAL BANK (FB)</option>
                            <option value="GPO">GPO FEDERAL BANK (GPO)</option>
                            <option value="HDF">HDFC (HDF)</option>
                            <option value="ICI">ICICI (ICI)</option>
                            <option value="IDB">IDBI (IDB)</option>
                            <option value="IDL">IDL</option>
                            <option value="INB">INDIAN BANK (INB)</option>
                            <option value="IDS">INDUSIND (IDS)</option>
                            <option value="IOB">IOB</option>
                            <option value="J&KB">J&K BANK</option>
                            <option value="KBL">KARNATAKA BANK (KBL)</option>
                            <option value="OBOC">OBOC</option>
                            <option value="PGB">PGB</option>
                            <option value="PNB">PNB</option>
                            <option value="P&SB">P&SB</option>
                            <option value="SBI">SBI</option>
                            <option value="STANDARD CHARTED">
                              STANDARD CHARTED
                            </option>
                            <option value="SOUTH INDIAN">KOTAK (KOT)</option>
                            <option value="UBOI">UBOI (UBI)</option>
                            <option value="UNI">UNI</option>
                            <option value="UCO">UCO</option>
                            <option value="VIJAYA BANK">VIJAYA BANK</option>
                            <option value="YES BANK">YES BANK</option>
                            <option value="YES PROPERTY">YES PROPERTY</option>
                            <option value="OTHERS">OTHERS</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">
                          Branch Name:<span style={data.startstyle}></span>
                        </label>
                        <div className="col-sm-9">
                          <Form.Control
                            type="text"
                            className="form-control"
                            name="branchName"
                            onChange={(e) => handleChanges(e)}
                            value={amountPaid.branchName}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Payable Amount<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          name="payable"
                          onChange={(e) => handleChanges(e)}
                          value={
                            studentInfo.originalFee &&
                            studentInfo.originalFee.totalAmount
                          }
                          disabled={true}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  {amountPaid.paymentMode === "cheque" ? (
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">
                          Cheque No: <span style={data.startstyle}>*</span>
                        </label>
                        <div className="col-sm-9">
                          <Form.Control
                            type="string"
                            className="form-control"
                            name="chequeNumber"
                            onChange={(e) => handleChanges(e)}
                            required={
                              amountPaid.paymentMode === "cheque" ? true : false
                            }
                            value={amountPaid.chequeNumber}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  ) : null}

                  {amountPaid.paymentMode === "card" ? (
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">
                          Card No: <span style={data.startstyle}>*</span>
                        </label>
                        <div className="col-sm-9">
                          <Form.Control
                            type="text"
                            className="form-control"
                            name="cardNumber"
                            onChange={(e) => handleChanges(e)}
                            required={
                              amountPaid.paymentMode === "card" ? true : false
                            }
                            value={amountPaid.cardNumber}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  ) : null}
                </div>

                <div className="row mt-3">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary ml-2 btn-fw"
                      style={{ lineHeight: 1.5, float: "right" }}
                    >
                      Pay Fees
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card"></div>
        </div>
      </div>

      {/* ================================= */}
      <PreviousFeeRecordModal data={data} handleClose2={handleClose2} rows={rows} feeSchedulesData={feeSchedulesData} monthsList={monthsList} />
      {/* ================================= */}
    </div>
  );
};

export default SubmitStudentFee;