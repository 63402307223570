import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (

    <footer id="footer" className="footer">
      <div className="container text-center">
        <p style={{ marginBottom: 30 }}>
          <Link to="privacy-policy" title="Privacy Policy - Edvantum.com" style={{ marginRight: "15px" }}>
            Privacy Policy{" "}
          </Link>
          <Link to="terms-of-use" title="Terms Of Use - Edvantum.com" style={{ marginRight: "15px" }}>
            Terms Of Use{" "}
          </Link>
          <Link to="refund-and-cancellation" title="Refund & Cancellation - Edvantum.com" style={{ marginRight: "15px" }}>
            Refund & Cancellation{" "}
          </Link>
          <Link to="unsubscribe" title="Unsubscribe - Edvantum.com" style={{ marginRight: "15px" }}>
            Unsubscribe{" "}
          </Link>
        </p>
        <h3 style={{ margin: "15px 0" }}>Registered Office:</h3>
        <p>
          Edvantum Infoservices Private Limited
          <br />
          SCF 40, Phase 7, Mohali{" "}
        </p>
        <h3 style={{ margin: "15px 0" }}>Corporate Office Address:</h3>
        <p style={{ minHeight: "22px" }}>SCO 218 Platinum Town, Malibu Town, Sohna Road, Gurgaon - 122101</p>
        ©{new Date().getFullYear()} Edvantum.com. All rights reserved
      </div>
    </footer>

  );
};
export default Footer;
