import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

export const UserContext = createContext({});
export const UserProvider = (props) => {
    const [userContext, setUserContext] = useState({ accessToken: "sdsdsds", role: "" });
    useEffect(() => {

    }, [])
    return (
        <UserContext.Provider value={[userContext, setUserContext]}>
            {props.children}
        </UserContext.Provider>
    )
}
