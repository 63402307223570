import React, { useEffect, useState } from 'react';
import Grid from "../../../components/dataTable";
import { Link, useHistory } from 'react-router-dom';
import { Form, Modal, Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import FeeData from "../../../api/feeDataApi";
import SchoolApi from '../../../api/schoolApi';
import MasterFee from "../../../components/bulkupload/feeData";
import ExportFeeData from "../../../components/export/feeData";
import Loader from "../../../components/loader";

const ManageFeeData = () => {
    let history = useHistory();
    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        isModalVisible: false,
        selectedTeacher: '',
    })

    const [rows, setRows] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [sessionsData, setSessionsData] = useState([]);
    const [selectedSession, setSelectedSession] = useState();

    useEffect(() => {
        if (localStorage.getItem('schoolId')) {
            getSchool(localStorage.getItem('schoolId'));
        }
        // getList()
    }, [])

    const getSchool = async (id) => {
        const schoolApi = new SchoolApi();
        await schoolApi.getSchool(id).then(async (response) => {
            if (response && response.data && response.data.session) {
                const session = response.data.session;
                if (session && session.startsFromMonth) {

                    const sessionData = response.data.session;
                    const currentMonth = new Date().getMonth() + 1;
                    const currentYear = new Date().getFullYear();
                    let sessionEndyear = new Date().getFullYear();
                    if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
                        sessionEndyear = currentYear + 1;
                    }
                    const sessionStartDate = new Date(currentYear, sessionData.startsFromMonth - 1, 1);
                    const sessionEndDate = new Date(sessionEndyear, sessionData.endsOnMonth, 0);
                    const sessionsData = [];

                    sessionsData.push({
                        display: (currentYear - 1) + '-' + (sessionEndyear - 1)
                    })
                    sessionsData.push({
                        display: currentYear + '-' + sessionEndyear
                    })
                    sessionsData.push({
                        display: (currentYear + 1) + '-' + (sessionEndyear + 1)
                    })
                    setSessionsData(sessionsData);
                    if (currentMonth >= sessionData.startsFromMonth) {
                        setSelectedSession(sessionsData[1].display);
                        getList(sessionsData[1].display)
                    } else {
                        setSelectedSession(sessionsData[0].display);
                        getList(sessionsData[0].display)
                    }
                }
            }
            return response;
        }).catch((error) => {
            // toast.error(error.toString());
            console.error('There is an error!', error);
        });
    }

    const onChangeSession = async (e) => {
        const { name, value } = e.target;
        setSelectedSession(value);
        getList(value);
    }

    const getList = async (sessionDate) => {
        setDataLoader(true);
        const Api = new FeeData();
        await Api.getFeeDataList(sessionDate).then(async (response) => {
            // console.log(response.data);
            if (response.data && response.data.length) {
                setRows(response.data)
            } else {
                setRows([])
            }
        }).catch((error) => {
            toast.error(error.toString());
            console.error("There is an error!", error);
        });
        setDataLoader(false)
    }

    const renderAction = (props) => {
        return (
            <span>
                <ReactTooltip id='Edit' type='warning' effect='solid'>
                    <span>Edit</span>
                </ReactTooltip>

                <button onClick={() => history.push(`/admin/masterdata/createfees?id=${props._id}`)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                    <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                </button>

                <ReactTooltip id='Delete' type='error' effect='solid'>
                    <span>Delete</span>
                </ReactTooltip>

                <button data-tip onClick={() => deleteFeeData(props._id, props.className)} data-for="Delete" type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
                    <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
                </button>
            </span>
        )
    }

    const TotalFeesHyperLink = (props) => {
        return (
            <span>
                {/*<Link>*/}
                Rs. {props.totalAmount}
                {/*</Link>*/}
            </span>
        )
    }

    const showSession = (props) => {
        return (
            <span>
                {props.session}
            </span>
        )
    }

    const showclassName = (props) => {
        return (
            <span>
                {props.className}
            </span>
        )
    }

    const deleteFeeData = async (id, className) => {
        const Api = new FeeData();
        await Api.deleteFeeData(id, className)
            .then((response) => {
                if (response.success) {
                    toast.success(response.msg)
                } else {
                    toast.error(response.msg)
                }
            })
            .catch((error) => {
                toast.error(error.toString());
                console.error("There is ERROR!", error)
            })
        getList(selectedSession)
    }

    const showParticular = (props) => {
        // particular
        const a = props.feesDetails;
        let show = '';
        // a.map(d => show += d.particular + ", ")
        a.map((d, key) => show += (key === a.length - 1) ? d.particular : d.particular + ', ')
        return show;

    }

    const field = () => {
        const fields = [
            {
                title: "Class",
                render: showclassName
            },
            {
                title: "Session",
                render: showSession
            },
            {
                title: "Total Fees",
                render: TotalFeesHyperLink
            },
            {
                title: "Particular",
                render: showParticular
            },
            {
                name: "action",
                title: "Actions ",
                render: renderAction,
            },
        ];
        return fields;
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Manage Fees </h3>
                <Link className="nav-link" to="/admin/masterdata/createfees">
                    <button
                        type="submit"
                        className="btn btn-primary"
                    >Create Fees</button>
                </Link>
            </div>
            {dataLoader ? <Loader /> : null}
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Select Session</label>
                                        <div className="col-sm-9">
                                            <select
                                                className="form-control"
                                                name="session"
                                                onChange={(e) => onChangeSession(e)}
                                                value={selectedSession}
                                            >
                                                {
                                                    sessionsData.map((resp) => <option key={resp.display} value={resp.display}>{resp.display}</option>)
                                                }
                                            </select>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="row mb-2">
                            <div className="col-md-5 import">
                                <MasterFee />
                            </div>
                            <div className="col-md-7 export">
                                <ExportFeeData feeData={rows} />
                            </div>
                        </div>
                        <Grid
                            columns={field()}
                            rows={rows}
                            settings={{ search: false, paging: false, exportButton: false }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ManageFeeData;
