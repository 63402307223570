import BaseApi from "./baseApi";

class BusRoute extends BaseApi {
    async getBusRoutes() {
        return await this.API(
            {
                url: "/busroute/getbusroutes",
                method:"get",
            }
        )
    }
		
		async getBusRoute(data) {
        return await this.API(
            {
                url: "/busroute/getbusroute/" + data,
                method:"get"
            }
        )
    }
		
		async createBusRoute(data) {
        return await this.API(
            {
                url: "/busroute/createbusroute",
                data: data,
                method:"post"
            }
        )
    }

    async updateBusRoute(data) {
        return await this.API(
            {
                url: "/busroute/updatebusroute",
                data: data,
                method:"post"
            }
        )
    }
		
		async activateBusRoute(data) {
        return await this.API(
            {
                url: "/busroute/activatebusroute",
                data: data,
                method:"post"
            }
        )
    }

    async deleteBusRoute(data) {
        return await this.API(
            {
                url: "/busroute/deletebusroute",
                data: { _id: data },
                method:"post"
            }
        )
    }

}

export default BusRoute;