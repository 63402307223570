import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import schoolReducer from "./reducer/getSchoolReducer";
import schoolApi from "./middleware/getSchoolMiddleware";

let reducer = {
    schoolReducer: schoolReducer
}
export default function store() {
    return configureStore({
        reducer,
        middleware: [...getDefaultMiddleware(), schoolApi],
    });
}