import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import SubjectApi from "../../api/subjectsApi";
const SubjectCSV = () => {
  const [isChooseFileDisplay, setIsChooseFileDisplay] = useState(false);

  function downloadSubjectCSV() {
    let headers = [{ label: "Subject Name", key: "subjectName" }];
    return (
      <CSVLink
        data={[{ subjectName: "English" }]}
        filename={"School_Sample_Subject_Import.csv"}
        headers={headers}
      >
        (Download Sample CSV)
      </CSVLink>
    );
  }

  function uploadCsvFile(claassData) {
    let subjectNameColumnIndex = -1;
    let imported = [];
    let firstRowIndex = -1;
    claassData.every(async (row, index) => {
      if (subjectNameColumnIndex == -1 && row.indexOf("Subject Name") > -1) {
        subjectNameColumnIndex = row.indexOf("Subject Name");
      }
      if (firstRowIndex === -1) {
        firstRowIndex = index + 1;
      }

      if (index >= firstRowIndex) {
        let subjectName = row[subjectNameColumnIndex];
        if (!subjectName) {
          return false
        }

        if (subjectName) {
          subjectName = subjectName.trim();
        }
        imported.push({
          subjectName
        });
      }
    });
    saveSubjectCsv(imported);
  }

  async function saveSubjectCsv(imported) {
    if (window.confirm("Are your sure for upload the class csv file ?")) {
      let Api = new SubjectApi();
      await Api.saveSubjectCsv(imported)
        .then(async (response) => {
          toast.success("Csv file is save successfully");
          window.location.reload(false);
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There is an error!", error);
        });
    }
  }

  function uploadSubjectCsv() {
    return (
      <CSVReader
        onFileLoaded={(subjectData) => uploadCsvFile(subjectData)}
        inputStyle={{ fontSize: "12px" }}
      />
    );
  }

  return (
    <>
      <div className="col-md-12 textRight">
        <a href="javascript:void(0)" onClick={() => setIsChooseFileDisplay(!isChooseFileDisplay)}>
          <><i className="mdi mdi-apple-keyboard-caps"></i> Import Subjects Data </>
        </a>
        <a className="text-sm">
          {downloadSubjectCSV()}
        </a>
      </div>
      <div className="col-md-5 text-sm">
        <div>{isChooseFileDisplay ? uploadSubjectCsv() : null}</div>
      </div>
    </>
  );
};

export default SubjectCSV;
