import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Link, useHistory } from "react-router-dom";
import { Form, Modal, Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import Grid from "../../../components/dataTable";
import StudentApi from "../../../api/studentApi";
import { toast } from "react-toastify";
import DeleteModal from "../../../components/deleteAlertPopup";
import StatusModal from "../../../components/alertPopup";
import Class from "../../../api/classApi";
import Students from "../../../components/bulkupload/students";
import Loader from "../../../components/loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ExportStudents from "../../../components/export/students";
import Datetime from 'react-datetime';

const ManageStudentData = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    isModalVisible: false,
    isModalVisible2: false,
    idForDelete: "",
  });
  const [mainRows, setMainRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [archiveListActive, setArchinveListActive] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [classList, setClassList] = useState([]);
  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: "",
  });
  const [selecteUser, setSelecteUser] = useState({});

  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalStudents, setTotalStudents] = useState(0);
  const [sortColName, setSortColName] = useState("_id");
  const [sort, setSort] = useState("DESC");
  const [sreachKeyword, setSreachKeyword] = useState(null);
  const [filters, setFilters] = useState(
    {
      "classId": "all",
      "gender": "",
      "dob": { "from": "", "to": "" },
      "admissionDate": { "from": "", "to": "" },
      "sessionStart": "",
      "isPrivate": "null"
    }
  )

  const [genderType, setGenderType] = useState();
  const [dobList, setDobList] = useState({ from: "", to: "" });

  useEffect(() => {
    if (classList.length == 0) {
      getClassList();
    }
    getList(itemsPerPage, itemOffset, filters, sreachKeyword, sortColName, sort);
  }, [itemOffset, filters, sreachKeyword, sortColName, sort]);

  const getList = async (itemsPerPage, skip, filters, sreachKeyword, sortColName, sort) => {
    let list = [];
    setDataLoader(true);
    let Api = new StudentApi();
    await Api.getStudentsList(itemsPerPage, skip, filters, sreachKeyword, sortColName, sort)
      .then(async (response) => {

        if (response.data && response.data.length) {
          await response.data.map((data) => {
            if (data.active && !archiveListActive) {
              list.push(data);
            } else if (!data.active && archiveListActive) {
              list.push(data);
            }
          });
          setMainRows(response.data);
          setRows(response.data);
          setSelectedRows(list)
          setPageCount(Math.ceil(response.totalStudents / itemsPerPage));
          setTotalStudents(response.totalStudents);
        } else {
          setRows([]);
          setSelectedRows([]);
          setPageCount(Math.ceil(0 / itemsPerPage));
          setTotalStudents(0);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const getClassList = async () => {
    let clist = new Class();
    await clist
      .getClass()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setClassList(response.data);
        } else {
          setClassList([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const checkArchive = async (archive) => {
    let list = [];
    setArchinveListActive(archive);
    if (mainRows) {
      mainRows.map((data) => {
        if (data.active && !archive) {
          list.push(data);
        } else if (!data.active && archive) {
          list.push(data);
        }
      });
    }
    setSelectedRows(list);
  };

  const renderAction = (props) => {
    return (
      <span
        style={{
          display: "block",
          width: "76px",
        }}
      >
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          onClick={() => history.push(`/addstudent?id=${props._id}`)}
          data-tip
          data-for="Edit"
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>

        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>

        {props.feeHistory && !props.feeHistory.length ? (
          <button
            onClick={() => {
              isVisible1(props);
              // deleteStaff(props._id)
            }}
            data-tip
            data-for="Delete"
            type="button"
            className="btn btn-outline-danger"
            style={{ padding: "8px" }}
          >
            <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
          </button>
        ) : (
          ""
        )}
      </span>
    );
  };

  // ===================================== delete ====================================

  const isVisible1 = (props = null) => {
    let d = { ...data };
    if (props) {
      setSelecteUser(props);
    }
    d.isModalVisible = !d.isModalVisible;
    setData(d);
  };

  const deleteStudent = async () => {
    let Api = new StudentApi();
    await handleClose();
    await Api.deleteStudent(selecteUser)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is ERROR!", error);
      });
    getList(itemsPerPage, itemOffset, filters, sreachKeyword, sortColName, sort);
  };

  // ===================================== delete ====================================

  // ===================================== stauts ====================================

  const handleClose = () => {
    let d = { ...data };
    d.isModalVisible = false;
    d.isModalVisible2 = false;
    setSelecteUser({});
    setStatusMsg({
      msg: "",
      buttonName: "",
    });
    setData(d);
  };

  // ===================================== stauts ====================================

  const DeactiveAction = (props, index) => {
    return (
      <span>
        <ReactTooltip
          id="activate"
          type={props.active ? "success" : "error"}
          effect="solid"
        >
          <span>Activate/Deactive</span>
        </ReactTooltip>
        {/* <span onClick={()=> deactieActionApi(props)} data-tip data-for="activate" className="form-check form-check-danger" style={{display:'inline-flex'}}> */}
        <span
          onClick={() => {
            let d = { ...data };
            if (props.active) {
              setStatusMsg({
                msg: `Are you sure that you want to Deactivate ${props.firstName}`,
                buttonName: "Deactivate",
              });
            } else {
              setStatusMsg({
                msg: `Are you sure that you want to Activate ${props.firstName}`,
                buttonName: "Activate",
              });
            }
            d.isModalVisible2 = true;
            setData(d);
            setSelecteUser(props);
          }}
          data-tip
          data-for="activate"
          className={
            props.active
              ? "form-check form-check-success"
              : "form-check form-check-danger"
          }
          style={{ display: "inline-flex" }}
        >
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={props.active}
            />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    );
  };

  const StudentHyperLink = (props) => {
    return (
      <span>
        <Link to={`/viewstudent?id=${props._id}`}>
          {props.firstName}
        </Link>
      </span>
    );
  };

  const fatherName = (props) => {
    // console.log(props.fatherProfile)
    if (props.fatherProfile) {
      return <span>{props.fatherProfile.name}</span>;
    }
  };

  const motherName = (props) => {
    if (props.motherProfile) {
      return <span>{props.motherProfile.name}</span>;
    }
  };

  const Admission_Date = (props) => {
    if (props.admissionDate) {
      return <span>{moment(props.admissionDate).format("DD/MM/YYYY")}</span>;
    }
  };
  const Show_Mobile = (props) => {
    if (props.fatherProfile) {
      return <span>{props.fatherProfile.mobile}</span>;
    }
  };

  const Show_DOB = (props) => {
    if (props.dob) {
      let d = props.dob.slice(0, 10);
      return <span>{moment(props.dob).format("DD/MM/YYYY")}</span>;
    }
  };

  const Show_Gender = (props) => {
    if (props.gender) {
      return <span>{props.gender}</span>;
    }
  };

  const Show_Session = (props) => {
    if (props.sessionStart) {
      return <span>{moment(props.sessionStart).format("YYYY")}</span>;
    }
  };

  const ShowClass = (props) => {
    if (props.classInfo) {
        return <span>{props.classInfo.className}</span>;
    }
  };

  const deactieActionApi = async () => {
    let Api = new StudentApi();
    let da = { _id: selecteUser._id, activate: !selecteUser.active };
    await Api.activateOrDactivate(da)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is ERROR!", error);
      });
    handleClose();
    await getList(itemsPerPage, itemOffset, filters, sreachKeyword, sortColName, sort);
  };


  function sortData(colName) {
    if (sort == "DESC") {
      setSort("ASC")
    } else {
      setSort("DESC");
    }
    setSortColName(colName);

  }

  const field = () => {
    const fields = [
      {
        // name: "",
        // field: "Deactive",
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: DeactiveAction,
      },
      {
        title: "Student Name ",
        render: StudentHyperLink,
        customSort: (a, b) => sortData("firstName")
      },
      {
        title: "Class",
        render: ShowClass,
        customSort: (a, b) => sortData("classInfo.className")
      },
      {
        title: "Admission ID",
        field: "admissionNo",
        customSort: (a, b) => sortData("admissionNo")
      },
      {
        title: "Admission Date",
        render: Admission_Date,
        customSort: (a, b) => sortData("admissionDate")
      },
      {
        title: "Father Name",
        render: fatherName,
        customSort: (a, b) => sortData("fatherProfile.name")
      },
      {
        title: "Mother Name",
        render: motherName,
        customSort: (a, b) => sortData("motherProfile.name")
      },
      {
        title: "Mobile ",
        render: Show_Mobile,
        customSort: (a, b) => sortData("mobile")
      },
      {
        title: "DOB ",
        render: Show_DOB,
        customSort: (a, b) => sortData("dob")
      },
      {
        title: "Gender ",
        render: Show_Gender,
        customSort: (a, b) => sortData("gender")
      },
      {
        title: "Session ",
        render: Show_Session,
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  };

  // ======================================================================

  const handleList = async (e, flag) => {
    let fr = [];
    let { name, value } = e.target;

    if (flag == "filter") {
      const f = { ...filters };
      if (name === "classId") {
        f.classId = value
      }

      if (name === "gender") {
        f.gender = value;
      }

      if (name === "dobFrom") {
        f.dob.from = value;
      }
      if (name === "dobTo") {
        f.dob.to = value;
      }

      if (name === "adFrom") {
        f.admissionDate.from = value;
      }
      if (name === "adTo") {
        f.admissionDate.to = value;
      }

      if (name === "sessionStart") {
        f.sessionStart = value;
      }

      if (name === "isPrivate") {
        if (value != "null") {
          f.isPrivate = !filters.isPrivate;
        } else {
          f.isPrivate = null
        }
      }

      setFilters(f);
    } else if (flag == "search") {
      setSreachKeyword(value);
    }
    await setRows(fr);
  };

  const chooseGender = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    await setGenderType(value);
    if (value) {
      await rows.map((datas) => datas.gender == value ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const chooseDOB = async (e) => {

    let { name, value } = e.target;
    let selectedrow = [];

    let dob = { ...dobList };

    if (name == "from") {
      dob.from = value.format("DD/MM/YYYY")
    }
    if (name == "to") {
      dob.to = value.format("DD/MM/YYYY")
    }
    console.log("dob===", dob);
    setDobList(dob);

    if (dob.from && dob.to) {
      await rows.map((datas) => {
        console.log("datas===", datas);
        if (datas.dob && moment(datas.dob).isBetween(dob.from, dob.to)) {
          selectedrow.push(datas);
        }
      })
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalStudents;
    setItemOffset(newOffset);
  };

  const currentYear = new Date().getFullYear();
  const years = [];
  for (var i = currentYear; i >= currentYear - 3; i--) {
    years.push(i);
  }

  // ======================================================================

  return (
    <div className="container">
      <div className="page-header">
        <h3 className="page-title"> Manage Students </h3>
        <Link className="nav-link" to="/addstudent">
          <button type="submit" className="btn btn-primary">Add</button>
        </Link>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-3 col-form-label">Select Class</label>
                    <div className="col-sm-8">
                      <select name="classId" onChange={(e) => handleList(e, "filter")} className="form-control">
                        <option value="">---------</option>
                        {classList.map((e) => {
                          if (e.section) {
                            return (
                              <option key={e._id} value={e._id}>
                                {e.className} - {e.section}
                              </option>
                            );
                          } else {
                            return (
                              <option key={e._id} value={e._id}>
                                {e.className}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Gender</label>
                    <div className="col-sm-8">
                      <select name="gender" onChange={(e) => handleList(e, "filter")} value={filters.gender} className="form-control">
                        <option value="">---------</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="row">
                    <label className="col-sm-3 col-form-label">Select DOB</label>

                    <div className="col-sm-4">
                      <label className="col-form-label">From</label>
                      <Datetime
                        dateFormat={true}
                        timeFormat={false}
                        utc={false}
                        onChange={(e) => handleList({ target: { name: "dobFrom", value: moment(e) } }, "filter")}
                        value={filters.dob.from}
                      />
                    </div>

                    <div className="col-sm-4">
                      <label className="col-form-label">To</label>
                      <Datetime
                        dateFormat={true}
                        timeFormat={false}
                        utc={false}
                        onChange={(e) => handleList({ target: { name: "dobTo", value: moment(e) } }, "filter")}
                        value={filters.dob.to}
                      />
                    </div>

                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Admission Date</label>

                    <div className="col-sm-4">
                      <label className="col-form-label">From</label>
                      <Datetime
                        dateFormat={true}
                        timeFormat={false}
                        utc={false}
                        onChange={(e) => handleList({ target: { name: "adFrom", value: moment(e) } }, "filter")}
                        value={filters.admissionDate.from}
                      />
                    </div>

                    <div className="col-sm-4">
                      <label className="col-form-label">To</label>
                      <Datetime
                        dateFormat={true}
                        timeFormat={false}
                        utc={false}
                        onChange={(e) => handleList({ target: { name: "adTo", value: moment(e) } }, "filter")}
                        value={filters.admissionDate.to}
                      />
                    </div>

                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="row">
                    <label className="col-sm-3 col-form-label">Select Session</label>
                    <div className="col-sm-8">
                      <select name="sessionStart" onChange={(e) => handleList(e, "filter")} value={filters.sessionStart} className="form-control">
                        <option value="">---------</option>
                        {years.map((year, i) => (<option key={"year_" + i} value={year}>{year}</option>))}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Bus</label>
                    <div className="col-sm-8">
                      <select name="isPrivate" onChange={(e) => handleList(e, "filter")} value={filters.isPrivate} className="form-control">
                        <option value={'null'}>---------</option>
                        <option value={true}>Private</option>
                        <option value={false}>Bus</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 mb-4">
          <div className="input-group">
            <div className="form-outline col-12">
              <input type="search" id="form1" className="form-control" placeholder="Search ..."
                onChange={(e) => {
                  console.log(e.value);
                  if (e.target.value.length > 0) {
                    handleList(e, "search")
                  } else if (e.target.value.length == 0) {
                    setSreachKeyword("");
                  }
                }
                } />
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2">
              <div className="col-md-5 import">
                <Students />
              </div>
              <div className="col-md-5 export">
                <ExportStudents studentsData={filters} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={async () => {
                    checkArchive(!archiveListActive);
                  }}
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: "8px" }}
                >
                  {archiveListActive ? "Active List" : "Archive List"}
                </button>
              </div>
            </div>

            <Grid
              columns={field()}
              rows={selectedRows}
              settings={{
                search: false,
                paging: false,
                // exportButton: true,
                exportButton: {
                  csv: true,
                  pdf: false
                },
                pageSize: itemsPerPage,
                sorting: true
              }}
            />
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination students"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
      <DeleteModal
        show={data.isModalVisible}
        isModalVisible2={isVisible1}
        deleteData={deleteStudent}
      />
      <StatusModal
        show={data.isModalVisible2}
        isModalVisible2={handleClose}
        deleteData={deactieActionApi}
        body={statusMsg}
      />
    </div>
  );
};

export default ManageStudentData;
