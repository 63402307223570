import React from "react";
import MaterialTable from "material-table";
const Grid = ({ columns, rows,title='',settings={ search: true, paging: true, exportButton: true,pageSize:15, sorting:true}}) => {
  return (
    <MaterialTable
      title={title}
      data={rows}
      columns={columns}
      options={settings}
    />
  )
}

export default Grid;
