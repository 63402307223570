import React from 'react';
const ClassSidebarTimeTable = ({ allClassData }) => {
  let allClassName = [];
  let newAllClassName = []
  allClassData.map((item) => {
    allClassName.push(`${item.className}${item.section ? '-' + item.section : ""}`)
  })
  newAllClassName = [... new Set(allClassName)]
  return (
    <>
      {(newAllClassName.length > 0) ? (
        <table className='timetable'>
          <thead>
            <tr>
              <th>{newAllClassName ? 'Days' : null}</th>
            </tr>
            <tr>
              <th className='th-height'>{newAllClassName ? 'Time' : null}</th>
            </tr>
          </thead>
          <tbody>
            {
              <React.Fragment>
                {
                  newAllClassName && newAllClassName.map((cls, index) => (
                    <tr key={"cls_" + index} style={{ height: '127px',fontWeight:"bold" }}><td className='td-height'>{cls}</td></tr>
                  ))
                }
              </React.Fragment>
            }
            <tr style={{ height: '20px', display: 'table-column' }}><td></td></tr>
          </tbody>
        </table>) : null}
    </>
  )
}

export default ClassSidebarTimeTable;