import BaseApi from "./baseApi";

class ActivitySchoolApi extends BaseApi {

    async getActivitySchoolById(id) {
        return await this.API(
            {
                url: `/activitySchool/getactivityschool/${id}`,
                method: "get",
            }
        )
    }

    async getActivitySchoolList() {
        return await this.API(
            {
                url: "activitySchool/getactivityschoolslist",
                method: "get",
            }
        )
    }

    async getSchoolAllActivity(data) {
        return await this.API({
            url: "/activitySchool/getschoolallactivity",
            data: data,
            method: "post",
        });
    }

    async createActivitySchool(data) {
        return await this.API(
            {
                url: "/activitySchool/createactivityschool",
                data: data,
                method: "post"
            }
        )
    }

    async updateActivitySchool(data) {
        return await this.API(
            {
                url: "/activitySchool/updateactivityschool",
                data: data,
                method: "post"
            }
        )
    }

    async deleteActivitySchool(data) {
        return await this.API(
            {
                url: "activitySchool/deleteactivityschool",
                data: { _id: data },
                method: "post"
            }
        )
    }

    async activateOrDactivate(data) {
        return await this.API(
            {
                url: "activitySchool/activeActivityschool",
                data: data,
                method: "post"
            }
        )
    }

}

export default ActivitySchoolApi;