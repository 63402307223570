import React, { useEffect, useState } from 'react';
import Grid from "../../../components/dataTable";
import { Form, Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import Concession from '../../../api/concessionApi';
import SimpleReactValidator from 'simple-react-validator';
import DeleteModal from "../../../components/deleteAlertPopup";
import StatusModal from '../../../components/alertPopup';
import Loader from "../../../components/loader";

const ManageConcessionData = () => {
	const [data, setData] = useState({
		tooltipOpen: false,
		startstyle: {
			color: 'red',
			fontSize: '14px'
		},
		isModalVisible: false,
		rowPropsId: "",
		selectedClass: '',

		_id: '',
		concessionType: '',
		concessionPercent: true,
		concessionAmount: ''
	})
	const [rows, setRows] = useState([]);
	const [dataLoader, setDataLoader] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [selecteUser, setSelecteUser] = useState({})
	const [statusMsg, setStatusMsg] = useState({
		msg: "",
		buttonName: ""
	});

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		setDataLoader(true);
		let ConcessionApi = new Concession();
		await ConcessionApi.getConcession().then(async (response) => {
			if (response.data && response.data.length) {
				let arr = [];
				response.data.map((d) => {
					d.viewableAmount = d.concessionPercent ? d.concessionAmount + '%' : d.concessionAmount;
					arr.push(d);
				});
				setRows(arr)
			} else {
				setRows([])
			}
		}).catch((error) => {
			toast.error(error.toString());
			console.error("There is an error!", error);
		});
		setDataLoader(false)
	}

	const renderAction = (props) => {

		return (
			<span>
				<ReactTooltip id='Edit' type='warning' effect='solid'>
					<span>Edit</span>
				</ReactTooltip>
				<button onClick={() => editConcession(props)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
					<i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
				</button>

				<ReactTooltip id='Delete' type='error' effect='solid'>
					<span>Delete</span>
				</ReactTooltip>

				<button data-tip data-for="Delete" onClick={() => isVisible2(props._id)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
					<i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
				</button>
			</span>
		)
	}

	const isVisible2 = (id = null) => {
		let d = { ...data };
		if (id) {
			// console.log(id)
			d.rowPropsId = id;
		}
		setIsModalVisible2(!isModalVisible2);
		setData(d);
	}

	const DeactiveAction = (props, index) => {
		return (
			<span>
				<ReactTooltip id='activate' type={props.active ? 'success' : 'error'} effect='solid'>
					<span>Activate/Deactive</span>
				</ReactTooltip>
				<span onClick={() => {
					let d = { ...data }
					if (props.active) {
						setStatusMsg({
							msg: `Are you sure that you want to Deactivate this concession`,
							buttonName: "Deactivate"
						})
					} else {
						setStatusMsg({
							msg: `Are you sure that you want to Activate this concession`,
							buttonName: "Activate"
						})
					}
					d.isModalVisible = true;
					setData(d);
					setSelecteUser(props);
				}} data-tip data-for="activate" className={props.active ? "form-check form-check-success" : "form-check form-check-danger"} style={{ display: 'inline-flex' }}>
					<label className="form-check-label">
						<input type="checkbox" className="form-check-input" checked={props.active} />
						<i className="input-helper"></i>
					</label>
				</span>
			</span>
		)
	}

	const editConcession = (props) => {
		let c = { ...data }
		c._id = props._id;
		c.concessionType = props.concessionType;
		c.concessionPercent = props.concessionPercent;
		c.concessionAmount = props.concessionAmount;
		setData(c);
	}

	const deleteConcession = async (id) => {
		let ConcessionApi = new Concession();
		isVisible2();
		await ConcessionApi.deleteConcession(data.rowPropsId)
			.then((response) => {
				toast.success(response.msg.toString());
				// console.log(response);
			})
			.catch((error) => {
				toast.error(error.toString());
				console.error("There is ERROR!", error)
			})
		getList();
	}

	const field = () => {
		const fields = [
			{
				// name: "",
				// field: "Deactive",
				name: "action",
				title: "",
				width: "0%",
				align: "center",
				render: DeactiveAction,
			},
			{
				title: "Concession Type",
				field: "concessionType",
			},
			{
				title: "Concession",
				field: "viewableAmount",
				render: PercentOrNot
			}, {
				title: "Students",
				render: StudentCount
			},
			{
				name: "action",
				title: "Actions ",
				render: renderAction,
			},
		];
		return fields;
	}

	const handleClose = () => {
		let d = { ...data };
		d.isModalVisible = false;
		d.isModalVisible2 = false;
		setSelecteUser({});
		setStatusMsg({
			msg: "",
			buttonName: ""
		});
		setData(d)
	}

	// ================================================== coding part =========================================

	const handleChanges = (event) => {
		let c = { ...data }
		const { name, value } = event.target;
		if (name == "concessionType") {
			c.concessionType = value
		} else if (name == "concessionPercent") {
			c.concessionPercent = value == "amount" ? false : true;
		} else if (name == "concessionAmount") {
			c.concessionAmount = value
		}
		setData(c);
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		let {
			_id,
			concessionType,
			concessionPercent,
			concessionAmount
		} = data;
		let ConcessionApi = new Concession()
		if (data._id) {
			await ConcessionApi.editConcession({
				_id,
				concessionType,
				concessionPercent,
				concessionAmount
			}).then((data) => {
				if (data.success) {
					toast.success(data.msg.toString());
					cleanFields();
					getList();
				} else {
					toast.error(data.msg);
				}
			})
				.catch((error) => {
					toast.error(error.toString());
					console.error("There was an error!", error);
				});
		} else {
			await ConcessionApi.createConcession({
				concessionType,
				concessionPercent,
				concessionAmount
			}).then((data) => {
				if (data.success) {
					toast.success(data.msg.toString());
					cleanFields();
					getList();
				} else {
					toast.error(data.msg);
				}
			})
				.catch((error) => {
					toast.error(error.toString());
					console.error("There was an error!", error);
				});
		}
	}

	const cleanFields = () => {
		let d = { ...data }
		d._id = "";
		d.concessionType = "";
		d.concessionPercent = true;
		d.concessionAmount = "";
		setData(d);
	}

	const PercentOrNot = (props) => {
		return (
			props.concessionPercent ? props.concessionAmount + "%" : props.concessionAmount
		)
	}

	const StudentCount = (props) => {
		return (
			<span>
				<Link to={`/students?conid=${props._id}`}>
					{props.student_docs.length}
				</Link>
			</span>
		)
	}

	const setActiveStatus = async () => {
		let ConcessionApi = new Concession()
		let da = { _id: selecteUser._id, activate: !selecteUser.active }
		await ConcessionApi.activateOrDactivate(da)
			.then((response) => {
				if (response.success) {
					toast.success(response.msg)
				} else {
					toast.error(response.msg)
				}
			})
			.catch((error) => {
				toast.error(error.toString());
				console.error("There is ERROR!", error)
			})
		handleClose();
		getList();
	}
	// ======================================= /coding part/ =====================================


	return (
		<div>
			<div className="page-header">
				<h3 className="page-title"> Master Data </h3>
			</div>
			{dataLoader ? <Loader /> : null}
			<div className="row">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<form onSubmit={handleSubmit} className="forms-sample">
								{/* =================================== create concession============================== */}
								<h4 className="card-title">Create Concession</h4>
								<div className="row">
									<div className="col-md-3">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Concession Type<span style={data.startstyle}>*</span></label>
											<div className="col-sm-12">
												<Form.Control type="text" name="concessionType" onChange={handleChanges} value={data.concessionType} required />
											</div>
										</Form.Group>
									</div>
									<div className="col-md-4">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Concession in percent or amount<span style={data.startstyle}>*</span></label>
											<label className="col-sm-6 col-form-label">
												<input type="radio" className="form-check-input mt-0 col-sm-2" onChange={handleChanges} name="concessionPercent" value="percentage" checked={data.concessionPercent} />
												<i className="input-helper"></i>
												Percent
											</label><label className="col-sm-6 col-form-label">
												<input type="radio" className="form-check-input  mt-0 col-sm-2" onChange={handleChanges} name="concessionPercent" value="amount" checked={!data.concessionPercent} />
												<i className="input-helper"></i>
												Amount
											</label>
										</Form.Group>
									</div>

									<div className="col-md-3">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Concession Amount<span style={data.startstyle}>*</span></label>
											<div className="col-sm-12">
												<Form.Control name="concessionAmount" onChange={handleChanges} value={data.concessionAmount} type="text" required />
											</div>
										</Form.Group>
									</div>
									<div className="col-md-2 mt-5">
										<button type="submit" className="btn btn-primary">{data._id ? "Update" : "Add"}</button>
									</div>
								</div>

								{/* ========================================================== */}
							</form>
						</div>
					</div>
				</div>

				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<Grid columns={field()} rows={rows} />
					</div>
				</div>
				<DeleteModal show={isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteConcession} />
				<StatusModal show={data.isModalVisible} isModalVisible2={handleClose} deleteData={setActiveStatus} body={statusMsg} />
			</div>
		</div>

	)

}

export default ManageConcessionData;