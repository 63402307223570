import BaseApi from "./baseApi";

class SubstituteTeachers extends BaseApi {
    async getSubstituteTeachers() {
        return await this.API(
            {
                url: "/substituteTeacher/getSubstituteTeachers",
                method: "get",
            }
        )
    }

}

export default SubstituteTeachers;