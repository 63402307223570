let user = localStorage.getItem("user_info");
let addedByUserId = '';
if (user) {
  user = JSON.parse(user);
  addedByUserId = user.userId;
}


const address = {
  country: "India",
  state: "Punjab",
  city: "",
  pinCode: "",
  address: "",
  longitude: "",
  latitude: "",
  allowedRadius: "",
};

const SchoolFields = {
  _id: '',
  schoolName: "",
  databaseName: "",
  address: address,
  colorScheme: {
    schoolLogo: "",
    backgroundColor: "#181824",
    fontColor: "#44ce42",
  },
  adminInfo: {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    userRoles: {
      userRole: "schoolAdmin",
      addedByUserId: addedByUserId,
    },
    address: address,
  },
};

const SchoolSettings = {
  _id: '',
  session: {
    startsFromMonth: 0,
    endsOnMonth: 0,
  },
  feeSchedules: [{
    months: [],
    lastFeeDate: ''
  }],
  leaves: {
    casual: 2,
    sick: 2,
    short: 2,
  },
  schoolTime: [{
    classIds: [],
    enteryTime: '',
    halfTime: '',
    shortLeaveTime: "1 Hr",
    satHolidays: []
  }],
  slotsTime: [{
    classIds: [],
    groupName: "",
    startTime: "09:00 AM",
    endTime: "02:00 PM",
    break1: "",
    break2: "",
    slots: [],
  }],
  termsExams: [{
    classIds: [],
    term: Number
  }]
}


module.exports = {
  SchoolFields,
  SchoolSettings
}
