import React, { useState } from "react";
import { Link } from "react-router-dom";
const Menu = () => {
    let schoolColorScheme = localStorage.getItem('schoolColorScheme');
    schoolColorScheme = JSON.parse(schoolColorScheme);
    const loginUser = JSON.parse(localStorage.getItem('user_info'));
    const role = loginUser.role;
    const nonStaff = loginUser.nonStaff; // class 4 employees
    const accessToModules = loginUser.accessToModules;
    let objKey, objKeys = Object.keys(accessToModules);
    let n = objKeys.length;
    const newObj = {}
    while (n--) {
        objKey = objKeys[n];
        newObj[objKey.toLowerCase()] = accessToModules[objKey];
    }

    const [menusList, setMenusList] = useState([
        { url: '/dashboard', label: 'Dashboard', icon: 'mdi-cube', key: 'dashboard' },
        { url: '/classes', label: 'Classes', icon: 'mdi-format-list-bulleted', key: 'classes' },
        { url: '/students', label: 'Students', icon: 'mdi-format-list-bulleted', key: 'students' },
        { url: '/staffmembers', label: 'Staff Members', icon: 'mdi-format-list-bulleted', key: 'staffMembers' },
        { url: '/class4employees', label: 'Non Teaching Staff', icon: 'mdi-format-list-bulleted', key: 'nonTeachingStaff' },
        { url: '/staffAttendance', label: 'Staff Attendance', icon: 'mdi-format-list-bulleted', key: 'staffAttendance' },
        { url: '/studentsfees', label: 'Student Fees', icon: 'mdi-format-list-bulleted', key: 'studentFees' },
        { url: '/busdata', label: 'Bus Data', icon: 'mdi-format-list-bulleted', key: 'busData' },
        { url: '/accesslevels', label: 'Access Levels', icon: 'mdi-format-list-bulleted', key: 'accessLevels' },
        { url: '/timetable', label: 'Time Table', icon: 'mdi-format-list-bulleted', key: 'timeTable' },
        { url: '/substituteTeachers', label: 'Substitute Teachers', icon: 'mdi-format-list-bulleted', key: 'substituteTeachers' },
        { url: '/coursestructure', label: 'Course Structure', icon: 'mdi-format-list-bulleted', key: 'courseStr' },
        { url: '/manageexamschedule', label: 'Exam Schedule', icon: 'mdi-format-list-bulleted', key: 'examSchedule' },
        { url: '/settings', label: 'Settings', icon: 'mdi-format-list-bulleted', key: 'settings' },
        { url: '/results', label: 'Results', icon: 'mdi-format-list-bulleted', key: 'results' },
    ]);


    const [masterMenuList, setMasterMenuList] = useState([
        { url: '/masterdata/concession', label: 'Concessions', icon: '', key: 'concessions' },
        { url: '/subjects', label: 'Subjects', icon: '', key: 'subjects' },
        { url: '/masterdata/feedata', label: 'Fee Data', icon: '', key: 'feeData' },
        { url: '/masterdata/holidays', label: 'Holidays', icon: '', key: 'holidays' },
        { url: '/masterdata/managefloors', label: 'Manage Floors', icon: '', key: 'manageFloors' },
        { url: '/masterdata/nonteachingstaffroles', label: 'Non-Teaching Staff Roles', icon: '', key: 'nonTeachingStaffRoles' },
        { url: '/masterdata/chequeStatus', label: 'Cheque Status', icon: '', key: 'chequeStatus' },
        { url: '/masterdata/manageupdates', label: 'Manage Updates', icon: '', key: 'ManageUpdates' }
    ]);

    const availableMenuList = menusList.filter(item => {
        return checkMenu(item.key);
    });

    function checkMenu(label) {
        if (role === 'principal' || role === 'teacher' || nonStaff) {
            const key4 = Object.keys(accessToModules).find(function (val, key) {
                return val === label
            });
            if (!accessToModules[key4]) {
                return false
            }
        }
        return true;
    }


    const availableMasterMenuList = masterMenuList.filter(item => {
        return checkMasterMenu(item.key);
    });

    function checkMasterMenu(label) {
        if (role === 'principal' || role === 'teacher' || nonStaff) {
            const key4 = Object.keys(accessToModules).find(function (val, key) {
                return val === label
            });
            if (!accessToModules[key4]) {
                return false
            }
        }
        return true;
    }

    return (
        <nav
            className="sidebar sidebar-offcanvas"
            id="sidebar"
            style={{
                background: schoolColorScheme && schoolColorScheme.backgroundColor,
            }}
        >
            <ul className="nav">
                <li
                    className="nav-item nav-category"
                    style={{
                        color: schoolColorScheme && schoolColorScheme.fontColor,
                    }}
                >
                    School - Admin
                </li>
                {
                    availableMenuList.map((resp) => (
                        <li className="nav-item" key={resp.key}>
                            <Link className="nav-link" to={resp.url}>
                                <span className="icon-bg">
                                    <i
                                        className={`mdi ${resp.icon} menu-icon`}
                                        style={{
                                            color: schoolColorScheme && schoolColorScheme.fontColor,
                                        }}
                                    ></i>
                                </span>
                                <span
                                    className="menu-title"
                                    style={{
                                        color: schoolColorScheme && schoolColorScheme.fontColor,
                                    }}
                                >
                                    {" "}
                                    {resp.label}
                                </span>
                            </Link>
                        </li>
                    ))
                }
                {/* ===================================== for master data route =============================== */}
                <div className="dropdown-divider"></div>
                {((loginUser.role === 'schoolAdmin' || loginUser.role === 'principal' || loginUser.role === 'teacher' || nonStaff === true) && availableMasterMenuList.length) ? (<>
                    <li
                        className="nav-item nav-category"
                        style={{
                            color: schoolColorScheme && schoolColorScheme.fontColor,
                        }}
                    >
                        Master - Data
                    </li>
                    {
                        availableMasterMenuList.map((resp) => (
                            <li className="nav-item" key={resp.key}>
                                <Link className="nav-link" to={resp.url}>
                                    <span className="icon-bg">
                                        <i
                                            className="mdi mdi-format-list-bulleted menu-icon"
                                            style={{
                                                color: schoolColorScheme && schoolColorScheme.fontColor,
                                            }}
                                        ></i>
                                    </span>
                                    <span
                                        className="menu-title"
                                        style={{
                                            color: schoolColorScheme && schoolColorScheme.fontColor,
                                        }}
                                    >
                                        {" "}
                                        {resp.label}
                                    </span>
                                </Link>
                            </li>
                        ))
                    }
                </>) : null}
            </ul>
        </nav>
    );
};
export default Menu;
