let user = localStorage.getItem("user_info");
let addedByUserId = "";
if (user) {
  user = JSON.parse(user);
  addedByUserId = user.userId;
}

const address = {
  country: "India",
  state: "Punjab",
  city: "",
  pinCode: "",
  address: "",
  longitude: "",
  latitude: "",
  allowedRadius: "",
};

const SchoolFields = {
  _id: "",
  schoolName: "",
  databaseName: "",
  address: address,
  colorScheme: {
    schoolLogo: "",
    backgroundColor: "#181824",
    fontColor: "#44ce42",
  },
  adminInfo: {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    userRoles: {
      userRole: "schoolAdmin",
      addedByUserId: addedByUserId,
    },
    address: address
  },
};

let lastDate = '04/15/'+new Date().getFullYear().toString();

let summerStart = '04/01/'+new Date().getFullYear().toString();
let summerEnd = '09/30/'+new Date().getFullYear().toString();
let winterStart = '10/01/'+new Date().getFullYear().toString();
let winterEnd = '03/31/'+new Date().getFullYear().toString();

const SchoolSettings = {
  _id: "",
  session: {
    startsFromMonth: 4,
    endsOnMonth: 3,
  },
  feeSchedules: {
    lastPaymentFeeType: '', // perDay, fixed
    lateFeeAmount: 0,
    lateFees: {
      months: [
        { label: "April", value: "4" },
        { label: "May", value: "5" },
        { label: "June", value: "6" }
      ],
      lastFeeDate: lastDate,
    }
  },
  leaves: {
    casual: 12,
    sick: 6,
    short: 6,
    shortLeaveTime:2
  },
  teacherTime: {
    summerTime: {
      fromDate: summerStart,
      toDate: summerEnd,
      schoolTime: new Date('2023-03-21T02:30:00.000Z'),
      onTime: '',
      shortLeave: {
        from: '',
        to: ''
      },
      halfDay: {
        from: '',
        to: ''
      },
      absent: ''
    },
    winterTime: {
      fromDate: winterStart,
      toDate: winterEnd,
      schoolTime: new Date('2023-03-21T02:40:00.000Z'),
      onTime: '',
      shortLeave: {
        from: '',
        to: ''
      },
      halfDay: {
        from: '',
        to: ''
      },
      absent: ''
    }
  },
  schoolTime: [
    {
      classIds: [],
      enteryTime: "08:45 AM",
      halfTime: "11:00 AM",
      shortLeaveTime: "1 Hr",
      satHolidays: [],
    },
  ],
  slotsTime: [
    {
      classIds: [],
      groupName: "",
      startTime: "09:00 AM",
      endTime: "02:00 PM",
      break1: "",
      break2: "",
      slots: [],
    },
  ],
  termsExams: [
    {
      classIds: [],
      term: 1,
    },
  ],
canParentMessageForHomework:false,
canParentMessageForComplaint:true,
canParentMessageForCommunication:false

};


module.exports = {
  SchoolFields,
  SchoolSettings,
};
