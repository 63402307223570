import BaseApi from "./baseApi";
class SchoolApi extends BaseApi {
  async create(data) {
    return await this.API(
      {
        url:  "/school/create",
        data: data,
        method: "post",
      }
    ); 
  }
  async saveSettings(data){
   return await this.API(
      {
        url:  "/school/savesettings",
        data: data,
        method: "post",
      }
    );
  }
  async getSchools(status) {
    return await this.API(
      {
        url:  "/school/getSchools",
        method: "get",
        params:{status:status}
      }
    ); 
  }
  async getSchool(id) {
    return await this.API(
      {
        url: "/school/getschool",
        method: "get",
        params: { id: id}
      }
    ); 
  }
  async getAdminSchools(email) {
    return await this.API(
      {
        url: "/school/getadminschool",
        method: "get",
        params: { email: email}
      }
    ); 
  }

  async deactiveOrActiveSchool(schoolId,status){
     return await this.API(
      {
        url:  "/school/status",
        method: "get",
        params: { schoolId: schoolId,status:status}
      }
    );
  }
  async getSchoolCounts(){
     return await this.API(
      {
        url:  "/school/getschooladmindashboardcounts",
        method: "get",
      }
    );
  }
  async getSchoolsCounts(){
     return await this.API(
      {
        url:  "/school/getsuperadmindashboardcounts",
        method: "get",
      }
    );
  }
}
export default SchoolApi;
