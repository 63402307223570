import React, { useLayoutEffect } from "react";
const Teacher = () => {
	useLayoutEffect(() => {
    window.scrollTo(0, 0)
	});
  return (
    <section id="teachers" className="section-padding ">
    <div className="container">
        <div className="">
        <div className="header-section text-center margin-btm">
            <h2 className="title__line">Teachers</h2>
            <p className="tagline">
            Streamlining administrative processes to let learning outcomes take
            center stage!
            </p>
        </div>
        <div className="row">
            <div className="col-md-4">
            <div className="service-box">
                <div className="icon-text1">
                <h4 className="ser-text">Reducing Administrative burden</h4>
                <ul>
                    <li>Digital Progress review</li>
                    <li>Intuitive attendance management</li>
                    <li>Digital Diary</li>
                </ul>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="service-box">
                <div className="icon-text1">
                <h4 className="ser-text">Efficient Digital solutions</h4>
                <ul>
                    <li>Seamless Parent interaction</li>
                    <li>Automated reminders</li>
                    <li>Personal record management</li>
                </ul>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="service-box">
                <div className="icon-text1">
                <h4 className="ser-text">Paperless record keeping</h4>
                <ul>
                    <li>Real time Dashboard</li>
                    <li>On-line fee management</li>
                    <li>Cloud based records</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>



  );
};
export default Teacher;
