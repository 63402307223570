import "../assets/styles/components/Accordion.css";
import chevronRight from "../assets/images/chevron-right.svg";
import React from 'react'
import { Accordion, Container, Card, Row, Col } from "react-bootstrap";

export default function AccordionDisplay({
	children,
	title,
	childClass,
	arrowPosition,
	forceDefaultHide = false,
}) {
	return (
		<Accordion defaultActiveKey="0">
			<Card>
				<Accordion.Collapse eventKey={forceDefaultHide ? "1" : "0"}>
					<Container className="child-container">
						<Card.Body className="child-padding">
							<div>{children}</div>
						</Card.Body>
					</Container>
				</Accordion.Collapse>
				<Accordion.Toggle as={Card.Header} eventKey={forceDefaultHide ? "1" : "0"} className={childClass && childClass.childClass}>
					<Container>
						<Row>
							<Col className="col-10 px-3">
								<div className="d-flex align-items-center" style={{ height: "100%" }}>
									{title}
								</div>
							</Col>
							<Col className="col-2 align-self-center pl-2 py-2" style={{ textAlign: arrowPosition ? arrowPosition : "left" }}
							>
								<img className="accordion-arrow" src={chevronRight} width="12" height="12" alt="chevron-right" loading="lazy"
								/>
							</Col>
						</Row>
					</Container>
				</Accordion.Toggle>
			</Card>
		</Accordion>
	);
}
