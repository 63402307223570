import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { Form, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import Contact from "../../../api/contactApi";
import Grid from "../../../components/dataTable";
import DeleteModal from "../../../components/deleteAlertPopup";

const ManageContact = () => {
	const [data, setData] = useState({
		tooltipOpen: false,
		startstyle: {
			color: 'red',
			fontSize: '14px'
		},
		isModalVisible: false,
		rowPropsId: "",

		_id: "",
		reply: "",
	})

	const [rows, setRows] = useState([]);
	const [dataLoader, setDataLoader] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [selectedContact, setSelectedContact] = useState({});

	useEffect(() => {
		getList();
	}, []);

	// ======================================= coding part =======================================

	const getList = async () => {
		setDataLoader(true);
		let ContactApi = new Contact();
		await ContactApi.getContactList().then(async (response) => {
			// console.log(response.data)
			if (response.data && response.data.length) {
				setRows(response.data)
			} else {
				setRows([])
			}
		}).catch((error) => {
			toast.error(error.toString());
			console.error("There is an error!", error);
		});
		setDataLoader(false)
	}



	const deleteContact = async () => {
		let ContactApi = new Contact();
		isVisible2();
		await ContactApi.deleteContact(data.rowPropsId)
			.then((response) => {
				// console.log(response);
				toast.success(response.msg.toString());
			})
			.catch((error) => {
				console.error("There is ERROR!", error)
				toast.error(error.toString());
			})
		getList()
	}

	const addReply = async () => {
		// console.log('submit reply--',data.reply);
		let ContactApi = new Contact();
		//isVisible2();
		let {
			_id,
			reply
		} = data;
		await ContactApi.replyContact({ _id, reply })
			.then((response) => {
				// console.log(response);
				toast.success(response.msg.toString());
				isModalVisible();
			})
			.catch((error) => {
				console.error("There is ERROR!", error)
				toast.error(error.toString());
			})
		getList()
	}

	function handleChange(event) {
		const { name, value } = event.target;
		//here set the state and pass
		let c = { ...data };
		if (name == "reply") {
			c.reply = value;
		}
		setData(c);
	}
	// ===================================/ coding part /=======================================

	const renderAction = (props) => {

		return (
			<span>

				<ReactTooltip id='Delete' type='error' effect='solid'>
					<span>Delete</span>
				</ReactTooltip>

				<button data-tip data-for="Delete" onClick={() => isVisible2(props._id)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
					<i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
				</button>

				<ReactTooltip id={`reply_${props._id}`} effect="solid">
					<span>{props.reply && props.reply != '' ? 'View Reply' : 'Add Reply'}</span>
				</ReactTooltip>
				<button
					data-tip
					data-for={`reply_${props._id}`}
					type="button"
					className="btn btn-outline-primary"
					style={{ padding: "8px" }}
					onClick={() => {
						let d = { ...data };
						d._id = props._id;
						setData(d);
						setSelectedContact(props);
						setIsActive(!isActive);
					}}
				>
					<i className={`mdi ${props.reply && props.reply != '' ? 'mdi-message-reply' : 'mdi-reply'}`} style={{ fontSize: "17px" }}></i>
				</button>
			</span>
		)
	}

	const isVisible2 = (id = null) => {
		let d = { ...data };
		if (id) {
			// console.log(id)
			d.rowPropsId = id;
		}
		setIsModalVisible2(!isModalVisible2);
		setData(d);
	}

	const isModalVisible = () => {
		let d = { ...data };
		d._id = '';
		d.reply = '';
		setData(d);
		setIsActive(!isActive);
	}

	const field = () => {
		const fields = [
			{
				title: "Name",
				field: "name",
			},
			{
				title: "Mobile",
				field: "mobile",
			},
			{
				title: "Message",
				field: "message",
			},
			{
				name: "action",
				title: "Actions ",
				render: renderAction,
			},
		];
		return fields;
	}

	return (
		<div>
			<div className="page-header">
				<h3 className="page-title"> Manage Contact </h3>
			</div>
			<div className="row">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						{/*  {<nav aria-label="breadcrumb">
                          <div className="row">
                            <div className="col">
                              <button type="button" className="btn mr-0 pr-0">
                                <i className="mdi mdi-import" style={{fontSize: "24px"}}></i>
                              </button>Import Bulk Data (<code><a href="" className="sm-text">Download &amp; View File Format</a></code>)
                            </div>
                          </div>            
													</nav> */}
						<Grid columns={field()} rows={rows} />
					</div>
				</div>
				<DeleteModal show={isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteContact} />
			</div>
			<Modal show={isActive} onHide={() => isModalVisible()}>
				<Modal.Header style={{ backgroundColor: "#345687" }} closeButton>
					<Modal.Title style={{ color: "beige" }}>{selectedContact.reply && selectedContact.reply != '' ? 'View Reply' : 'Add a Reply'}</Modal.Title>
				</Modal.Header>

				<Modal.Body style={{ minHeight: "133px", textAlign: "center", backgroundColor: "white" }}>
					<span >{selectedContact.reply && selectedContact.reply != '' ? 'Message Replied' : 'You can Add a reply here'}</span>
					{/* ==================================================================================== */}


					<Form className="pt-3">
						<Form.Group className="d-flex search-field">
							{
								selectedContact.reply && selectedContact.reply != ''
									?
									<label className="text-center col-12">
										" {selectedContact.reply} "
									</label>
									:
									<Form.Control
										type="text"
										onChange={handleChange}
										placeholder="Add reply"
										size="lg"
										name="reply"
										className="h-auto"
										required={true}
									/>
							}
						</Form.Group>

						<div className="mt-3">
							{/* <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard">SIGN IN</Link> */}
						</div>
					</Form>


					{/* ==================================================================================== */}
				</Modal.Body>

				<Modal.Footer style={{ backgroundColor: "white" }}>
					{
						selectedContact.reply && selectedContact.reply != ''
							?
							''
							:
							<button type="button" className="btn btn-outline-primary" onClick={() => addReply()}>
								<i className="mdi mdi-reply" style={{ fontSize: "17px" }}></i> Add Reply
							</button>
					}
					<button type="button" className="btn btn-outline-secondary" onClick={() => isModalVisible()} style={{ padding: '16px' }}>
						Cancel
					</button>
				</Modal.Footer>
			</Modal>

			{/* ======================================================================== */}
		</div>
	)
}


export default ManageContact;