import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';

const StatusModal = ({show = false, body= null, isModalVisible2, deleteData}) => {
    
    return (
        <>
            <Modal show={show} onHide={() => isModalVisible2()}>
                <Modal.Header  style={{backgroundColor:"#af0000"}} closeButton>
                    <Modal.Title style={{color: "beige"}}>Warning!</Modal.Title>
                </Modal.Header>
                
                <Modal.Body style={{minHeight:"133px", textAlign:"center", backgroundColor:"white"}}>
                    <span > {body.msg}</span>
                </Modal.Body>
                
                <Modal.Footer style={{backgroundColor:"white"}}>
                    <button type="button" className="btn btn-outline-danger" onClick={()=> deleteData() }>
                        {body.buttonName}
                    </button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => isModalVisible2()}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
      </>
    )
  }
  
  export default StatusModal;