import React, { useState, useEffect } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Collapse } from 'react-bootstrap';


const Menu = () => {
  let location = useLocation();
  const [menuState, setMenuState] = useState({
    activitySchoolMenuOpen: false,
    activitySchoolSubMenuOpen: false,
    activityStudentSubMenuOpen: false,
    activityCoachesMenuOpen: false
  });

  const toggleMenuState = (menu) => {
    let menuTog = { ...menuState }
    switch (menu) {
      case "activitySchoolMenuOpen":
        menuTog.activitySchoolMenuOpen = !menuTog.activitySchoolMenuOpen
        break;
      case "activitySchoolSubMenuOpen":
        menuTog.activitySchoolSubMenuOpen = !menuTog.activitySchoolSubMenuOpen
        break;
      case "activityStudentSubMenuOpen":
        menuTog.activityStudentSubMenuOpen = !menuTog.activityStudentSubMenuOpen
        break;
      case "activityCoachesMenuOpen":
        menuTog.activityCoachesMenuOpen = !menuTog.activityCoachesMenuOpen
        break;
      default:
        break;
    }
    setMenuState(menuTog);
  }


  function onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(menuState).forEach(i => {
      setMenuState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/activityschool', state: 'activitySchoolMenuOpen' }
    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setMenuState({ [obj.state]: true })
      }
    }));

  }

  function isPathActive(path) {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [])


  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-category">Edvantum - Admin</li>
        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link active" to="/dashboard">
            <span className="icon-bg">
              <i className="mdi mdi-cube menu-icon"></i>
            </span>
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        <li className={isPathActive('/setupschool') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/setupschool">
            <span className="icon-bg">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </span>
            <span className="menu-title">Setup New School</span>
          </Link>
        </li>
        <li className={isPathActive('/manageschool') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/manageschool">
            <span className="icon-bg">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </span>
            <span className="menu-title">Manage Schools</span>
          </Link>
        </li>
        <li  className={ isPathActive('/managecontact') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/managecontact">
            <span className="icon-bg">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </span>
            <span className="menu-title">Manage Contact</span>
          </Link>
        </li>
        <li className={isPathActive('/managesupport') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/managesupport">
            <span className="icon-bg">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </span>
            <span className="menu-title">Manage Support</span>
          </Link>
        </li>

        <li className={isPathActive('/updateabout') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/updateabout">
            <span className="icon-bg"><i className="mdi mdi-format-list-bulleted menu-icon"></i></span>
            <span className="menu-title">Update About</span>
          </Link>
        </li>

        <li className={isPathActive('/updategallery') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/updategallery">
            <span className="icon-bg"><i className="mdi mdi-format-list-bulleted menu-icon"></i></span>
            <span className="menu-title">Update Gallery</span>
          </Link>
        </li>

        {/* <li className={ isPathActive('/managecoaches') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/managecoaches">
            <span className="icon-bg"><i className="mdi mdi-format-list-bulleted menu-icon"></i></span>
            <span className="menu-title">Manage Coaches</span>
          </Link>
        </li> */}

        <li className={isPathActive('/managecoaches') ? 'nav-item active' : 'nav-item'}>
          <div className={menuState.activityCoachesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('activityCoachesMenuOpen')}>
            <span className="icon-bg"><i className="mdi mdi-format-list-bulleted menu-icon"></i></span>
            <span className="menu-title">Manage Coaches</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={menuState.activityCoachesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={isPathActive('/managecoaches/list') ? 'nav-link active' : 'nav-link'} to="/managecoaches/list">
                  Coaches</Link>
              </li>
              <li className="nav-item">
                <Link className={isPathActive('/managecoaches/onleave') ? 'nav-link active' : 'nav-link'} to="/managecoaches/onleave">
                  On Leaves</Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li className={isPathActive('/manageactivityschool') ? 'nav-item active' : 'nav-item'}>
          <div className={menuState.activitySchoolMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('activitySchoolMenuOpen')} data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-crosshairs-gps menu-icon"></i></span>
            <span className="menu-title">Activities</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={menuState.activitySchoolMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={isPathActive('/manageactivityschool/manageactivities') ? 'nav-link active' : 'nav-link'} to="/manageactivityschool/manageactivities">
                  Manage Activities</Link>
              </li>
              <li className="nav-item">
                <Link className={isPathActive('/manageactivityschool/schoollist') ? 'nav-link active' : 'nav-link'} to="/manageactivityschool/schoollist">Manage Schools</Link>
              </li>

              <li className="nav-item">
                <Link className={isPathActive('/manageactivityschool/studentlist') ? 'nav-link active' : 'nav-link'} to="/manageactivityschool/studentlist">Manage Students</Link>
              </li>
              <li className="nav-item">
                <Link className={isPathActive('/manageactivityschool/classlist') ? 'nav-link active' : 'nav-link'} to="/manageactivityschool/classlist">Manage Classes</Link>
              </li>
            </ul>
          </Collapse>
        </li>

      </ul>
    </nav>
  );
};
export default Menu;
