import React from "react";
import { CSVLink } from "react-csv";

const ExportStaffMembers = ({ staffMemberData = null }) => {

  function downloadStaffMemberCSVFormat() {
    let headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Mobile", key: "mobile" },
      { label: "Email", key: "email" },
      { label: "Password", key: "password" },
      { label: "Image", key: "photoUrl" },
      { label: "DOB", key: "dob" },
      { label: "Gender", key: "gender" },
      { label: "Country", key: "address.country" },
      { label: "State", key: "address.state" },
      { label: "City", key: "address.city" },
      { label: "Pin Code", key: "address.pinCode" },
      { label: "Address", key: "address.address" },
      { label: "User Role", key: "userRoles.userRole" },
      { label: "Class Teacher", key: "HeadData.classHeadName" },
      { label: "Subject Head", key: "HeadData.subjectHeadName" },
      { label: "Introduction", key: "introduction" },
      { label: "Floor Incharge", key: "floor.floorName" },
    ];
    return (
      <CSVLink
        data={staffMemberData}
        filename={"Staff_Member.csv"}
        headers={headers}
      >
      <i className="mdi mdi-download"></i>  Export Staff Member Data
      </CSVLink>
    );
  }

  return (
    <div>{downloadStaffMemberCSVFormat()}</div>
  );

}

export default ExportStaffMembers;