import React, { useEffect } from 'react';
import * as d3 from 'd3';

const StaffAttendanceScatterPlot = ({ data }) => {
    useEffect(() => {
        if (!data || data.length === 0) {
            console.error("No data provided for scatter plot.");
            return;
        }

        const svgWidth = 600;
        const svgHeight = 400;

        const svg = d3.select("#scatter-plot")
            .append("svg")
            .attr("width", svgWidth)
            .attr("height", svgHeight);

        const xScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.x)])
            .range([0, svgWidth]);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.y)])
            .range([svgHeight, 0]);

        const circles = svg.selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", d => xScale(d.x))
            .attr("cy", d => yScale(d.y))
            .attr("r", 5)
            .attr("fill", "blue");

        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        svg.append("g")
            .attr("transform", `translate(0, ${svgHeight})`)
            .call(xAxis);

        svg.append("g")
            .call(yAxis);
    }, [data]);

    return (
        <div id="scatter-plot">
        </div>
    );
};

export default StaffAttendanceScatterPlot;