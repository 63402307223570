import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import Support from "../../../api/supportApi";

const ManageSupports = () => {
    const [data, setData] = useState({
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        _id: "",
        AppName: "",
        email: "",
        alternateEmail: "",
        mobile: "",
        alternateMobile: "",
        address: ""
    })

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let SupportApi = new Support();
        await SupportApi.getSupport().then(async (response) => {
            if (response.success && response.data) {
                setData(response.data[0]);
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const handleChanges = (event) => {
        let d = { ...data }
        const { name, value } = event.target;
        if (name == "AppName") {
            d.AppName = value
        } else if (name === 'email') {
            d.email = value;
        } else if (name === 'alternateEmail') {
            d.alternateEmail = value;
        } else if (name === 'mobile') {
            d.mobile = value;
        } else if (name === 'alternateMobile') {
            d.alternateMobile = value;
        } else if (name === 'address') {
            d.address = value;
        }
        setData(d);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let SupportApi = new Support();
        let {
            _id,
            AppName,
            email,
            alternateEmail,
            mobile,
            alternateMobile,
            address
        } = data
        await SupportApi.updateSupport({
            _id,
            AppName,
            email,
            alternateEmail,
            mobile,
            alternateMobile,
            address
        }).then((data) => {
            if (data.success) {
                toast.success(data.msg.toString());
            } else {
                toast.error(data.msg.toString());
            }
        })
            .catch((error) => {
                toast.error(error.toString());
            });
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">Support Details</h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <h4 className="card-title">Update Support Details</h4>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">App Name<span style={data && data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text" name="AppName" onChange={handleChanges} value={data && data.AppName ? data.AppName : ''} required />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Email<span style={data && data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control type="email" name="email" onChange={handleChanges} value={data && data.email ? data.email : ''} required />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Alternate Email</label>
                                            <div className="col-sm-9">
                                                <Form.Control type="email" name="alternateEmail" onChange={handleChanges} value={data && data.alternateEmail ? data.alternateEmail : ''} />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Mobile<span style={data && data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text" name="mobile" onChange={handleChanges} value={data && data.mobile ? data.mobile : ''} required />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Alternate Mobile</label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text" name="alternateMobile" onChange={handleChanges} value={data && data.alternateMobile ? data.alternateMobile : ''} />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Address<span style={data && data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control type="text" name="address" onChange={handleChanges} value={data && data.address ? data.address : ''} required />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5, float: 'right' }}>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ManageSupports;