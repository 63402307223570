import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from "material-table";
import { Form, Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import BusRoute from "../../../api/busRouteApi";
import BaseApi from "../../../api/baseApi";
import Class4employee from "../../../api/addclass4Employees";
import { ToastContainer, toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    height: '44px',
    padding: '3px',
    borderBottom:'solid',

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "White",

    // styles we need to apply on draggables
    ...draggableStyle
});

const AddBusRoute = () => {
	let history = useHistory();
    const [data, setData] = useState({
	    records: [],
	    tooltipOpen: false,
	    startstyle: {
	      color: 'red',
	      fontSize: '14px'
	    },
		stopName: '',
		busFees: '',
		_id: '',
		busNo: '',
	    routeNo: '',
	    capacity: '',
		driver: {
			userId: '',
			name: ''
		},
	    monitor: {
			userId: '',
			name: ''
		},
		daysOfWeek: [],
	    leaveFrom: 'school',
	    departureTime: '',
	    arrivalTime: '',
		stops: []
	    /*  stops: [{
	        destination: String,
	        arrivalTime: String,
	    }] */
	})

    const [busMonitors, setBusMonitors] = useState([]);
    const [busDrivers, setBusDrivers] = useState([]);

    const [errors, setErrors] = useState({
		departureTime: '',
		arrivalTime: ''
    });
    const [days, setDays] = useState([1]);
    const [DAYS, setDAYS] = useState([]);

    const StyledToggleButtonGroup = withStyles(theme => ({
		grouped: {
			margin: theme.spacing(2),
			padding: theme.spacing(0, 1),
			"&:not(:first-child)": {
				border: "1px solid",
				borderColor: "#692B7C",
				borderRadius: "50%"
			},
			"&:first-child": {
				border: "1px solid",
				borderColor: "#692B7C",
				borderRadius: "50%"
			}
		}
	}))(ToggleButtonGroup);

	const StyledToggle = withStyles({
		root: {
			color: "#692B7C",
			"&$selected": {
				color: "white",
				background: "#692B7C"
			},
			"&:hover": {
				borderColor: "#BA9BC3",
				background: "#BA9BC3"
			},
			"&:hover$selected": {
				borderColor: "#BA9BC3",
				background: "#BA9BC3"
			},
			minWidth: 32,
			maxWidth: 32,
			height: 32,
			textTransform: "unset",
			fontSize: "0.75rem"
		},
		selected: {}
	})(ToggleButton);

    useEffect(() => {
        getStaffList();
        const id = new URLSearchParams(history.location.search).get("id");
        if (id) {
            selectedRoute(id)
        }
        getDefaultSchoolSettings();
    },[]);

	const getDefaultSchoolSettings = async () => {
		const baseApi = new BaseApi();
		const defaultSchoolSettings = await baseApi.getDefaultSchoolSettings();
		if (defaultSchoolSettings && defaultSchoolSettings.success) {
			setDAYS(defaultSchoolSettings.data.daysOfWeek);
		}
	}

	const selectedRoute = async (id) => {
		let d = {...data}
		let BusRouteApi = new BusRoute();
		let routeData = await BusRouteApi.getBusRoute(id);
		if (routeData.success) {
			d._id = routeData.data._id;
			d.busNo = routeData.data.busNo;
			d.routeNo = routeData.data.routeNo;
			d.capacity = routeData.data.capacity;
			d.driver = routeData.data.driver;
			d.monitor = routeData.data.monitor;
			d.leaveFrom = routeData.data.leaveFrom;
			d.departureTime = routeData.data.departureTime;
			d.arrivalTime = routeData.data.arrivalTime;
			d.stops = routeData.data.stops;
			setData(d);
			setDays(routeData.data.daysOfWeek);
		} else {
			toast.error(data.msg);
		}
    }

	const ToggleDays = () => {
		return (
			<>
				<StyledToggleButtonGroup
					size="small"
					arial-label="Days of the week"
					value={days}
					onChange={(event, value) => setDays(value)}
				>
					{DAYS.map((day, index) => (
						<StyledToggle key={day.key} value={index} aria-label={day.key}>
							{day.label}
						</StyledToggle>
					))}
				</StyledToggleButtonGroup>
			</>
		);
	};

    const handleAddMore = (event) => {
        const d = { ...data}
        if (d.stopName) {
            const newObj = {destination: d.stopName, busFees: d.busFees};
            if (d.busFees === 0 || d.busFees === '0' || d.busFees === '') {
            	return toast.error('Fee must be greater than 0');
            }
            const stopName = d.stops.find(item => {
            	return item.destination === d.stopName;
            })
            if (!stopName) {
                d.stops.push(newObj);
                d.stopName = '';
                d.busFees = '';
                setData(d);
            } else {
                toast.error(d.stopName + ' already exists')
            }
        }
    }

    const removeItem = (index) => {
        let f = { ...data};
        f.stops.splice(index, 1);
        setData(f);
    }

	const getStaffList = async () => {
		let Class4EmployeeApi = new Class4employee();
		await Class4EmployeeApi.getClassEmployees().then(async (response) => {
			if(response.data && response.data.length){
				let busMonitorArr = [];
				let busDriverArr = [];
				response.data.map((dt) => {
					if(dt.userRoles.subRole=='Monitor')
						busMonitorArr.push(dt);
					if(dt.userRoles.subRole=='Driver')
						busDriverArr.push(dt);
				})
				setBusMonitors(busMonitorArr);
				setBusDrivers(busDriverArr);
			}
		}).catch((error) => {
			toast.error(error.toString());
		});
	}

    const handleSubmit = async (event) => {
        event.preventDefault();
        let {
            _id,
            busNo,
            routeNo,
            capacity,
            driver,
            monitor,
            leaveFrom,
            daysOfWeek,
            departureTime,
            arrivalTime,
            stops
        } = data;
        daysOfWeek = days;
        let BusRouteApi = new BusRoute()
        if (_id) {
            await BusRouteApi.updateBusRoute({
                _id,
                busNo,
                routeNo,
                capacity,
                driver,
                monitor,
                leaveFrom,
                daysOfWeek,
                departureTime,
                arrivalTime,
                stops
			}).then((data) => {
                if (data.success) {
					toast.success(data.msg.toString());
					history.push("/busdata");
                } else {
                	toast.error(data.msg);
                }
			})
			.catch((error) => {
                toast.error(error.toString());
			});
		} else {
			await BusRouteApi.createBusRoute({
                busNo,
                routeNo,
                capacity,
                driver,
                monitor,
                leaveFrom,
                daysOfWeek,
                departureTime,
                arrivalTime,
                stops
			}).then((data) => {
                if (data.success) {
                	toast.success(data.msg.toString());
					history.push("/busdata");
                } else {
                	toast.error(data.msg);
                }
			})
			.catch((error) => {
                toast.error(error.toString());
                console.error('There was an error!', error);
			});
		}
    }

	const handleChanges = (event) => {
		let d = { ...data}
		const { name, value } = event.target;
		if (name === 'busNo' ) {
			d.busNo = value
		} else if (name === 'routeNo' ) {
			d.routeNo = value;
		} else if (name === 'capacity' ) {
			d.capacity = value;
		} else if (name === 'driver' ) {
			let arr = value.split("_");
			d.driver.userId = arr[0];
			d.driver.name = arr[1]?arr[1]:'';
		} else if (name === 'monitor' ) {
			let arr = value.split("_");
			d.monitor.userId = arr[0];
			d.monitor.name = arr[1]?arr[1]:'';
		} else if (name === 'leaveFrom' ) {
			d.leaveFrom = value;
		} else if (name === 'departureTime' ) {
			d.departureTime = value;
		} else if (name === 'arrivalTime' ) {
			d.arrivalTime = value;
		} else if (name === 'stopName' ) {
			d.stopName = value;
		}  else if (name === 'busFees' ) {
			d.busFees = value;
		}
		setData(d);
	}
	
	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(
			data.stops,
			result.source.index,
			result.destination.index
		);	
		data.stops = items
        
    }

    return(
        <div>
            <div className="page-header">
                <h3 className="page-title">Bus Data </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">    
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <h4 className="card-title">Add Bus Route</h4>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Route No.<span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <Form.Control type="text" name="routeNo" onChange={handleChanges} value={data.routeNo} required/>
                                        </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-8">
                                        <Form.Group className="row">
	                                        <label className="col-sm-3 col-form-label">Bus No./Id<span style={data.startstyle}>*</span></label>
	                                        <div className="col-sm-9">
	                                            <Form.Control type="text" name="busNo" onChange={handleChanges} value={data.busNo} required/>
	                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Bus Driver<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <select className="form-control col-sm-9" name="driver" onChange={handleChanges} value={data.driver.userId+'_'+data.driver.name} required>
                                                    <option value="">---</option>
                                                    { busDrivers.map((d,i)=><option value={d._id+'_'+d.firstName+' '+d.lastName} key={i}>{d.firstName ? d.firstName : ''} {d.lastName ? d.lastName : ''}</option>)}
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Bus Monitor<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <select className="form-control col-sm-9" name="monitor" onChange={handleChanges} value={data.monitor.userId+'_'+data.monitor.name} required>
                                                    <option value="">---</option>
                                                    { busMonitors.map((d,i)=><option value={d._id+'_'+d.firstName+' '+d.lastName} key={i}>{d.firstName ? d.firstName : ''} {d.lastName ? d.lastName : ''}</option>)}
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                        	<label className="col-sm-3 col-form-label">Departure Time<span style={data.startstyle}>*</span></label>
	                                        <div className="col-sm-9">
	                                            <Form.Control
	                                                type="time"
	                                                format="hh:mm"
	                                                name="departureTime"
	                                                placeholder="Start Time"
	                                                onChange={handleChanges}
	                                                value={data.departureTime}
	                                                required
	                                            />
	                                            <small className="text-danger">{errors.departureTime}</small>
	                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
	                                        <label className="col-sm-3 col-form-label">Arrival Time<span style={data.startstyle}>*</span></label>
	                                        <div className="col-sm-9">
	                                            <Form.Control
	                                                type="time"
	                                                format="hh:mm"
	                                                name="arrivalTime"
	                                                placeholder="Start Time"
	                                                onChange={handleChanges}
	                                                value={data.arrivalTime}
	                                                required
	                                            />
	                                            <small className="text-danger">{errors.arrivalTime}</small>
	                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Days Of Week<span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <ToggleDays />
                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
	                                        <label className="col-sm-3 col-form-label">Capacity<span style={data.startstyle}>*</span></label>
	                                        <div className="col-sm-9">
	                                            <Form.Control type="number" name="capacity" onChange={handleChanges} value={data.capacity} required/>
	                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
                                        <label className="col-sm-3 col-form-label">Start From<span style={data.startstyle}>*</span></label>
                                        <div className="col-sm-9">
                                            <Form.Control  type="text" value={data.leaveFrom?data.leaveFrom:''} name="leaveFrom" onChange={handleChanges}  required/>
                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
	                                        <label className="col-sm-3 col-form-label">Stop At<span style={data.startstyle}>*</span>(Destinations)</label>
	                                        <div className="col-sm-3">
	                                            <Form.Control type="text" name="stopName" onChange={handleChanges} value={data.stopName} required={data.stops && data.stops.length>0?false:true} />
	                                        </div>
	                                        <label className="col-sm-3 col-form-label">Stop At<span style={data.startstyle}>*</span>(Bus Fees)</label>
	                                        <div className="col-sm-3">
	                                            <Form.Control type="number" name="busFees" onChange={handleChanges} value={data.busFees} required={data.stops && data.stops.length>0?false:true} />
	                                        </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        <button type="button" className="btn btn-link btn-rounded btn-fw" onClick={handleAddMore}>Add More</button>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="row">
	                                        <label className="col-sm-3 col-form-label"></label>
	                                        <div className="col-sm-9">
	                                            {/* ==============drag and drop============== */}
	                                            <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {data.stops.map((item, index) => (
                                                                    <Draggable key={item.destination} draggableId={item.destination} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                            >
                                                                                <span>
                                                                                	<div className="row">
                                                                                		<div className="col-6">{item.destination}</div>
                                                                                		<div className="col-3">₹{item.busFees}</div>
                                                                                		<div className="col-3">
		                                                                                    <span className="float-right">
		                                                                                        <ReactTooltip id='Delete' type='error' effect='solid'>
		                                                                                            <span>Delete</span>
		                                                                                        </ReactTooltip>

		                                                                                        <button data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{padding:'8px'}} onClick={()=> removeItem(index)}>
		                                                                                            <i className="mdi mdi-delete"style={{fontSize:'17px'}}></i>
		                                                                                        </button>
		                                                                                    </span>
                                                                                		</div>
                                                                                	</div>
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
	                                            </DragDropContext>
	                                            {/* ========================================== */}
	                                        </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{lineHeight:1.5, float:'right'}}>Submit</button>
                                    </div>
                                </div>
                                {/* ========================================================== */}
                            </form>
                        </div>
                    </div>
                </div>                    
            </div>
        </div>
    )
}

export default AddBusRoute;
